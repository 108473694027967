import {PersonalName} from "../../utils/PureUserUtils";

/**
 * TODO: see if this interface is still reliable
 */
export interface NotificationItem {
	id: string;
	timestamp: number;
	userPicUrl?: string;

	/** The first and last names concatenated, or any other meaningful screen-name */
	name: string;

	/** The first and last names provided separately */
	fullName: PersonalName;

	text: string;
	isRead: boolean;
	referenceId?: number | string;
	notificationType?: NotificationTypes;

	// link to redirect user when user click on the notification
	linkPath?: string;
}

export enum NotificationTypes {
	Invitations = "invitations",
	DisconnectReminder = "disconnect_reminder",
	IncomingMail = "incomingMail",
	/**
	 * @deprecated
	 */
	NewMessage = "NewMessage",
}
