import {createSelector} from "reselect";
import {AppState} from "../../ts/redux/AppState";
import {UserlistUtils} from "../../ts/utils/UserlistUtils";
import {UserUtils} from "../../ts/utils/UserUtils";
import {unreadMessagesCountMap, getLastInteractionTime} from "../../chat/redux/ChatSelector";
import {UserRole} from "@sense-os/goalie-js";

const getSidebarNavState = (state: AppState) => state.sidebarNav;
const getSortedContacts = (state: AppState) => UserlistUtils.userlistSorting(state);

export const isSidebarCollapse = createSelector(getSidebarNavState, (state) => state.isSidebarCollapse);

export const getUnreadMessages = (state: AppState) => unreadMessagesCountMap(state);

export const getLastChatInteractionTime = (userId: number) => (state: AppState) =>
	getLastInteractionTime(state, userId);

export const getSortedContactsByRole = (role: UserRole) =>
	createSelector(getSortedContacts, (contacts) => {
		return contacts.filter((contact) => {
			return contact.role === role;
		});
	});

export const getUserChatAvailability = (userId: number) => (state: AppState) =>
	UserUtils.userChatAvailability(state, userId);
