import {NumberMap} from "services/utils/Maps";
import {LoadingState} from "constants/redux";
import {Contact} from "../../contacts/contactTypes";
import {CustomRepetitionFormValues, RepeatedOption} from "../../customRepetition/helpers/CustomRepetitionTypes";
import {UpdateEventOption} from "../../eventDialog/views/UpdateEventOptionDialog";
import {PlannedEventEntry, SensorDatum} from "redux/tracking/TrackingTypes";
import {Calendar} from "../../calendar/calendarTypes";
import {Task} from "redux-saga";

export interface TherapySessionState {
	// Client's user id
	userId: number;
	// Therapy session form isOpen state
	isFormOpened: boolean;
	// Therapy session saving state
	savingState: LoadingState;
	// Therapy session delete state
	deleteState: LoadingState;
	// Therapy session form values map
	savedFormValues: NumberMap<TherapySessionFormValues>;
	// Therapy session id
	therapyId?: string;
	// TherapySession Data
	therapySession?: SensorDatum<PlannedEventEntry>;
	// TherapySession fetch status
	fetchState?: LoadingState;
}

export interface TherapySessionFormValues {
	// Therapy session title
	title: string;
	// Therapy session description
	description: string;
	// Therapy session start date and time
	startTime: Date;
	// Therapy session end date and time
	endTime: Date;
	// Therapy session duration
	sessionDuration: number;
	// SMQ questionnaire toggle
	smqToogle: boolean;
	// OMQ questionnaire toggle
	omqToogle: boolean;
	// Push notification reminder toggle
	reminderToggle: boolean;
	// Push notification reminder duration
	reminderDuration?: number;
	// Push notification reminder time unit (minutes / hours)
	reminderTimeUnit?: ReminderTimeUnit;
	// Selected client for calendar session event
	selectedClient?: Contact;
	// Selected calendar for calendar session event
	selectedCalendar?: Calendar;
	// Repeated option for recurring session
	repeatedOption?: RepeatedOption;
	// Custom repetition form values for recurring session
	customRepetition?: CustomRepetitionFormValues;
	// Recurring session update option
	updateEventOption?: UpdateEventOption;
}

/** Time units for reminder */
export enum ReminderTimeUnit {
	HOURS = "hours",
	MINUTES = "minutes",
}

export const initialFormValues: TherapySessionFormValues = {
	title: "",
	description: "",
	startTime: new Date(),
	endTime: new Date(),
	sessionDuration: 30,
	smqToogle: true,
	omqToogle: true,
	reminderToggle: true,
	reminderDuration: 1,
	reminderTimeUnit: ReminderTimeUnit.HOURS,
	selectedClient: null,
	selectedCalendar: null,
};

export const sessionScheduleMap: Map<string, Task> = new Map();
