import moment from "moment";
import {SliderProps} from "@material-ui/core";
import {
	BehaviorExperimentDetailResponse,
	BehaviorExperimentParams,
	BehaviorExperimentResultItem,
	BehaviorExperimentType as OutcomeType,
} from "@sense-os/goalie-js/dist/behaviorExperiment";

import {createEventViewId} from "redux/tracking/TrackingHelper";
import {EventViewType, BehaviorExprEventView, Sensors} from "redux/tracking/TrackingTypes";
import behaviorExprSDK from "./behaviorExprSDK";
import {BehaviorExprFormValues} from "../redux/behaviorExprTypes";
import {ReminderTimeUnit} from "../../../helpers/time";
import localization from "../../../localization/Localization";
import strTranslation from "../../../assets/lang/strings";
import {Utils} from "utils/Utils";

/**
 * Get Behavior Experiment List by consuming API
 * @param token
 * @param start
 * @param end
 * @param hashId
 * @returns BehaviorExperimentResultItem[]
 */
export async function getBehaviorExprs(token: string, start: Date, end: Date, hashId: string) {
	const params: BehaviorExperimentParams = {
		periodAfter: start,
		periodBefore: end,
		patient: hashId,
		pageSize: 100,
	};
	const behaviorExperimentResponse = await behaviorExprSDK.getBehaviorExperimentList(token, params).then((res) => {
		return res?.results;
	});
	return behaviorExperimentResponse;
}

/**
 * Slide Configuration
 */
export const sliderProps: SliderProps = {
	min: 0,
	max: 100,
	getAriaValueText: (val: number) => `${val}%`,
	marks: [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100].map((value) => ({
		value,
		label: [0, 100].includes(value) ? value : null,
	})),
	valueLabelDisplay: "off",
};

export const detailSliderProps = {
	...sliderProps,
	disabled: true,
};

/**
 * Transform Behavior Experiment to be able shown in client's activity
 *	- passing `userId` due to doesn't get the user id from api response
 * https://niceday.slab.com/posts/behavior-experiment-api-ie5j7kgy
 * @param item
 * @param userId
 * @returns BehaviorExprEventView
 */
export function transformBehaviorExprIntoEventViewData(
	item: BehaviorExperimentResultItem,
	userId: number,
): BehaviorExprEventView {
	const occurenceTime = new Date(item.completedAt || item.canceledAt || item.expiresAt);
	return {
		id: createEventViewId(EventViewType.BEHAVIOR_EXPERIMENT, item.id.toString(), new Date(item.createdAt)),
		ownerId: userId,
		createdBy: item.createdBy,
		title: item.title,
		startTime: occurenceTime,
		endTime: occurenceTime,
		type: EventViewType.BEHAVIOR_EXPERIMENT,
		isCompleted: !!item.completedAt,
		isCanceled: !!item.canceledAt,
		createdAt: item.createdAt,
		updatedAt: item.expiresAt,
		sensors: [Sensors.BEHAVIOR_EXPERIMENT],
		source: item,
	};
}

export const behaviorExperimentTitleMax = 60;

/**
 * `isBehaviorOutcomeExist` is used to check if the outcome attribute is exist on the behaviorDetailResponse or not.
 * - if the outcome attribute response if it is empty will return null (response from be)
 * @param params
 * @returns boolean
 */
export const isBehaviorOutcomeExist = (params: BehaviorExperimentDetailResponse): boolean => {
	const behaviorExperimentOutcome = params.outcome;

	return behaviorExperimentOutcome !== null;
};

/** Convert reminder duration into date time */
export function getBehaviorReminderDateTime(formValues: BehaviorExprFormValues = null): Date {
	if (!formValues) {
		return null;
	}

	const isHour: boolean = formValues.reminderTimeUnit === ReminderTimeUnit.HOURS;

	return moment(formValues.expiresAt)
		.subtract(formValues.reminderDuration, isHour ? "hours" : "minutes")
		.toDate();
}

/**
 * get behavior experiment title
 * if the title of behavior experiment is empty it will be replaced by default behavior experiment title
 * */
export function getBehaviorExpTitle(title: string): string {
	if (!title) {
		return localization.formatMessage(strTranslation.ZZ_CORE_TRACKER.tracker.behavior_experiment.name);
	}
	return title;
}

/**
 * Reminder time range helper
 * @param {ReminderTimeUnit} unit
 * @returns {number}
 */
export function getBehaviorTimeRange(unit: ReminderTimeUnit = ReminderTimeUnit.HOURS): number[] {
	// For 'Minutes' time unit it should showing every 5 minutes (0 to 60 minutes)
	if (unit === ReminderTimeUnit.MINUTES) {
		return Array.from({length: 13}, (x, i) => i * 5);
	}
	// For 'Hours' should showing every 1 hour (1 to 24 hours)
	return Array.from({length: 24}, (x, i) => i + 1);
}

/*
 * requiredBehaviorExprErrorMessageFormatter
 * formatting the required error message behavior experiment form with this patter:
 *    {form_name} - required
 */
export function requiredBehaviorExprErrorMessageFormatter(formLabel: string): string {
	const errorMessage = `${formLabel} - ${localization.formatMessage(strTranslation.VALIDATION.required)}`;

	return Utils.capitalizeFirstChar(errorMessage);
}

/**
 * Get the outcome text of the completed behavior experiment to be displayed on the details page.
 * @param item BehaviorExperimentItem
 * @returns outcome text based on the result of the given `item`
 */
export const getBehaviorOutcomeText = (item: BehaviorExperimentDetailResponse): string => {
	if (!item.outcome) {
		return "-";
	}

	switch (item.outcome.type) {
		case OutcomeType.negative:
			return item.expectation.negativeConsequence || "-";
		case OutcomeType.rational:
			return item.expectation.rationalConsequence || "-";
		case OutcomeType.other:
			return item.outcome.otherDescription || "-";
		default:
			return "-";
	}
};
