import {combineReducers} from "redux";
import {getType} from "typesafe-actions";
import {UIAction} from "./UIAction";
import {ChatAction} from "../../../chat/redux/ChatAction";

interface ChatBoxState {
	isChatBoxOpen: boolean;
	isChatBoxFullscreen: boolean;
}

function chatBoxStateReducer(
	state: ChatBoxState = {isChatBoxOpen: false, isChatBoxFullscreen: false},
	action: UIAction.UIActionType | ChatAction.ChatActionType,
): ChatBoxState {
	switch (action.type) {
		case getType(UIAction.toggleChatBox):
			const isChatBoxFullscreen = !state.isChatBoxOpen ? state.isChatBoxFullscreen : false;
			return {isChatBoxOpen: !state.isChatBoxOpen, isChatBoxFullscreen};

		case getType(UIAction.openChatBox):
			return {...state, isChatBoxOpen: true};

		case getType(UIAction.closeChatBox):
			return {...state, isChatBoxOpen: false};

		case getType(UIAction.toggleFullscreenChatBox):
			const isChatBoxOpen = !state.isChatBoxFullscreen ? true : state.isChatBoxOpen;
			return {isChatBoxOpen, isChatBoxFullscreen: !state.isChatBoxFullscreen};

		default:
			return state;
	}
}

export interface UIState {
	chatBoxState: ChatBoxState;
}

export const UIReducer = combineReducers({
	chatBoxState: chatBoxStateReducer,
});
