import {select} from "redux-saga/effects";
import {getType} from "typesafe-actions";

import {DoSomething, stateMachineHandler} from "../../helpers/sagas/stateMachine";
import {RESET_APP_STATE} from "../../ts/redux/StoreContainer";

import {authActions} from "../redux/authActions";
import * as authSelectors from "../redux/authSelectors";

/**
 * Forks the given fn as long as the user is logged in.
 * Accepts an optional second fn that will be called,
 * when the user successfully logging out.
 */
export function whenLoggedIn(startEffect?: DoSomething, stopEffect?: DoSomething) {
	return stateMachineHandler(
		select(authSelectors.isLoggedIn),
		getType(authActions.login.success),
		[getType(authActions.logout.success), RESET_APP_STATE],
		startEffect,
		stopEffect,
	);
}

/**
 * Runs `startEffect` if logged in user is a client
 * Runs `stopEffect` when user is logged out
 */
export function runSagaForClients(startEffect?: DoSomething, stopEffect?: DoSomething) {
	return stateMachineHandler(
		select(authSelectors.isClient),
		getType(authActions.login.success),
		[getType(authActions.logout.success), RESET_APP_STATE],
		startEffect,
		stopEffect,
	);
}

/**
 * Runs `startEffect` if logged in user is a therapist
 * Runs `stopEffect` when user is logged out
 */
export function runSagaForTherapists(startEffect?: DoSomething, stopEffect?: DoSomething) {
	return stateMachineHandler(
		select(authSelectors.isTherapistOrResearcher),
		getType(authActions.login.success),
		[getType(authActions.logout.success), RESET_APP_STATE],
		startEffect,
		stopEffect,
	);
}

/**
 * Runs `startEffect` if logged in user is a therapist
 * Runs `stopEffect` when user is logged out
 */
export function runSagaForAcceptedTherapists(startEffect?: DoSomething, stopEffect?: DoSomething) {
	return stateMachineHandler(
		select(authSelectors.isAcceptedTherapist),
		getType(authActions.login.success),
		[getType(authActions.logout.success), RESET_APP_STATE],
		startEffect,
		stopEffect,
	);
}
