import {LoadingState} from "constants/redux";
import {LoggerConfigAction} from "./LoggerConfigAction";
import {getType} from "typesafe-actions";
import {LoggerConfigState, LoggerConfig} from "./LoggerConfigTypes";
import {Utils} from "utils/Utils";
import {ScheduleType} from "../tracking/TrackingTypes";
import {StringMap} from "services/utils/Maps";

const defaultState: LoggerConfigState = {
	isOpenModal: false,
	isOpenForm: false,
	isOpenEventModal: false,
	loadingCreateReminder: LoadingState.EMPTY,
	loadingRemoveReminder: {},
	loadingFetchReminder: LoadingState.EMPTY,
	loggerConfigs: {},
};

export function loggerConfigReducer(
	state: LoggerConfigState = defaultState,
	action: LoggerConfigAction.LoggerConfigActionType,
): LoggerConfigState {
	switch (action.type) {
		//
		// OPEN AND CLOSE LOGGERCONFIGURATIONMODAL COMPONENT
		//
		case getType(LoggerConfigAction.openModal): {
			const newState = toggleLoggerConfigModal(state, true);
			return setScheduleType(newState, action.payload.scheduleType);
		}
		case getType(LoggerConfigAction.closeModal): {
			const newState = toggleLoggerConfigModal(state, false);
			return setScheduleType(newState, null);
		}

		//
		// Open and Close LoggerConfigEventModal Component
		//
		case getType(LoggerConfigAction.openEventModal): {
			return {...state, isOpenEventModal: true};
		}

		case getType(LoggerConfigAction.closeEventModal): {
			return {...state, isOpenEventModal: false};
		}

		//
		// SET SCHEDULE TYPE
		//
		case getType(LoggerConfigAction.setScheduleType): {
			return setScheduleType(state, action.payload.scheduleType);
		}

		//
		// OPEN AND CLOSE LOGGERCONFIGURATIONFORM COMPONENT
		//
		case getType(LoggerConfigAction.openForm):
			return toggleLoggerConfigForm(state, true);
		case getType(LoggerConfigAction.closeForm):
			return toggleLoggerConfigForm(state, false);

		//
		// LOAD LOGGER CONFIG
		//
		case getType(LoggerConfigAction.loadingLoggerConfig): {
			const newState = setLoggerConfigs(state, []);
			return setLoadingGetReminder(newState, LoadingState.LOADING);
		}
		case getType(LoggerConfigAction.errorLoadingLoggerConfig): {
			return setLoadingGetReminder(state, LoadingState.ERROR);
		}
		case getType(LoggerConfigAction.loadedLoggerConfig): {
			const newState = setLoadingGetReminder(state, LoadingState.LOADED);
			return setLoggerConfigs(newState, action.payload.loggerConfigs);
		}

		//
		// CREATE OR UPDATE LOGGER CONFIG
		//
		case getType(LoggerConfigAction.savingLoggerConfig):
			return setLoadingCreateReminder(state, LoadingState.LOADING);
		case getType(LoggerConfigAction.errorSavingLoggerConfig):
			return setLoadingCreateReminder(state, LoadingState.ERROR);
		case getType(LoggerConfigAction.loggerConfigSaved): {
			return setLoadingCreateReminder(state, LoadingState.LOADED);
		}

		//
		// REMOVE LOGGER CONFIG
		//
		case getType(LoggerConfigAction.removingLoggerConfig):
			return setLoadingRemovingReminder(state, action.payload.loggerId, LoadingState.LOADING);
		case getType(LoggerConfigAction.loggerConfigRemoved): {
			const newState = removeLoggerConfig(state, action.payload.loggerId);
			return setLoadingRemovingReminder(newState, action.payload.loggerId, LoadingState.LOADED);
		}
		case getType(LoggerConfigAction.errorRemovingloggerConfig):
			return setLoadingRemovingReminder(state, action.payload.loggerId, LoadingState.ERROR);

		default:
			return state;
	}
}

/**
 * Remove logger config by `loggerId`
 *
 * @param {LoggerConfigState} state
 * @param {string} loggerId
 */
function removeLoggerConfig(state: LoggerConfigState, loggerId: string): LoggerConfigState {
	return {
		...state,
		loggerConfigs: Utils.removePropertyFromObject(state.loggerConfigs, loggerId),
	};
}

/**
 * Set loadingState by `loggerId` to indicates whether loggerConfig with that id
 * is being removed.
 *
 * @param {LoggerConfigState} state
 * @param {string} loggerId
 * @param {LoadingState} loadingState
 *
 */
function setLoadingRemovingReminder(
	state: LoggerConfigState,
	loggerId: string,
	loadingState: LoadingState,
): LoggerConfigState {
	return {
		...state,
		loadingRemoveReminder: {
			...state.loadingRemoveReminder,
			[loggerId]: loadingState,
		},
	};
}

/**
 * open or close `loggerConfigFormModal`
 *
 * @param {LoggerConfigState} state
 * @param {boolean} isOpen
 */
function toggleLoggerConfigForm(state: LoggerConfigState, isOpen?: boolean): LoggerConfigState {
	return {
		...state,
		isOpenForm: isOpen,
	};
}

/**
 * open or close `loggerConfigModal`
 *
 * @param {LoggerConfigState} state
 * @param {boolean} isOpen
 */
function toggleLoggerConfigModal(state: LoggerConfigState, isOpen?: boolean): LoggerConfigState {
	return {
		...state,
		isOpenModal: isOpen,
	};
}

/**
 * set schedule type
 *
 * @param {LoggerConfigState} state
 * @param {ScheduleType} scheduleType
 */
function setScheduleType(state: LoggerConfigState, scheduleType?: ScheduleType): LoggerConfigState {
	return {
		...state,
		scheduleType,
	};
}

/**
 * set loading create reminder to indicates whether
 * portal is creating / updating a reminder
 *
 * @param {LoggerConfigState} state
 * @param {LoadingState} loadingState
 */
function setLoadingCreateReminder(state: LoggerConfigState, loadingState: LoadingState): LoggerConfigState {
	return {
		...state,
		loadingCreateReminder: loadingState,
	};
}

/**
 * set loading get reminder to indicates whether
 * portal is loading lists of reminder
 *
 * @param {LoggerConfigState} state
 */
function setLoadingGetReminder(state: LoggerConfigState, loadingState: LoadingState): LoggerConfigState {
	return {
		...state,
		loadingFetchReminder: loadingState,
	};
}

/**
 * Set loggerConfigs state
 *
 * @param {LoggerConfigState} state
 * @param {LoggerConfig[]} loggerConfigs
 * @param {StringMap<LoggerConfig>} initialLoggerConfigs
 */
function setLoggerConfigs(
	state: LoggerConfigState,
	loggerConfigs: LoggerConfig[],
	initialLoggerConfigs: StringMap<LoggerConfig> = {},
): LoggerConfigState {
	const newLoggerConfigs: StringMap<LoggerConfig> = loggerConfigs.reduce((mappedLoggerConfigs, loggerConfig) => {
		return {
			...mappedLoggerConfigs,
			[loggerConfig.id]: loggerConfig,
		};
	}, initialLoggerConfigs);

	return {
		...state,
		loggerConfigs: newLoggerConfigs,
	};
}
