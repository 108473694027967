import {EventViewData, Interval} from "../../ts/redux/tracking/TrackingTypes";
import {ActionType, createAction, createAsyncAction} from "typesafe-actions";

export const clientActivityActions = {
	loadActivities: createAsyncAction(
		"clientActivity/loadActivities",
		"clientActivity/loadActivitiesSuccess",
		"clientActivity/loadActivitiesFail",
	)<
		{userId: number; start: Date; end: Date},
		{userId: number; data: EventViewData[]},
		{userId: number; err: Error}
	>(),
	loadActivity: createAsyncAction(
		"clientActivity/loadActivity",
		"clientActivity/loadActivitySuccess",
		"clientActivity/loadActivityFail",
	)<{userId: number; eventViewId: string}, {userId: number; data: EventViewData}, {userId: number; err: Error}>(),

	/**
	 * This action will be dispacthed anytime any registrations is opened.
	 * The expected results would be that the registrations will have some highlight
	 * to help our user easily recognize the last registrations that they open.
	 */
	markAsOpenedRecently: createAction("clientActivity/markAsOpenedRecently", (activityId: string) => ({activityId}))(),
	unmarkOpenedRecently: createAction("clientActivity/unmarkOpenedRecently")(),

	/**
	 * This action is used to change the interval in activities dashboard.
	 */
	changeInterval: createAction("clientActivity/changeInterval", (interval: Interval) => ({interval}))(),

	/**
	 * This action is used to apply the default interval that was stored in user settings.
	 */
	setDefaultIntervalForDashboard: createAction("clientActivity/setDefaultIntervalForDashboard")(),
};

export type ClientActivityActionType = ActionType<typeof clientActivityActions>;
