import {LoadingState} from "constants/redux";
import {DEFAULT_TRACKER_ORDER} from "../../tracker/customTracker/customTrackerTypes";
import {AppState} from "../../ts/redux/AppState";

export const getSettings = (state: AppState) => state.userSettings;
export const getOmqSmqSettings = (state: AppState) => state.userSettings.omqSmq;
export const getTrackerAddedSettings = (state: AppState) =>
	state.userSettings.customTracker.isTrackerAddedDialogChecked;
export const getFreshdeskSettings = (state: AppState) => state.userSettings.isFreshdeskTooltipOpened;
export const getUserSettingsLoadingState = (state: AppState) => state.userSettings.userSettingsLoadingState;
export const getIsUserSettingsLoaded = (state: AppState) =>
	state.userSettings.userSettingsLoadingState === LoadingState.LOADED;
export const getRecentChangesFlags = (state: AppState) => state.userSettings.flagForRecentChangesPopup;

export const getTrackerOrderSettingsByUserId = (userId: number) => (state: AppState) => {
	const trackerOrder = getSettings(state).trackerOrderMap[userId];
	if (!trackerOrder || trackerOrder.length === 0) {
		return DEFAULT_TRACKER_ORDER;
	}
	return trackerOrder;
};

export const getTimeTrackingSettings = (state: AppState) => state.userSettings.timeTrackingUserSettings;

export const getConditionalEmdrSettings = (state: AppState) => state.userSettings.isEmdrEnabled;

export const getDefaultTimeRangeForActivitiesDashboard = (state: AppState) =>
	state.userSettings.defaultTimeRangeForActivitiesDashboard;

export const getCalendarSettings = (state: AppState) => state.userSettings.calendarUserSettings;

export const getUserSettingsSavingState = (state: AppState) => state.userSettings.userSettingsSavingState;
