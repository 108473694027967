import {getType} from "typesafe-actions";
import produce from "immer";
import {SensorTaskState} from "../taskTypes";
import {LoadingState} from "constants/redux";
import {taskRegistrationActionType, taskRegistrationActions} from "./taskRegistrationActions";

export const initialState: SensorTaskState = {
	/**
	 * To determine fetching data request to backend
	 */
	fetchingState: LoadingState.EMPTY,
	/**
	 * To determine saving data request to backend
	 */
	savingState: LoadingState.EMPTY,
	/**
	 * selected tracker on the tracker menu component
	 */
	activeTracker: null,
	/**
	 * set the task modal
	 */
	isModalFormOpen: null,
	/**
	 * Saved task form values keyed by `userId`
	 */
	savedFormValues: {},
	/**
	 * initial state of form action
	 */
	formAction: null,
};

export const sensorTaskReducer = produce(
	(state: SensorTaskState, action: taskRegistrationActionType): SensorTaskState => {
		switch (action.type) {
			case getType(taskRegistrationActions.saveTask.request): {
				state.savingState = LoadingState.LOADING;
				return;
			}
			case getType(taskRegistrationActions.saveTask.success): {
				state.savingState = LoadingState.LOADED;
				return;
			}
			case getType(taskRegistrationActions.saveTask.failure): {
				state.savingState = LoadingState.ERROR;
				return;
			}
			case getType(taskRegistrationActions.setModalFormTask): {
				const {open, tracker, formAction} = action.payload;

				state.isModalFormOpen = open;
				state.formAction = formAction;
				state.activeTracker = tracker;
				return;
			}
			case getType(taskRegistrationActions.saveFormValues): {
				const {userId, formValues, sensorName} = action.payload;

				state.savedFormValues[userId] = {
					...state.savedFormValues[userId],
					[sensorName]: formValues,
				};
				return;
			}
			case getType(taskRegistrationActions.resetFormValues): {
				const {userId, sensorName} = action.payload;

				state.savedFormValues[userId] = {
					...state.savedFormValues[userId],
					[sensorName]: null,
				};
				return;
			}
			case getType(taskRegistrationActions.resetSavingState): {
				state.savingState = LoadingState.EMPTY;
			}
		}
	},
	initialState,
);
