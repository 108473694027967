import moment from "moment";
import {useEffect, useState} from "react";

import {TIME} from "../ts/constants/time";
import {padNumWithZero} from "./textTransform";
import localization from "../localization/Localization";
import strTranslation from "../assets/lang/strings";

/**
 * This function will try to parse and get the hour-minute part of the the given string,
 * and turn it into a `hh:mm` formatted string. If parsing it into hour-minute part isn't possible,
 * this function will simply return the given string.
 */
export const formatTimeString = (timeText: string) => {
	try {
		const [hour, minute] = getHourAndMinuteFromText(timeText);
		return createTimeText(hour, minute);
	} catch (err) {
		return timeText;
	}
};

/**
 * This is the hook to derive a string value from a moment object.
 * The derived string will later be used to control the html input component
 * for a time picker.
 */
export function useInputTextValue(value: moment.Moment): [string, React.Dispatch<React.SetStateAction<string>>] {
	const [inputValue, setInputValue] = useState(() => {
		// Set default inputValue
		const hour = value?.hour?.() || 0;
		const minute = value?.minute?.() || 0;
		return createTimeText(hour, minute);
	});

	useEffect(() => {
		// Try to synchronize inputValue with the actual `value`

		const timeText = createTimeText(value?.hour?.() || 0, value?.minute?.() || 0);
		if (timeText !== inputValue) {
			setInputValue(timeText);
		}
	}, [value, inputValue]);

	return [inputValue, setInputValue];
}

/**
 * This is the function to generate a list of `hh:mm` formatted string with given interval.
 * The list is primarily used as options to be showed in time picker components.
 */
export function generateTimeOptions(minutesInterval = 15): string[] {
	const times = []; // time array
	const max = 24 * TIME.MINUTES_IN_HOUR;

	let start = 0; // start time

	//loop to increment the time and push results in array
	while (start < max) {
		const hour = Math.floor(start / TIME.MINUTES_IN_HOUR); // getting hours of day in 0-24 format
		const minute = start % TIME.MINUTES_IN_HOUR; // getting minutes of the hour in 0-55 format
		const timeText = createTimeText(hour, minute);

		times.push(timeText);

		start += minutesInterval;
	}

	return times;
}

/**
 * This is the function to generate a list of `hh:mm` formatted string with given interval.
 * The list is primarily used as options to be showed in time picker components.
 * The main difference with the above `generateTimeOptions` is that the list will start
 * with recent time strings.
 */
export function generateTimeOptionsWithPriorityToRecentTimes(minutesInterval = 15): string[] {
	const times = []; // time array

	const currentTime = new Date();

	let start = (currentTime.getHours() - 2) * TIME.MINUTES_IN_HOUR + currentTime.getMinutes();
	if (start < 0) {
		start += TIME.MINUTES_IN_DAY;
	}

	start = Math.floor(start / minutesInterval) * minutesInterval;

	const max = start + TIME.MINUTES_IN_DAY;

	//loop to increment the time and push results in array
	while (start < max) {
		const hour = Math.floor(start / TIME.MINUTES_IN_HOUR) % 24; // getting hours of day in 0-24 format
		const minute = start % TIME.MINUTES_IN_HOUR; // getting minutes of the hour in 0-55 format
		const timeText = createTimeText(hour, minute);

		times.push(timeText);

		start += minutesInterval;
	}

	return times;
}

function getDurationStringFromSecond(value: number): string {
	const hour = Math.floor(value / TIME.SECONDS_IN_HOUR);
	const minute = Math.floor((value % TIME.SECONDS_IN_HOUR) / TIME.SECONDS_IN_MINUTE);
	const second = value % TIME.SECONDS_IN_MINUTE;

	return `${hour}:${padNumWithZero(minute, 2)}:${padNumWithZero(second, 2)}`;
}

export function generateDurationOptions(minutesInterval = 15 * TIME.SECONDS_IN_MINUTE): string[] {
	const times = []; // time array
	const max = TIME.SECONDS_IN_DAY;

	let start = minutesInterval; // start time

	//loop to increment the time and push results in array
	while (start < max) {
		times.push(getDurationStringFromSecond(start));
		start += minutesInterval;
	}

	return times;
}

export function createTimeText(hour: number, minute: number): string {
	return [hour, minute].map((n) => padNumWithZero(n, 2)).join(":");
}

function isValidHour(hour: number) {
	return !isNaN(hour) && hour >= 0 && hour <= 23;
}

function isValidMinute(minute: number) {
	return !isNaN(minute) && minute >= 0 && minute <= 59;
}

/**
 * Validate and convert given text into hour and minute
 */
export function getHourAndMinuteFromText(text: string): [number, number] {
	const hasTimeSeparator = text.indexOf(":") > -1;

	if (hasTimeSeparator) {
		const [hourText, minuteText] = text.split(":");
		if (!isValidHour(Number(hourText)) || !isValidMinute(Number(minuteText))) {
			throw new Error("Invalid time");
		}
		return [Number(hourText), Number(minuteText)];
	}

	if (text.length <= 2 && isValidHour(Number(text))) {
		// Here we assume that the first two chars is the `hour` value
		return [Number(text), 0];
	}

	if (text.length <= 3) {
		// Here we assume that the first char is the `hour` and the last two chars is the `minute` value
		const hourText = text.substr(0, 1);
		const minuteText = text.substr(1, 2);
		if (!isValidHour(Number(hourText)) || !isValidMinute(Number(minuteText))) {
			throw new Error("Invalid time");
		}
		return [Number(hourText), Number(minuteText)];
	}

	// If there are more than 3 chars in the text, then we only use the first 4 chars.
	const hourText = text.substr(0, 2);
	const minuteText = text.substr(2, 2);
	if (!isValidHour(Number(hourText)) || !isValidMinute(Number(minuteText))) {
		throw new Error("Invalid time");
	}
	return [Number(hourText), Number(minuteText)];
}

/**
 * Given a moment object called `month`,
 * this will return a `moment.Moment[][]` typed object,
 * where each `moment.Moment[]` represent a week from the month of the given argument.
 *
 * Each `moment.Moment[]` will contain exactly seven items,
 * that represent date starting in `Monday` of that week.
 */
export function constructDateOfMonth(month: moment.Moment): moment.Moment[][] {
	const startOfMonth = moment(month).startOf("month");
	const startOfWeek = moment(startOfMonth).startOf("isoWeek");
	const result: moment.Moment[][] = [];

	while (
		(startOfWeek.month() <= month.month() && startOfWeek.year() == month.year()) ||
		startOfWeek.year() < month.year()
	) {
		result.push([0, 1, 2, 3, 4, 5, 6].map((x) => moment(startOfWeek).add(x, "d")));
		startOfWeek.add(1, "week");
	}

	return result;
}

/**
 * This will check whether the given date is today or not.
 */
export function isItToday(date: moment.Moment): boolean {
	return date.isSame(moment(), "day");
}

/**
 * This will check whether the given date is today or not.
 */
export function isItTodayOrFuture(date: moment.Moment): boolean {
	return date.isSame(moment(), "day") || date.isAfter(moment(), "day");
}

/**
 * This will check whether the given date is today or not.
 */
export function isItThisWeek(date: moment.Moment): boolean {
	return date.isSame(moment(), "week");
}

/**
 * This will check whether the given date is today or not.
 */
export function isItThisYear(date: moment.Moment): boolean {
	return date.isSame(moment(), "year");
}

/**
 * This will check whether the given date is in the future or note.
 */
export function isItInTheFuture(date: moment.Moment): boolean {
	return date.isAfter(moment(), "day");
}

export enum ReminderTimeUnit {
	HOURS = "hours",
	MINUTES = "minutes",
}

/**
 * This function will output a localized string of the given date.
 */
export function dateToLocalizedString(date: Date): string {
	const now = moment();
	const dateAsMoment = moment(date);
	const formatter = localization.intl.formatters.getRelativeTimeFormat(localization.getLocale(), {numeric: "auto"});

	const yearDiff = dateAsMoment.diff(now, "year");
	if (yearDiff < 0) {
		return formatter.format(yearDiff, "year");
	}

	const monthDiff = dateAsMoment.diff(now, "month");
	if (monthDiff < 0) {
		return formatter.format(monthDiff, "month");
	}

	const weekDiff = dateAsMoment.diff(now, "week");
	if (weekDiff < 0) {
		return formatter.format(weekDiff, "week");
	}

	const dayDiff = dateAsMoment.diff(now, "day");
	if (dayDiff < -1) {
		return formatter.format(dayDiff, "day");
	}

	return dayDiff < 0
		? localization.formatMessage(strTranslation.TIME.yesterday)
		: localization.formatMessage(strTranslation.TIME.today);
}
