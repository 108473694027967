import {call, put, all, takeEvery, delay, takeLeading} from "redux-saga/effects";
import {ActionType, getType} from "typesafe-actions";
import {authActions} from "../redux";
import authSDK from "../helpers/authSDK";
import storage from "services/system/storage/Storage";
import {removableStorageKeys} from "services/system/storage/StorageKeys";
import {getSessionId} from "../helpers/authStorage";
import {toastActions} from "../../toaster/redux";
import strTranslation from "../../assets/lang/strings";

function* handleLogout(action: ActionType<typeof authActions.logout.request>) {
	try {
		yield call(authSDK.logout, getSessionId());
		yield put(authActions.logout.success({}));
	} catch (error) {
		yield put(authActions.logout.failure({error}));
	} finally {
		if (action.payload.isSessionExpired) {
			// Show session expired toast
			yield put(
				toastActions.addToast({
					message: strTranslation.AUTH.session_expired.toast.text,
					type: "error",
					timeout: 10000,
				}),
			);
		}
	}
}

function* delayedLogout(action: ActionType<typeof authActions.logout.request>) {
	yield call(handleLogout, action);
	// Add one second delay before we allow another logout action to be processed.
	yield delay(1000);
}

export function* clearAuthStorage() {
	yield all(removableStorageKeys.map((key) => call(storage.remove, key)));

	analytics.reset();
}

export default function* () {
	yield takeLeading(getType(authActions.logout.request), delayedLogout);
	yield takeEvery(getType(authActions.logout.success), clearAuthStorage);
	yield takeEvery(getType(authActions.logout.failure), clearAuthStorage);
}
