import {RomState, UserRomState} from "./RomTypes";
import {RomAction} from "./RomAction";
import {getType} from "typesafe-actions";
import {LoadingState} from "constants/redux";

export const emptyRom: UserRomState = {
	data: [],
	loadingState: LoadingState.EMPTY,
	interval: null,
};

export function RomReducer(state: RomState = {}, action: RomAction.RomActionType): RomState {
	const userId: number = action && action.payload && action.payload.userId;
	const userRomState = state[userId] || emptyRom;
	switch (action.type) {
		case getType(RomAction.setCurrentInterval):
			return {...state, [userId]: {...userRomState, interval: action.payload.interval}};

		case getType(RomAction.loadingRomData):
			return {...state, [userId]: {...userRomState, loadingState: LoadingState.LOADING}};
		case getType(RomAction.loadedRomData):
			return {
				...state,
				[userId]: {...userRomState, loadingState: LoadingState.LOADED, data: action.payload.data},
			};
		case getType(RomAction.errorLoadingRomData):
			return {...state, [userId]: {...userRomState, loadingState: LoadingState.ERROR}};
		default:
			return state;
	}
}
