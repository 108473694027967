import {AppState, AppStateNames} from "../../ts/redux/AppState";
import {LoadingState} from "../../ts/constants/redux";
import {AutoTrackedActivity, DateString, TimeTrackingEntry, TimeTrackingState} from "../timeTrackingTypes";
import {createSelector} from "reselect";
import {checkHasErrors, checkHasOverlap, getErrorsOfATimeTrackingEntry} from "../timeTrackingValidation";
import {getTodaysDate} from "../timeTrackingHelpers";
import {cacheCreateSelectorPerKey} from "redux/utils";

/** Currently displayed date (it changes when the user navigates through time) */
export const getSelectedDate = (state: AppState) => state[AppStateNames.timeTracking].selectedDate;

export const getEntriesCache = (state: AppState): Record<DateString, TimeTrackingEntry[]> =>
	state[AppStateNames.timeTracking].trackingEntryCache;

export const getActiveEntryInput = (state: AppState): Partial<TimeTrackingEntry> =>
	state[AppStateNames.timeTracking].activeEntryInput;

export const getIsActiveEntryTimerRunning = (state: AppState): boolean =>
	state[AppStateNames.timeTracking].isActiveEntryTimerRunning;

export const getDaysLoadingState = (state: AppState): Record<DateString, LoadingState> =>
	state[AppStateNames.timeTracking].daysLoadingState;

export const getEntryConfirmationLoadingState = (state: AppState): LoadingState =>
	state[AppStateNames.timeTracking].entryConfirmationLoadingState;

export const getIsCreatingNewEntry = (state: AppState): boolean =>
	state.timeTracking.isCreatingEntry === LoadingState.LOADING;

export const getIsDeletingEntry =
	(id: number) =>
	(state: AppState): boolean =>
		state.timeTracking.isDeletingEntryMap[id] === LoadingState.LOADING;

export const getIsUpdatingEntry =
	(id: number) =>
	(state: AppState): boolean =>
		state.timeTracking.isUpdatingEntryMap[id] === LoadingState.LOADING;

export const getHasEntryFailedToBeUpdated =
	(id: number) =>
	(state: AppState): boolean =>
		state.timeTracking.isUpdatingEntryMap[id] === LoadingState.ERROR;

export const getCurrentAutoTrackedActivity = (state: AppState): AutoTrackedActivity =>
	state[AppStateNames.timeTracking].currentAutoTrackedActivity;

// FIXME: remove when development will have been finished
export const getTimeTrackingState = (state: AppState): TimeTrackingState => state[AppStateNames.timeTracking];

// The selector to get all the entries of today
export const getTodayTrackingEntries = (state: AppState) =>
	state[AppStateNames.timeTracking].trackingEntryCache[getTodaysDate()];

/**
 * Returns entries for the currently selected date.
 * TODO: find places in the code where this selector can be used instead of a series of `yield select()` calls
 */
export const getSelectedDateEntriesCache = createSelector(
	[getSelectedDate, getEntriesCache],
	(selectedDate, entriesMap) => entriesMap[selectedDate] || [],
);

export const getUnconfirmedEntriesOfDate = createSelector(getSelectedDateEntriesCache, (entries) =>
	entries.filter((entry) => !entry.isConfirmed),
);

/**
 * Returns whether there are invalid entries for the currently selected date.
 */
export const getIsSelectedDateContainsInvalidEntries = createSelector(
	[getSelectedDateEntriesCache],
	(selectedDateEntries) => {
		const unconfirmedEntries = selectedDateEntries.filter((entry) => !entry.isConfirmed);
		return unconfirmedEntries.some((entry) => {
			return (
				checkHasErrors(
					getErrorsOfATimeTrackingEntry({
						...entry,
						treatmentId: entry.treatmentId?.toString(),
					}),
				) || checkHasOverlap(selectedDateEntries, entry)
			);
		});
	},
);

export const getUnconfirmedEntries = createSelector(getTimeTrackingState, (state) => state.unconfirmedEntries);

// The selector to get the value of whether user allow
// portal to automatically create a session time entry or not.
export const getDoesUserAllowAutoTracking = (state: AppState) => {
	// If redux contain value for this state, use that
	if (state.timeTracking.doesUserAllowAutoTracking !== undefined) return state.timeTracking.doesUserAllowAutoTracking;

	// If no state in redux but it's saved in user settings, use that
	if (state.userSettings?.timeTrackingUserSettings?.doesUserAllowAutoTracking !== undefined)
		return state.userSettings?.timeTrackingUserSettings?.doesUserAllowAutoTracking;

	// The default is on
	return true;
};

export const getBarInfo = cacheCreateSelectorPerKey((dateStr: string) => {
	return createSelector(
		(state: AppState): TimeTrackingEntry[] => state[AppStateNames.timeTracking].trackingEntryCache[dateStr],
		(dayEntry) => {
			if (dayEntry === undefined) return "gray";
			if (dayEntry.length === 0) return "none";
			if (dayEntry.some((entry) => !entry.isConfirmed)) return "red";
			return "green";
		},
	);
});

export const getExportTTLoadingState = (state: AppState) => state.timeTracking.loadingExportingTimeTracking;

export const getLoadingStateDownloadLinkForExportTimeTracking = (id: string) => (state: AppState) =>
	state.timeTracking.loadingCheckExportTimeTracking[id];

export const getDownloadLinkForExportTimeTracking = (id: string) => (state: AppState) =>
	state.timeTracking.downloadLinkMapForExport[id];
