import {createSelector} from "reselect";
import {AppState} from "../../../ts/redux/AppState";
import {getContactNameById, getSelectedContactId} from "../../../contacts/redux/contactSelectors";

import {getReminderDurationInMinutes, getReminderDurationInHour, getTimeRange} from "../helpers/psychoEducationHelpers";
import {PsychoEducationFormValues, ReminderTimeUnit} from "../psychoEducationTypes";

const getPsychoEducationState = (state: AppState) => state.psychoEducation;
export const isFormOpened = createSelector(getPsychoEducationState, (state) => state.isFormOpened);

export const getSavingStatePsychoEducationForm = (state: AppState) => {
	return getPsychoEducationState(state).savingState;
};

const getSavedFormValues = (state: AppState) => state.psychoEducation.savedFormValues;

export const getSavedFormValue = createSelector(
	getSelectedContactId,
	getSavedFormValues,
	(userId, savedFormValues) => savedFormValues[userId],
);

export const getPsychoEducationData = (state: AppState) => {
	return getPsychoEducationState(state).psychoEducationData;
};
export const getPsychoEducationId = (state: AppState) => {
	return getPsychoEducationState(state).id;
};

export const getFormValues = (state: AppState): PsychoEducationFormValues => {
	const data = getPsychoEducationData(state);
	const id = getPsychoEducationId(state);

	if (!data || data.id !== id) {
		return null;
	}

	let duration = getReminderDurationInMinutes(data.expiresAt, data.remindAt);
	let timeUnit = ReminderTimeUnit.MINUTES;

	// if notification is off
	if (!data.remindAt) {
		duration = getTimeRange()[0];
		timeUnit = ReminderTimeUnit.HOURS;
	}

	// IF duration > 60 minutes, set duration and timeUnit in hour
	if (duration >= 60) {
		duration = getReminderDurationInHour(data.expiresAt, data.remindAt);
		timeUnit = ReminderTimeUnit.HOURS;
	}

	return {
		articles: data.articles,
		title: data.title,
		description: data.description,
		dueDate: data.expiresAt,
		reminderToggle: !!data.remindAt,
		reminderDuration: duration,
		reminderTimeUnit: timeUnit,
	};
};

const getPatientId = (state: AppState) => state.psychoEducation.psychoEducationData?.patient;

export const getPatientName = (state: AppState): string => {
	const patientId = getPatientId(state);

	return getContactNameById(patientId)(state);
};

export const getPsychoEducationFetchState = (state: AppState) => {
	return getPsychoEducationState(state).psychoEducationFetchState;
};

export const getRemovedArticleIds = createSelector(getPsychoEducationState, (state) => state.removedArticleIds);

export const getSelectedPsychoEdUserId = (state: AppState) => {
	return getPsychoEducationState(state).userId;
};

export const getDeletingStatePsychoEducation = (state: AppState) => {
	return getPsychoEducationState(state).deletingState;
};
