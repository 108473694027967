import {getType} from "typesafe-actions";
import produce from "immer";

import {InterventionPsychoEducationState} from "../interventionPsychoEducationType";
import {
	interventionPsychoEducationActionType,
	interventionPsychoEducationActions,
} from "./interventionPsychoEducationActions";
import {LoadingState} from "constants/redux";

const initialState: InterventionPsychoEducationState = {
	data: null,
	loadingState: LoadingState.EMPTY,
};

export const interventionPsychoEducationReducer = produce(
	(state: InterventionPsychoEducationState, action: interventionPsychoEducationActionType) => {
		switch (action.type) {
			case getType(interventionPsychoEducationActions.fetchPsychoEducations.request): {
				state.loadingState = LoadingState.LOADING;
				state.data = null;
				state.error = null;
				return;
			}
			case getType(interventionPsychoEducationActions.fetchPsychoEducations.success): {
				const {data} = action.payload;
				state.data = data;
				state.loadingState = LoadingState.LOADED;
				return;
			}
			case getType(interventionPsychoEducationActions.fetchPsychoEducations.failure): {
				const {err} = action.payload;
				state.loadingState = LoadingState.ERROR;
				state.error = err;
				return;
			}
		}
	},
	initialState,
);
