/**
 * Author: leo Date: 23/01/2018
 */

////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// lib imports
import autobind from "autobind-decorator";
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
// app imports
import {Injectable} from "../../IoC/Injectable";
import {ITracking, SenseTracking, SensorData, SensorDataResponse} from "@sense-os/goalie-js/dist/tracking";
import {DailyPlannerService, ResolvedSensorData} from "@sense-os/goalie-js/dist";
import {SensorDatum, Sensors, ActivityTypes, PlannedEventEntry} from "redux/tracking/TrackingTypes";
import {getSessionId} from "../../../auth/helpers/authStorage";
import createLogger from "../../../logger/createLogger";
import {SentryTags} from "../../../errorHandler/createSentryReport";

export interface ITrackingService {
	/**
	 * Initializes the tracking service.
	 */
	init(): void;

	/** Direct access to the Tracking SDK */
	readonly sdk: ITracking;

	/**
	 * All the %uri fields in the sensor data are resolved by the sensor data with the uri.
	 * It also filter out duplicated sensor data in case if the sensor data is included as a child of any sensor data within the respond.
	 * @summary Get a list of sensors in resolved format.
	 *
	 * @param {Sensors[]} sensorNames array of sensor names
	 * @param {string} startTime startTime interval to fetch in ISODateString
	 * @param {string} endTime endTime interval to fetch in ISODateString
	 * @param {number} userId if empty, backend will return all therapist's clients sensors
	 */
	getSensorResolved(
		sensorNames: Sensors[],
		startTime: string,
		endTime: string,
		userId?: number,
	): Promise<SensorDatum<any>[]>;

	/**
	 * create or update sensor data
	 *
	 * @param {SensorData<T>} sensorData
	 * @param {number} userId
	 * @param {string} sensorId
	 */
	saveSensorData<T>(sensorData: SensorData<T>, userId: number, sensorId?: string): Promise<SensorDataResponse<T>>;
}

/**
 *
 */
@autobind
export class TrackingService implements ITrackingService, Injectable {
	public readonly c: string = "[TrackingService]";

	//TODO: remove unused class members

	/** Direct access to the methods of Chat SDK */
	private _sdk: ITracking;

	private log = createLogger("TrackingService", SentryTags.Tracking);

	/** @inheritDoc */
	public get sdk(): ITracking {
		if (!this._sdk) {
			this.log.debug(this.c, "The service is not ready. Call init()!");
			return;
		}
		return this._sdk;
	}

	/** @inheritDoc */
	public init(): void {
		this.log.debug(this.c, "initializing...");
		this._sdk = new SenseTracking();
		this._sdk.init(getSessionId());
	}

	/** @inheritDoc */
	public async getSensorResolved(
		sensorNames: Sensors[],
		startTime: string,
		endTime: string,
		userId?: number,
		sensorId?: Array<string>,
	): Promise<SensorDatum<any>[]> {
		const response = await DailyPlannerService.sensorResolvedGet(
			getSessionId(),
			userId,
			sensorNames,
			startTime,
			endTime,
			undefined,
			true,
			undefined,
			undefined,
			false,
			sensorId,
		);
		return this.convertResolvedSensorDataResponse(response);
	}

	/**
	 * Convert resolvedSensorData response to `SensorDatum`
	 *
	 * @param {ResolvedSensorData[]} response
	 * @param {number} userId
	 *
	 * @returns {SensorDatum<any>[]}
	 */
	private convertResolvedSensorDataResponse(response: ResolvedSensorData[], userId?: number): SensorDatum<any>[] {
		let convertedResolvedSensorData = response.map(({sensorData}) => {
			const convertedResolvedSensorData: SensorDatum<any> = {
				sensorName: sensorData.sensorName as Sensors,
				sourceName: sensorData.sourceName,
				startTime: new Date(sensorData.startTime),
				endTime: new Date(sensorData.endTime),
				id: `${sensorData.id}`,
				userId: userId || sensorData.userId,
				userProfile: sensorData.userProfile,
				value: sensorData.value,
				version: sensorData.version,
				createdAt: new Date(sensorData.createdAt),
				updatedAt: new Date(sensorData.updatedAt),
				createdBy: sensorData.createdBy,
				createdByProfile: sensorData.createdByProfile,
				updatedBy: sensorData.updatedBy,
				updatedByProfile: sensorData.updatedByProfile,
			};
			return convertedResolvedSensorData;
		});

		return convertedResolvedSensorData;
	}

	/** @inheritdoc */
	public saveSensorData<T>(
		sensorData: SensorData<T>,
		userId: number,
		sensorId?: string,
	): Promise<SensorDataResponse<T>> {
		if (sensorId) {
			return this._sdk.putSensorData(sensorId, sensorData);
		}
		return this._sdk.postSensorData([sensorData], userId).then((res) => res[0]);
	}

	/**
	 * Try to filter out invalid planned events
	 * 1. Questionnaire without parents
	 * 	  There might be a case where a planned questionnaire doesn't have a parent (Therapy session) because
	 * 	  there was a failure in edit or delete the parent. Thus we need to filter out the planned questionnaire
	 *    if it doesn't have a parent.
	 * 2. ....
	 *
	 * @param {SensorDatum<PlannedEventEntry>[]} unfilteredPlannedEvents
	 * @param {(sensor: SensorDatum<PlannedEventEntry>) => void} invalidPlannedEventCallback called only when portal found an invalid planned events
	 */
	private filterInvalidPlannedEvents(
		unfilteredPlannedEvents: SensorDatum<PlannedEventEntry>[],
		invalidPlannedEventCallback?: (sensor: SensorDatum<PlannedEventEntry>) => void,
	): SensorDatum<PlannedEventEntry>[] {
		let questionnaireSensors: SensorDatum<PlannedEventEntry>[] = [],
			plannedEventSensors: SensorDatum<PlannedEventEntry>[] = [],
			therapySessionSensors: SensorDatum<PlannedEventEntry>[] = [];

		// Try to separate planned events to questionnaires, therapy sessions, and other planned events
		unfilteredPlannedEvents.forEach((sensor) => {
			switch (sensor.value.activityType) {
				case ActivityTypes.FILL_SMQ:
				case ActivityTypes.FILL_OMQ:
					questionnaireSensors.push(sensor);
					break;
				case ActivityTypes.THERAPY_SESSION:
					therapySessionSensors.push(sensor);
					break;
				default:
					plannedEventSensors.push(sensor);
					break;
			}
		});

		// A planned questionnaire is valid to be shown in the data tab
		// if the questionnaire exist inside therapy session data.
		const validQuestionnaires = questionnaireSensors.filter((sensor) => {
			const isSmq: boolean = sensor.value.activityType === ActivityTypes.FILL_SMQ,
				isOmq: boolean = sensor.value.activityType === ActivityTypes.FILL_OMQ;

			// Search through therapy session sensors
			const hasTherapySession = therapySessionSensors.some((therapySession) => {
				if (isOmq && therapySession.value.plannedOmq) {
					return sensor.id === therapySession.value.plannedOmq.sensorData.id;
				} else if (isSmq && therapySession.value.plannedSmq) {
					return sensor.id === therapySession.value.plannedSmq.sensorData.id;
				}
				return false;
			});

			// Try to call the callback function
			if (!hasTherapySession) {
				invalidPlannedEventCallback && invalidPlannedEventCallback(sensor);
			}

			return hasTherapySession;
		});

		return [...plannedEventSensors, ...therapySessionSensors, ...validQuestionnaires];
	}
}
