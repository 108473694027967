import produce from "immer";
import {getType} from "typesafe-actions";
import {LoadingState} from "constants/redux";
import {calendarActions, calendarActionType} from "../redux/calendarActions";
import {CalendarState, TimeView} from "../calendarTypes";
import {getTodaysDate} from "../helpers/calendarHelpers";

const initialState: CalendarState = {
	selectedDate: getTodaysDate(),
	selectedTime: null,
	selectedTimeView: TimeView.WEEK,
	loadingState: LoadingState.EMPTY,
	createLoadingState: LoadingState.EMPTY,
	updateLoadingState: LoadingState.EMPTY,
	removeLoadingState: LoadingState.EMPTY,
	sessionLoadingState: LoadingState.EMPTY,
	isWeekendShown: false,
	calendarAccount: null,
	calendarEvents: {},
	calendarColors: {},
	calendarsVisibility: {},
	sessionEventView: null,
	isScrollDisabled: false,
};

const calendarReducer = produce((state: CalendarState = initialState, action: calendarActionType): CalendarState => {
	switch (action.type) {
		case getType(calendarActions.setDate):
			state.selectedDate = action.payload.date;
			return;
		case getType(calendarActions.setWeekendView):
			state.isWeekendShown = action.payload.isWeekendShown;
			return;
		case getType(calendarActions.setTimeView):
			state.selectedTimeView = action.payload.timeView;
			return;
		case getType(calendarActions.setSelectedTime):
			state.selectedTime = action.payload.selectedTime;
			return;
		case getType(calendarActions.setInitialCalendarVisibility):
			state.calendarsVisibility = action.payload.calendarsVisibilityMap;
			return;
		case getType(calendarActions.updateCalendarVisibility):
			state.calendarsVisibility[action.payload.calendarId] = action.payload.isVisible;
			return;
		case getType(calendarActions.setInitialCalendarsColor):
			state.calendarColors = action.payload.calendarsColorMap;
			return;
		case getType(calendarActions.updateCalendarColor):
			state.calendarColors[action.payload.calendarId] = action.payload.color;
			return;
		case getType(calendarActions.getCalendarAccount.request):
			state.loadingState = LoadingState.LOADING;
			return;
		case getType(calendarActions.getCalendarAccount.success):
			state.loadingState = LoadingState.LOADED;
			state.calendarAccount = action.payload.calendarAccount;
			return;
		case getType(calendarActions.getCalendarAccount.failure):
			state.loadingState = LoadingState.ERROR;
			return;
		case getType(calendarActions.getCalendarEvents.request):
			state.loadingState = LoadingState.LOADING;
			return;
		case getType(calendarActions.getCalendarEvents.success):
			const {calendarEvents} = action.payload;
			state.loadingState = LoadingState.LOADED;
			state.calendarEvents = calendarEvents;
			return;
		case getType(calendarActions.getCalendarEvents.failure):
			state.loadingState = LoadingState.ERROR;
			return;
		case getType(calendarActions.revokeCalendarProfile.request):
			state.loadingState = LoadingState.LOADING;
			return;
		case getType(calendarActions.revokeCalendarProfile.success):
			state.loadingState = LoadingState.LOADED;
			return;
		case getType(calendarActions.revokeCalendarProfile.failure):
			state.loadingState = LoadingState.ERROR;
			return;
		case getType(calendarActions.createInternalEvent.request):
			state.createLoadingState = LoadingState.LOADING;
			return;
		case getType(calendarActions.createInternalEvent.success):
			state.createLoadingState = LoadingState.LOADED;
			return;
		case getType(calendarActions.createInternalEvent.failure):
			state.createLoadingState = LoadingState.ERROR;
			return;
		case getType(calendarActions.updateInternalEvent.request):
			state.updateLoadingState = LoadingState.LOADING;
			return;
		case getType(calendarActions.updateInternalEvent.success):
			state.updateLoadingState = LoadingState.LOADED;
			return;
		case getType(calendarActions.updateInternalEvent.failure):
			state.updateLoadingState = LoadingState.ERROR;
			return;
		case getType(calendarActions.deleteInternalEvent.request):
			state.removeLoadingState = LoadingState.LOADING;
			return;
		case getType(calendarActions.deleteInternalEvent.success):
			state.removeLoadingState = LoadingState.LOADED;
			return;
		case getType(calendarActions.deleteInternalEvent.failure):
			state.removeLoadingState = LoadingState.ERROR;
			return;
		case getType(calendarActions.updateExternalEvent.request):
			state.updateLoadingState = LoadingState.LOADING;
			return;
		case getType(calendarActions.updateExternalEvent.success):
			state.updateLoadingState = LoadingState.LOADED;
			return;
		case getType(calendarActions.updateExternalEvent.failure):
			state.updateLoadingState = LoadingState.ERROR;
			return;
		case getType(calendarActions.deleteExternalEvent.request):
			state.removeLoadingState = LoadingState.LOADING;
			return;
		case getType(calendarActions.deleteExternalEvent.success):
			state.removeLoadingState = LoadingState.LOADED;
			return;
		case getType(calendarActions.deleteExternalEvent.failure):
			state.removeLoadingState = LoadingState.ERROR;
			return;
		case getType(calendarActions.saveSessionEvent.request):
			state.createLoadingState = LoadingState.LOADING;
			return;
		case getType(calendarActions.saveSessionEvent.success):
			state.createLoadingState = LoadingState.LOADED;
			return;
		case getType(calendarActions.saveSessionEvent.failure):
			state.createLoadingState = LoadingState.ERROR;
			return;
		case getType(calendarActions.fetchSessionDetails.request):
			state.sessionLoadingState = LoadingState.LOADING;
			return;
		case getType(calendarActions.fetchSessionDetails.success):
			state.sessionLoadingState = LoadingState.LOADED;
			state.sessionEventView = action.payload.sessionData;
			return;
		case getType(calendarActions.fetchSessionDetails.failure):
			state.sessionLoadingState = LoadingState.ERROR;
			return;
		case getType(calendarActions.clearFetchedSession):
			state.sessionEventView = null;
			return;
		case getType(calendarActions.deleteSessionEvent.request):
			state.removeLoadingState = LoadingState.LOADING;
			return;
		case getType(calendarActions.deleteSessionEvent.success):
			state.removeLoadingState = LoadingState.LOADED;
			return;
		case getType(calendarActions.deleteSessionEvent.failure):
			state.removeLoadingState = LoadingState.ERROR;
			return;
		case getType(calendarActions.undoEventAction):
			state.createLoadingState = LoadingState.EMPTY;
			state.updateLoadingState = LoadingState.EMPTY;
			state.removeLoadingState = LoadingState.EMPTY;
			return;
		case getType(calendarActions.disableViewScroll):
			state.isScrollDisabled = action.payload.isDisabled;
			return;
	}
}, initialState);

export default calendarReducer;
