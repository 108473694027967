import {LoadingState} from "constants/redux";
import produce from "immer";
import {getType} from "typesafe-actions";
import {ClientsActivityState, ClientActivityState} from "../clientActivityTypes";
import {clientActivityActions, ClientActivityActionType} from "./clientActivitiyActions";

export const clientActivityMapReducer = produce(
	(state: ClientsActivityState, action: ClientActivityActionType) => {
		switch (action.type) {
			case getType(clientActivityActions.loadActivities.request):
			case getType(clientActivityActions.loadActivities.success):
			case getType(clientActivityActions.loadActivities.failure):
			case getType(clientActivityActions.loadActivity.request):
			case getType(clientActivityActions.loadActivity.success):
			case getType(clientActivityActions.loadActivity.failure): {
				const {userId} = action.payload;
				state.clientActivities[userId] = clientActivityReducer(state.clientActivities[userId], action);

				return;
			}

			case getType(clientActivityActions.markAsOpenedRecently): {
				state.idOfRecentlyOpenedRegistration = action.payload.activityId;
				return;
			}

			case getType(clientActivityActions.unmarkOpenedRecently): {
				state.idOfRecentlyOpenedRegistration = undefined;
				return;
			}
		}
	},
	{clientActivities: {}} as ClientsActivityState,
);

const defaultState = {activities: [], loadingState: LoadingState.EMPTY};
const clientActivityReducer = produce((state: ClientActivityState, action: ClientActivityActionType) => {
	switch (action.type) {
		case getType(clientActivityActions.loadActivities.request): {
			state.loadingState = LoadingState.LOADING;
			state.error = null;
			break;
		}

		case getType(clientActivityActions.loadActivities.success): {
			state.loadingState = LoadingState.LOADED;
			state.error = null;
			state.activities = action.payload.data;

			return;
		}

		case getType(clientActivityActions.loadActivities.failure): {
			state.loadingState = LoadingState.ERROR;
			state.error = action.payload.err;
			break;
		}

		case getType(clientActivityActions.loadActivity.success): {
			const index = state.activities.findIndex((activity) => activity.id === action.payload.data.id);
			if (index === -1) {
				state.activities.push(action.payload.data);
			} else {
				state.activities[index] = action.payload.data;
			}
			break;
		}
	}
}, defaultState);
