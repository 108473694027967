import {PsychoEducationItemList, PsychoEducationStatus} from "@sense-os/goalie-js";
import {ActionType, createAsyncAction} from "typesafe-actions";

export const interventionPsychoEducationActions = {
	fetchPsychoEducations: createAsyncAction(
		"interventionPsychoEducation/fetchPsychoEducations",
		"interventionPsychoEducation/fetchPsychoEducationsSuccess",
		"interventionPsychoEducation/fetchPsychoEducationsFail",
	)<
		{
			hashId: string;
			page?: number;
			pageSize?: number;
			psychoEducationStatus?: PsychoEducationStatus;
		},
		{hashId: string; data: PsychoEducationItemList},
		{err: Error}
	>(),
};

export type interventionPsychoEducationActionType = ActionType<typeof interventionPsychoEducationActions>;
