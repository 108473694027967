import {DISC} from "IoC/DISC";
import {eventChannel} from "redux-saga";
import {fork, take, put, call, cancelled, select} from "redux-saga/effects";
import {getActiveCall, getCallTypeList} from "redux/videoCall/VideoCallSelectors";
import {States} from "services/chat/video/States";
import analyticsService from "../../../analytics/AnalyticsService";
import {SentryTags} from "../../../errorHandler/createSentryReport";
import {createSendMessage, subscribeToInterWindowCommunication} from "../../../IWC/IWC";
import {WinMsg, WinMsgTypes} from "../../../IWC/WinMsg";
import createLogger from "../../../logger/createLogger";
import {sidebarNavActions} from "../../../sidebarNav/redux/sidebarNavActions";
import {setCallState} from "../../helpers/callHelpers";
import {callActions} from "../../redux/callActions";
import {updateActiveCallData} from "./updateActiveCallData";
import {updateCallWindowData} from "./updateCallWindowData";

const log = createLogger("callWindowSaga", SentryTags.VideoCall);

export function* onIncomingWindowMsgSaga(callWindow: Window) {
	const incomingCallWindowMsgChannel = yield call(createIncomingCallWindowMsgChannel, callWindow);
	try {
		while (true) {
			const msg: WinMsg = yield take(incomingCallWindowMsgChannel);
			yield fork(incomingWindowMsgHandler, callWindow, msg);
		}
	} finally {
		if (yield cancelled()) {
			incomingCallWindowMsgChannel.close();
		}
	}
}

export function* incomingWindowMsgHandler(callWindow: Window, msg: WinMsg) {
	log.debug("incoming iwc data", msg);
	switch (msg.type) {
		case WinMsgTypes.CALL_DIRECTION_CHECK:
			// Check for call direction
			yield call(callDirectionCheck, callWindow);
			break;

		case WinMsgTypes.CALL_TYPES_CHECK:
			// Check call types to be shown in call window
			yield call(callTypesCheck, callWindow);
			break;

		case WinMsgTypes.SELECTED_CALL_TYPE:
			// Set selected call type from call window
			yield put(callActions.startOutgoingCallByType(msg.data));
			break;

		case WinMsgTypes.VIDEO_CALL_WINDOW_READY:
			// Synchronize call window data from the main window by sending them via IWC
			yield fork(syncCallWindowData, callWindow);
			break;

		case WinMsgTypes.HUNG_UP:
			// Simply close the call window when user clicks the end call button
			yield put(callActions.closeCallWindow());
			break;

		case WinMsgTypes.STREAMING_STARTED: {
			// Set call state to IN_CALL so that the other portal tabs cannot initate any call
			yield fork(setCallState, States.IN_CALL);
			// Set streamingStartedDate to determine the call duration when the call has ended
			yield put(callActions.setStreamingStartedDate(new Date()));
			break;
		}
		case WinMsgTypes.INVITE_OTHER_PARTY:
			yield put(callActions.inviteNewParticipantToCall({userId: msg.data.userId}));
			break;

		case WinMsgTypes.TWILIO_PARTICIPANT_LEFT:
			// There may be a case where remote participant has disconnected from the internet.
			// When this happens, the participant won't be able to send termination signal.
			// However, Twilio will send an event to the room which indicates that the participant has left the call.
			// This function will handle it.
			yield fork(DISC.getVideoCallService().handleTwilioParticipantLeft, msg.data);
			break;

		case WinMsgTypes.CALL_WINDOW_CRASH:
			yield put(callActions.callWindowCrashed());
			break;

		case WinMsgTypes.HIDE_SIDEBAR:
			yield put(sidebarNavActions.collapseSidebar());
			break;
	}
}

function* createIncomingCallWindowMsgChannel(targetWindow: Window) {
	return eventChannel((emitter) => {
		const unsubscribe = subscribeToInterWindowCommunication({
			targetWindow,
			onMessage: emitter,
		});

		return unsubscribe;
	});
}

/**
 * This function will be executed when call window is ready to receive IWC messages from the main window.
 */
function* syncCallWindowData(callWindow: Window) {
	const sendIWCMessage = yield call(createSendMessage, callWindow);

	log.debug("syncCallWindowData");

	// Subscribe to redux store and send active call data
	yield fork(updateActiveCallData, callWindow);
	// Subscribe to redux store and send other essential call window data
	yield fork(updateCallWindowData, callWindow);
	// Send analytics instance id to the call window so that we can initialize the analyticsService in the call window with the same ID.
	yield call(sendIWCMessage, {
		type: WinMsgTypes.SHARE_PARENT_ANALYTICS_ID,
		data: {parentInstanceID: analyticsService.getInstanceID()},
	});
}

/**
 * This function will send a call direction to the call window
 */
function* callDirectionCheck(callWindow: Window) {
	log.debug("callDirectionCheck");
	const activeCall = yield select(getActiveCall);

	if (!activeCall) {
		// Do nothing if there's no `activeCall` (outgoing call)
		return;
	}

	const sendIWCMessage = yield call(createSendMessage, callWindow);
	// Send call direction from activeCall to call window
	yield call(sendIWCMessage, {
		type: WinMsgTypes.CALL_DIRECTION_CHECK,
		data: activeCall.direction,
	});
}

/**
 * This function will send a call type list to the call window
 */
function* callTypesCheck(callWindow: Window) {
	log.debug("callTypesCheck");
	const activeCall = yield select(getActiveCall);

	if (activeCall) {
		// Do nothing if there's an `activeCall` (incoming call)
		return;
	}

	const callTypes = yield select(getCallTypeList);
	const sendIWCMessage = yield call(createSendMessage, callWindow);
	// Send call types to call window
	yield call(sendIWCMessage, {
		type: WinMsgTypes.CALL_TYPES_CHECK,
		data: callTypes,
	});
}
