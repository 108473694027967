import {debounce, fork, takeEvery} from "redux-saga/effects";
import {getType} from "typesafe-actions";
import {clientActivityActions} from "../redux/clientActivitiyActions";
import clientActivitiesIntervalSaga from "./clientActivitiesIntervalSaga";
import {loadClientActivities, loadClientActivity} from "./loadClientActivitiesSaga";

export function* clientActivitySaga() {
	yield debounce(1000, getType(clientActivityActions.loadActivities.request), loadClientActivities);
	yield takeEvery(getType(clientActivityActions.loadActivity.request), loadClientActivity);

	yield fork(clientActivitiesIntervalSaga);
}
