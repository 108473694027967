import {TrackerCategory} from "@sense-os/goalie-js";
import {AppConfig} from "app/AppConfig";
import strTranslation from "../../assets/lang/strings";
import localization from "../../localization/Localization";

/** List of core tracker sensors */
export enum CoreTrackerSensorNames {
	check_in = "check_in",
	diary_entry = "diary_entry",
	gscheme = "gscheme",
	meeting_note = "meeting_note",
	step_count = "step_count",
}

/** List of core tracker model name except `SensorData` */
export enum CoreTrackerModelNames {
	Homework = "Homework",
	BehaviorExperiment = "BehaviorExperiment",
}

/**
 * Returns true if Tracker is a core trackers.
 */
export function isCoreTrackers(sensorName: string): boolean {
	return !!CoreTrackerSensorNames[sensorName] || !!CoreTrackerModelNames[sensorName];
}

/** List of core tracker id */
export enum CoreTrackerId {
	BEHAVIOR_EXPERIMENT = AppConfig.BEHAVIOR_EXPERIMENT_ID,
	CHECK_IN = AppConfig.CHECK_IN_ID,
	DIARY_NOTE = AppConfig.DIARY_NOTE_ID,
	MEETING_NOTES = AppConfig.MEETING_NOTES_ID,
	PSYCHOEDUCATION = AppConfig.PSYCHOEDUCATION_ID,
	THOUGHT_RECORD = AppConfig.THOUGHT_RECORD_ID,
	STEP_COUNT = AppConfig.STEP_COUNT_ID,
}

/** Default Tracker Category */
export const defaultTrackerCategory = "tracker.category.default";

/** Get Filtered Tracker Categories without `defaultTrackerCategory` */
export function filterDefaultTrackerCategory(trackerCategory: TrackerCategory) {
	return trackerCategory.category !== defaultTrackerCategory;
}

/** Sensor Data Model Name */
export const SensorDataModelName = "SensorData";

/** Core Tracker Translation Map */
export const coreTrackerTranslationMap = {
	[CoreTrackerSensorNames.check_in]: strTranslation.ZZ_CORE_TRACKER.tracker.check_in.name,
	[CoreTrackerSensorNames.diary_entry]: strTranslation.ZZ_CORE_TRACKER.tracker.diary_entry.name,
	[CoreTrackerSensorNames.gscheme]: strTranslation.ZZ_CORE_TRACKER.tracker.gscheme.name,
	[CoreTrackerSensorNames.meeting_note]: strTranslation.ZZ_CORE_TRACKER.tracker.meeting_note.name,
	[CoreTrackerSensorNames.step_count]: strTranslation.ZZ_CORE_TRACKER.tracker.step_count.name,

	[CoreTrackerModelNames.BehaviorExperiment]: strTranslation.ZZ_CORE_TRACKER.tracker.behavior_experiment.name,
	[CoreTrackerModelNames.Homework]: strTranslation.ZZ_CORE_TRACKER.tracker.homework.name,
};

/** Translated Core Tracker Name */
export const getTranslatedCoreTrackerName = (sensorName: string): string => {
	if (!!coreTrackerTranslationMap[sensorName]) {
		return localization.formatMessage(coreTrackerTranslationMap[sensorName]);
	}
	return "";
};
