import {call, put, select, all, takeEvery} from "redux-saga/effects";
import {ActionType, getType} from "typesafe-actions";
import {customTrackerActions} from "../redux/customTrackerActions";
import {getSessionId} from "../../../auth/helpers/authStorage";
import createLogger from "../../../logger/createLogger";
import {SentryTags} from "../../../errorHandler/createSentryReport";
import {getLoggerConfigsByScheduleType} from "../../../ts/redux/loggerConfig/LoggerConfigSelector";
import {LoggerConfig} from "../../../ts/redux/loggerConfig/LoggerConfigTypes";
import {DISC} from "../../../ts/IoC/DISC";
import {LoggerConfigAction} from "../../../ts/redux/loggerConfig/LoggerConfigAction";
import {getSelectedContactId} from "../../../contacts/redux/contactSelectors";
import trackersSDK from "../helpers/trackersSDK";

const log = createLogger("manageTrackersSaga", SentryTags.Tracking);

function* handleSaveManageTrackers(action: ActionType<typeof customTrackerActions.saveManageTrackers.request>) {
	const {trackers, userId, isEnabled, trackerId} = action.payload;

	try {
		const token: string = yield call(getSessionId);

		// TODO: remove this if the task has reached production,
		// since the "tasks" will replace the logger reminder. and we don't need to hit the logger reminder again
		// Remove tracker reminders
		const disabledTrackerScheduleTypes = trackers
			.filter((tracker) => !tracker.isEnabled)
			.map((tracker) => tracker.sensorName);
		yield call(removeTrackerReminders, disabledTrackerScheduleTypes);

		yield call(trackersSDK.updateTrackerStatus, token, {
			isEnabled,
			tracker: trackerId,
			patient: userId,
		});

		yield put(customTrackerActions.saveManageTrackers.success());
	} catch (err) {
		log.captureException(err);
		yield put(customTrackerActions.saveManageTrackers.failure(err));
	}
}

export function* removeTrackerReminders(scheduleTypes: string[]) {
	yield all(scheduleTypes.map((scheduleType) => call(removeTrackerReminder, scheduleType)));
}

function* removeTrackerReminder(scheduleType: string) {
	const reminders: LoggerConfig[] = yield select(getLoggerConfigsByScheduleType(scheduleType as any));
	const requests = reminders.map((reminder) => call(removeReminder, reminder));
	yield all(requests);
}

export function* removeReminder(loggerReminder: LoggerConfig) {
	const selectedClientId = yield select(getSelectedContactId);
	const {rawRecurringExpression, id} = loggerReminder;
	yield call(DISC.getRecurringSchedule().removeRecurringSchedule, id, rawRecurringExpression);
	yield put(LoggerConfigAction.loggerConfigRemoved(selectedClientId, loggerReminder.scheduleType, id));
}

export default function* () {
	yield takeEvery(getType(customTrackerActions.saveManageTrackers.request), handleSaveManageTrackers);
}
