import moment from "moment";

import {TIME_UNITS} from "../../constants/time";
import {endOfRange, endOfWeek, startEndToRange, startOfRange, startOfWeek} from "../../utils/time";

import {Interval, TimeRange} from "./TrackingGraphTypes";
import {QuickNav} from "./TrackingTypes";

/** Creates an interval-id: '$start_$end' */
function intervalId(start: number, end: number): string {
	return `${start}_${end}`;
}

export function getSubInterval(interval: Interval): Interval[] {
	const {start} = interval;
	const results: Interval[] = [];
	switch (interval.range) {
		case TimeRange.FOUR_WEEKS:
			for (let i = 0; i < 4; i++) {
				let s = moment(start).add(i, TIME_UNITS.WEEKS);
				results.push(newInterval(s.valueOf(), endOfWeek(s).valueOf(), TimeRange.WEEK));
			}
			break;

		case TimeRange.WEEK:
			for (let i = 0; i < 7; i++) {
				let s = moment(start).add(i, TIME_UNITS.DAY);
				results.push(newInterval(s.valueOf(), s.endOf(TIME_UNITS.DAY).valueOf(), TimeRange.DAY));
			}
			break;

		default:
			break;
	}

	return results;
}

/** Creates an interval-object */
export function newInterval(start: number, end: number, range?: TimeRange): Interval {
	const interval: Interval = {
		id: intervalId(start, end),
		start: new Date(start),
		end: new Date(end),
		range: range || startEndToRange(start, end),
	};

	// generate sub-intervals that should be rendered for this interval..
	interval.children = getSubInterval(interval);

	return interval;
}

/** Given an interval, return its previous interval. */
export function toPrevInterval(interval: Interval): Interval {
	let end = moment(interval.start).subtract(1, TIME_UNITS.DAY); // go from Monday to Sunday
	end = endOfWeek(end);

	return newInterval(startOfRange(end, interval.range), end.valueOf(), interval.range);
}

/** Given an interval, return its next interval. */
export function toNextInterval(interval: Interval): Interval {
	let start = moment(interval.end).add(1, TIME_UNITS.DAY); // go from Sunday to Monday
	start = startOfWeek(start);

	return newInterval(start.valueOf(), endOfRange(start, interval.range), interval.range);
}

/** Given an interval and a new TimeRange, return a new interval
 * with same ending as previous one but in the given new TimeRange.
 */
export function toIntervalWithGivenRange(interval: Interval, newRange: TimeRange): Interval {
	if (newRange === interval.range) return interval;
	return newInterval(startOfRange(interval.end.getTime(), newRange), interval.end.getTime(), newRange);
}

/** Given a range, invert it to other range. */
const invertRange = (range: TimeRange): TimeRange => {
	return range === TimeRange.WEEK ? TimeRange.FOUR_WEEKS : TimeRange.WEEK;
};

/** Given interval, return interval with new inverted range */
export function toIntervalWithInvertedRange(interval: Interval): Interval {
	return toIntervalWithGivenRange(interval, invertRange(interval.range));
}

/** Calculate the interval of given QuickNav */
export function navToInterval(nav: QuickNav): Interval {
	let momentObj: moment.Moment = null,
		timeRange = TimeRange.FOUR_WEEKS;

	switch (nav) {
		case QuickNav.LAST_WEEK:
			momentObj = moment().subtract(1, TIME_UNITS.WEEKS);
			timeRange = TimeRange.WEEK;
			break;
		case QuickNav.THIS_WEEK:
			momentObj = moment();
			timeRange = TimeRange.WEEK;
			break;
		case QuickNav.LAST_MONTH:
			momentObj = moment().subtract(1, TIME_UNITS.MONTHS).startOf(TIME_UNITS.MONTHS);
			break;
		case QuickNav.THIS_MONTH:
		default:
			momentObj = moment().startOf(TIME_UNITS.MONTHS);
	}

	const start = startOfWeek(momentObj);
	return newInterval(start.valueOf(), endOfRange(start.valueOf(), timeRange));
}
