import {FileChatItem} from "@sense-os/goalie-js";
import {LoadingState} from "constants/redux";
import {NumberMap} from "services/utils/Maps";

export type FileItem = {
	/**
	 * Unique ID from the database.
	 */
	id: number;
	/**
	 * Person id who upload the file.
	 */
	authorId: number;
	/**
	 * File Name
	 */
	fileName: string;
	/**
	 * File Name
	 */
	fileSize: number;
	/**
	 * The timestamp of the file when it's shared / uploaded.
	 */
	timestamp: Date;
	/**
	 * The caption of the uploaded file.
	 */
	description: string;
	/**
	 * Url of the file.
	 */
	url?: string;
	/**
	 * Smaller size Url of the file.
	 */
	thumbnailUrl?: string;
	/**
	 * Url of the file.
	 */
	blobUrl?: string;
	/**
	 * Smaller size Url of the file.
	 */
	blobThumbnailUrl?: string;

	isDeleted?: boolean;
};

export interface SelectedFile {
	/**
	 * Blob url of selected file
	 */
	blobUrl: string;
	/**
	 * File name of selected file
	 */
	fileName: string;
	/**
	 * File type of selected file
	 */
	fileType: string;
	/**
	 * File size of selected file
	 */
	fileSize: number;
	/**
	 * A base64 string of selected file
	 */
	fileData: Blob;
	/**
	 * Image thumbnails max width ImageSize.HIGH
	 */
	thumb_hdpi: Blob;
	/**
	 * Image thumbnails max width ImageSize.MEDIUM
	 */
	thumb_mdpi: Blob;
	/**
	 * Image thumbnails max width ImageSize.LOW
	 */
	thumb_ldpi: Blob;
	/**
	 * Uploading state.
	 */
	uploadingState: LoadingState;
}

export interface FileSharingUploadState {
	/**
	 * File browser open state
	 */
	isFileBrowserOpened: boolean;
	/**
	 * Selected files from local device map
	 */
	selectedFiles: NumberMap<SelectedFile[]>;
	/**
	 * Uploading state of the file.
	 */
	uploadingState: LoadingState;
}

/**
 *  State of File Sharing per User id
 */
export interface UserFileSharingState {
	/**
	 * Flag to check if there's anoter that to fetch.
	 */
	hasMore?: boolean;
	/**
	 * Current fetched page.
	 */
	currentPage: number;
	/**
	 * List of fetched / loaded files.
	 */
	loadedFiles: FileItem[];
	/**
	 * Loading State
	 */
	loadingState: LoadingState;
}

export interface FileSharingState {
	/**
	 * List of ids about to deleted.
	 */
	deletingIds: number[];
	/**
	 * The state of deleting data.
	 */
	deletingState: LoadingState;
	/**
	 * Flag to open/close delete dialog.
	 */
	isDeleteDialogOpen: boolean;
	/**
	 * Mapped FileSharingState by UserId.
	 */
	files: NumberMap<UserFileSharingState>;
}

export interface FileUploadResult {
	/**
	 * Is file successfully uplaoded?
	 */
	success: boolean;
	/**
	 * Upload file error
	 */
	error: Error;
	/**
	 * Payload response from upload file.
	 */
	data: FileChatItem;
}

/**
 * Maximun file size in bytes
 */
export const MAX_FILE_SIZE = 5_000_000;
export const MAX_IMAGE_SIZE = 20_000_000;

/**
 * Maximum selected files amount
 */
export const MAX_FILES_AMOUNT = 15;

/**
 * File sharing validation alert type
 */
export enum ValidationType {
	FILE_TYPE = "file_type",
	FILE_SIZE = "file_size",
	FILE_AMOUNT = "file_amount",
	IMAGE_SIZE = "image_size",
}

/**
 * Enum for filter based on file type
 */
export enum FileTypeFilter {
	MEDIA = "media",
	DOCUMENTS = "documents",
	ALL = "all",
}

/**
 * Enum for filter based on person who shared then files
 */
export enum PersonFilter {
	NAME = "name",
	ME = "me",
	ALL = "all",
}

/**
 * Image size for thumbnails
 */
export enum ImageSize {
	HIGH = 280,
	MEDIUM = 130,
	LOW = 80,
}

/**
 * Empty message string for send file without caption,
 * FYI deleting this might affect the mobile app.
 */
export const EMPTY_MESSAGE = "&nbsp;";
