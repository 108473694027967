import {ProductFruits} from "react-product-fruits";
import {useSelector} from "react-redux";

import {AppConfig} from "app/AppConfig";
import loc from "../localization/Localization";
import {isContactsLoaded} from "../contacts/redux/contactSelectors";
import {useProductFruits} from "./useProductFruits";
import {LanguageTag} from "@sense-os/goalie-js";

let locale: string = loc.getLocale();

export const NDProductFruits = () => {
	const {isProductFruitsAccessible, userInfo} = useProductFruits();
	const contactsIsLoaded = useSelector(isContactsLoaded);

	if (locale === LanguageTag.PT_BR) {
		// Use `en` instead if selected language is `pt-br`
		// because Productfruits did not support `pt-br`
		locale = LanguageTag.EN;
	}

	if (isProductFruitsAccessible && contactsIsLoaded) {
		return (
			<ProductFruits
				workspaceCode={AppConfig.PRODUCTFRUITS_WORKSPACE_KEY}
				language={locale}
				user={userInfo}
				dontDestroy
			/>
		);
	}

	return <></>;
};
