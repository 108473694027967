import {Typography} from "@material-ui/core";
import {useMemo} from "react";
import {SensorDatum} from "redux/tracking/TrackingTypes";
import strTranslation from "../../../../assets/lang/strings";
import localization from "../../../../localization/Localization";
import {NDColors} from "../../../../material-ui/NDColors";
import {
	GraphV2Type,
	IGraphConfig,
	IGraphV2Data,
	IUseGraphData,
	TrackerRegistrationGroupByDate,
} from "../../customTrackerGraphV2Types";
import {CustomTrackerSensor} from "../../customTrackerTypes";
import {GRAPH_TIME_VIEW_VALUE} from "./graphV2Config";
import {getActiveStringDates, getEndDate, transformRegistrationDateFromString} from "./graphV2ConfigHelper";
import {useGetXTickValuePlainData, useGetXTickValues, useGetYTickValues} from "./graphV2TickHooks";

// minimum value of Y scale
const HIGHEST_Y_SCALE: number = 10;
// always return 6 lines start from 0
const Y_LINES: number = 6;

const getGraphData = (
	measureString: string,
	trackerRegistration: TrackerRegistrationGroupByDate,
	selectedTimeView: GRAPH_TIME_VIEW_VALUE,
	startDate: Date,
	endDate: Date,
): IGraphV2Data[] => {
	const activeDates: string[] = getActiveStringDates(trackerRegistration, selectedTimeView, startDate, endDate);
	const happenedGraph = {
		id: strTranslation.CUSTOM_TRACKER.chart.boolean_true.tooltip,
		color: NDColors.ORANGE,
		data: activeDates
			.map((date) => {
				const xDate = transformRegistrationDateFromString(date);
				const totalHappened = trackerRegistration[date].reduce((total, tracker) => {
					const booleanValue =
						tracker.value.measures[measureString]?.sensorData?.value?.boolean === true ? 1 : 0;
					return total + booleanValue;
				}, 0);

				return {
					x: xDate,
					y: totalHappened,
				};
			})
			.filter((data) => data.y > 0),
	};
	const didNotHappenGraph = {
		id: strTranslation.CUSTOM_TRACKER.chart.boolean_false.tooltip,
		color: NDColors.ROYAL_BLUE,
		data: activeDates
			.map((date) => {
				const xDate = transformRegistrationDateFromString(date);
				const totalDidNotHappen = trackerRegistration[date].reduce((total, tracker) => {
					const booleanValue =
						tracker.value.measures[measureString]?.sensorData?.value?.boolean === false ? 1 : 0;
					return total + booleanValue;
				}, 0);
				return {
					x: xDate,
					// to differentiate slightly if data is same as happenedGraph
					y: totalDidNotHappen + 0.1,
				};
			})
			.filter((data) => data.y > 0.1),
	};
	// filter graph that contains zero value
	// https://github.com/plouc/nivo/issues/1854
	const resultData = [happenedGraph, didNotHappenGraph].filter((res) => res.data.length > 0);
	return resultData;
};

export const booleanConfig = (measureString: string): IGraphConfig => {
	return {
		graphType: GraphV2Type.BOOLEAN,
		graphName: localization.formatMessage(strTranslation.CUSTOM_TRACKER.chart.boolean_true.tooltip),
		yLegend: localization.formatMessage(strTranslation.GRAPHS.events.header),
		xLegend: localization.formatMessage(strTranslation.TIME.date),
		yToolTipFormatter: (label, value) => {
			return (
				<Typography variant="body2">{`${localization.formatMessage(label)} : ${Math.round(value)}`}</Typography>
			);
		},
		useGraphData: (
			trackerRegistration: TrackerRegistrationGroupByDate,
			trackerName: string,
			graphType: GraphV2Type,
			startDate: Date,
			selectedTimeView: GRAPH_TIME_VIEW_VALUE,
		): IUseGraphData => {
			const endDate = getEndDate(selectedTimeView, startDate);

			// data
			const graphData: IGraphV2Data[] = useMemo(() => {
				return getGraphData(measureString, trackerRegistration, selectedTimeView, startDate, endDate);
				// eslint-disable-next-line react-hooks/exhaustive-deps
			}, [trackerRegistration, trackerName, graphType, selectedTimeView, startDate]);

			// divide by Y_AXIS - 1 because of there are only 5 lines that have value
			const yTickValues: number[] = useGetYTickValues(graphData, HIGHEST_Y_SCALE, Y_LINES);

			const xTickValues: Date[] = useGetXTickValues(graphData, selectedTimeView, startDate, endDate);

			return {
				data: graphData,
				yMax: yTickValues[yTickValues.length - 1],
				yTickValues,
				xMin: xTickValues[0],
				xMax: xTickValues[xTickValues.length - 1],
				xTickValues,
			};
		},

		useAllTimePlainGraphData: (trackerRegistration: SensorDatum<CustomTrackerSensor>[], trackerName: string) => {
			const graphData: IGraphV2Data[] = useMemo(() => {
				const startDate = trackerRegistration?.[0]?.startTime;
				const trackerRegistrationLength = trackerRegistration.length;
				const endDate = trackerRegistration?.[trackerRegistrationLength]?.startTime;

				const trackerRegistrationGroupByDate: TrackerRegistrationGroupByDate = trackerRegistration.reduce(
					(result, trackerData) => {
						const date: number = trackerData.startTime.getDate();
						const month: number = trackerData.startTime.getMonth();
						const year: number = trackerData.startTime.getFullYear();
						const monthDateYear: string = `${date}/${month}/${year}`;
						return {
							...result,
							[monthDateYear]: result[monthDateYear]
								? result[monthDateYear].concat(trackerData)
								: [trackerData],
						};
					},
					{},
				);

				return getGraphData(
					measureString,
					trackerRegistrationGroupByDate,
					GRAPH_TIME_VIEW_VALUE.ALL_TIME,
					startDate,
					endDate,
				);

				// eslint-disable-next-line react-hooks/exhaustive-deps
			}, [trackerRegistration, trackerName]);

			const yTickValues: number[] = useGetYTickValues(graphData, HIGHEST_Y_SCALE, Y_LINES);

			const xTickValues: Date[] = useGetXTickValuePlainData(graphData);

			return {
				data: graphData,
				yMax: yTickValues[yTickValues.length - 1],
				yTickValues,
				xMin: xTickValues[0],
				xMax: xTickValues[xTickValues.length - 1],
				xTickValues,
			};
		},
	};
};
