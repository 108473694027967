import {ActionType, createAction, createAsyncAction} from "typesafe-actions";
import {CalendarSessionRequestBody} from "@sense-os/goalie-js";

import {EventViewData} from "redux/tracking/TrackingTypes";

import {DateString, CalendarAccount, CalendarEvent, TimeView, CalendarColor, Calendar} from "../calendarTypes";
import {CalendarEventFormValues} from "../views/forms/formTypes";

export const calendarActions = {
	// Calendar view navigation actions
	goToPrevWeek: createAction("calendarActions/goToPrevWeek")(),
	goToNextWeek: createAction("calendarActions/goToNextWeek")(),
	goToPrevDay: createAction("calendarActions/goToPrevDay")(),
	goToNextDay: createAction("calendarActions/goToNextDay")(),
	goToToday: createAction("calendarActions/goToToday")(),

	// Set selected date actions
	setDate: createAction("calendarActions/setDate", (date: DateString) => ({date}))(),

	// Show / hide weekend days in calendar view action
	setWeekendView: createAction("calendarActions/setWeekendView", (isWeekendShown: boolean) => ({
		isWeekendShown,
	}))(),

	// Set selected day / week view action
	setTimeView: createAction("calendarActions/setTimeView", (timeView: TimeView) => ({timeView}))(),

	// Set selected time action
	setSelectedTime: createAction("calendarActions/setSelectedTime", (selectedTime: Date) => ({selectedTime}))(),

	// Set initial calendars color action
	setInitialCalendarsColor: createAction(
		"calendarActions/setInitialCalendarsColor",
		(calendarsColorMap: Record<string, CalendarColor>) => ({
			calendarsColorMap,
		}),
	)(),

	// Update calendar color action
	updateCalendarColor: createAction(
		"calendarActions/updateCalendarColor",
		(payload: {calendarId: string; color: CalendarColor}) => payload,
	)(),

	// Set calendar visibility action
	setInitialCalendarVisibility: createAction(
		"calendarActions/setInitialCalendarVisibility",
		(calendarsVisibilityMap: Record<string, boolean>) => ({
			calendarsVisibilityMap,
		}),
	)(),

	// Update calendar visibility action
	updateCalendarVisibility: createAction(
		"calendarActions/updateCalendarVisibility",
		(payload: {calendarId: string; isVisible: boolean}) => payload,
	)(),

	// Connect user to calendar action
	connectCalendar: createAction("calendarActions/connectCalendar")(),

	// Create Cronofy access token action
	createAccessToken: createAction("calendarActions/createAccessToken", (cronofyCode: string) => ({cronofyCode}))(),

	// External event access url action
	getExternalEventAccess: createAction("calendarActions/getExternalEventAccess", (calendarId: string) => ({
		calendarId,
	}))(),

	// Get calendar account action
	getCalendarAccount: createAsyncAction(
		"calendarActions/getCalendarAccount",
		"calendarActions/getCalendarAccountSuccess",
		"calendarActions/getCalendarAccountFail",
	)<void, {calendarAccount: CalendarAccount}, Error>(),

	// Revoke synced calendar profile
	revokeCalendarProfile: createAsyncAction(
		"calendarActions/revokeCalendarProfile",
		"calendarActions/revokeCalendarProfileSuccess",
		"calendarActions/revokeCalendarProfileFail",
	)<{calendar: Calendar}, void, Error>(),

	// Get calendar events action
	getCalendarEvents: createAsyncAction(
		"calendarActions/getCalendarEvents",
		"calendarActions/getCalendarEventsSuccess",
		"calendarActions/getCalendarEventsFail",
	)<void, {calendarEvents: Record<DateString, CalendarEvent[]>}, Error>(),

	// Create internal calendar event actions
	createInternalEvent: createAsyncAction(
		"calendarActions/createInternalEvent",
		"calendarActions/createInternalEventSuccess",
		"calendarActions/createInternalEventFail",
	)<{calendarId: string; formValues: CalendarEventFormValues}, void, Error>(),

	// Update internal calendar event actions
	updateInternalEvent: createAsyncAction(
		"calendarActions/updateInternalEvent",
		"calendarActions/updateInternalEventSuccess",
		"calendarActions/updateInternalEventFail",
	)<{calendarId: string; eventId: string; formValues: CalendarEventFormValues}, void, Error>(),

	// Delete internal calendar event actions
	deleteInternalEvent: createAsyncAction(
		"calendarActions/deleteInternalEvent",
		"calendarActions/deleteInternalEventSuccess",
		"calendarActions/deleteInternalEventFail",
	)<{calendarId: string; eventId: string}, void, Error>(),

	// Update external calendar event actions
	updateExternalEvent: createAsyncAction(
		"calendarActions/updateExternalEvent",
		"calendarActions/updateExternalEventSuccess",
		"calendarActions/updateExternalEventFail",
	)<{calendarId: string; eventUid: string; formValues: CalendarEventFormValues}, void, Error>(),

	// Delete external calendar event actions
	deleteExternalEvent: createAsyncAction(
		"calendarActions/deleteExternalEvent",
		"calendarActions/deleteExternalEventSuccess",
		"calendarActions/deleteExternalEventFail",
	)<{calendarId: string; eventUid: string}, void, Error>(),

	// Save calendar session event actions
	saveSessionEvent: createAsyncAction(
		"calendarActions/saveSessionEvent",
		"calendarActions/saveSessionEventSuccess",
		"calendarActions/saveSessionEventFail",
	)<{calendarId: string; body: CalendarSessionRequestBody}, void, Error>(),

	// Delete calendar session event actions
	deleteSessionEvent: createAsyncAction(
		"calendarActions/deleteSessionEvent",
		"calendarActions/deleteSessionEventSuccess",
		"calendarActions/deleteSessionEventFail",
	)<{calendarId: string; eventId: string}, void, Error>(),

	// Fetch session details actions
	fetchSessionDetails: createAsyncAction(
		"calendarActions/fetchSessionDetails",
		"calendarActions/fetchSessionDetailsSuccess",
		"calendarActions/fetchSessionDetailsFail",
	)<{sessionId: string}, {sessionData: EventViewData}, Error>(),

	// Clear fetched session details action
	clearFetchedSession: createAction("calendarActions/clearFetchedSession")(),

	// Undo event creation, update, and delete action
	undoEventAction: createAction("calendarActions/undoEventAction", (undoId: string) => ({undoId}))(),

	// Disable calendar view scroll
	disableViewScroll: createAction("calendarActions/disableViewScroll", (isDisabled: boolean) => ({
		isDisabled,
	}))(),
};

export type calendarActionType = ActionType<typeof calendarActions>;
