import {remoteThoughtRecordSensorToThoughtRecordSensor, ThoughtRecord} from "@sense-os/goalie-js";
import strTranslation from "../../assets/lang/strings";
import localization from "../../localization/Localization";
import {createEventViewId, getSensorNamesFromObject} from "../../ts/redux/tracking/TrackingHelper";
import {
	ActivityTypes,
	DailyPlannerItem,
	PlannedEventSensorDP,
	PlannedEventStatus,
	RecurringPlannedEventDP,
	ScheduleType,
	EventViewType,
	EventViewData,
	RecurringPlannedEventView,
	Sensors,
	ResolvedSensorData,
} from "../../ts/redux/tracking/TrackingTypes";
import {isThoughtRecordSensor} from "./sensorDataActivityHelpers";

/**
 * Transforms DailyPlannerItem into EventViewData
 *
 * DailyPlannerItem may contains one of these data:
 * - RecurringPlannedEvent
 * - TherapySession
 * - PlannedEventSensor
 * - SMQ
 * - OMQ
 *
 * This function transform DailyPlannerItem into one of those EventViewData above.
 *
 * @param dpItem
 */
export function transformDailyPlannerIntoEventViewData(dpItem: DailyPlannerItem): EventViewData | null {
	if (isRecurringPlannedEventDP(dpItem)) {
		return transformRecurringPlannedEventDP(dpItem);
	}
	if (isLegacyPlannedEvent(dpItem)) {
		return transformPlannedEventSensorDP(dpItem, EventViewType.PLANNED_EVENT_SENSOR);
	}
	if (isTherapySession(dpItem)) {
		return transformPlannedEventSensorDP(dpItem, EventViewType.THERAPY_SESSION_SENSOR);
	}
	if (isSMQ(dpItem)) {
		return transformSmqOmqPlannedEventSensorDP(dpItem, EventViewType.SMQ_SENSOR);
	}
	if (isOMQ(dpItem)) {
		return transformSmqOmqPlannedEventSensorDP(dpItem, EventViewType.OMQ_SENSOR);
	}
	return null;
}

/**
 * Returns true if `DailyPlannerItem` is a planned event sensor, and has Fun/Exposure/Other activity type
 *
 * @param dpItem
 */
function isLegacyPlannedEvent(dpItem: DailyPlannerItem): dpItem is PlannedEventSensorDP {
	return isMatchingActivityType(dpItem, [
		ActivityTypes.FUN_ACTIVITY,
		ActivityTypes.EXPOSURE_ACTIVITY,
		ActivityTypes.OTHER_ACTIVITY,
	]);
}

/**
 * Returns true if `DailyPlannerItem` is a planned event sensor, and has THERAPY_SESSION activity type
 *
 * @param dpItem
 */
function isTherapySession(dpItem: DailyPlannerItem): dpItem is PlannedEventSensorDP {
	return isMatchingActivityType(dpItem, [ActivityTypes.THERAPY_SESSION]);
}

/**
 * Returns true if `DailyPlannerItem` is a planned event sensor, and has FILL_SMQ activity type
 *
 * @param dpItem
 */
function isSMQ(dpItem: DailyPlannerItem): dpItem is PlannedEventSensorDP {
	return isMatchingActivityType(dpItem, [ActivityTypes.FILL_SMQ]);
}

/**
 * Returns true if `DailyPlannerItem` is a planned event sensor, and has FILL_OMQ activity type
 *
 * @param dpItem
 */
function isOMQ(dpItem: DailyPlannerItem): dpItem is PlannedEventSensorDP {
	return isMatchingActivityType(dpItem, [ActivityTypes.FILL_OMQ]);
}

/**
 * Returns `true` if `dpItem` contains matching `activityTypes`
 *
 * @param dpItem
 * @param activityTypes
 */
function isMatchingActivityType(
	dpItem: DailyPlannerItem,
	activityTypes: ActivityTypes[],
): dpItem is PlannedEventSensorDP {
	if (!isPlannedEventSensorDP(dpItem)) {
		return false;
	}
	const data = dpItem.payload.sensors[0].sensorData;
	return activityTypes.includes(data.value.activityType);
}

/**
 * Returns `true` if DailyPlanner's schedule_type is `planned_event_entry`
 *
 * @param dpItem
 * @param activityTypes
 */
function isPlannedEventSensorDP(dpItem: DailyPlannerItem): dpItem is PlannedEventSensorDP {
	return dpItem.scheduleType === ScheduleType.PLANNED_EVENT_SENSOR;
}

/**
 * Returns `true` if DailyPlanner's schedule_type is `planned_event_log`
 *
 * @param dpItem
 * @param activityTypes
 */
function isRecurringPlannedEventDP(dpItem: DailyPlannerItem): dpItem is RecurringPlannedEventDP {
	return dpItem.scheduleType === ScheduleType.RECURRING_PLANNED_EVENT;
}

/**
 * Transforms SMQ or OMQ planned event sensor to EventViewData.
 */
function transformSmqOmqPlannedEventSensorDP(
	dpItem: PlannedEventSensorDP,
	type: EventViewType.SMQ_SENSOR | EventViewType.OMQ_SENSOR,
): EventViewData {
	const eventViewData = transformPlannedEventSensorDP(dpItem, type);

	const questionnaireName =
		type === EventViewType.SMQ_SENSOR
			? localization.formatMessage(strTranslation.ZZ_SMQ.name)
			: localization.formatMessage(strTranslation.ZZ_OMQ.name);

	// Add postfix to the title, because SMQ and OMQ questionnaires have the same title with its Session title (Therapy session)
	// Example:
	// Therapy session title -> Meeting with John
	// SMQ title would be -> Meeting with John - SMQ
	// OMQ title would be -> Meeting with John - OMQ
	eventViewData.title = [eventViewData.title, questionnaireName].join(" - ");

	return eventViewData;
}

function transformPlannedEventSensorDP(
	dpItem: PlannedEventSensorDP,
	type:
		| EventViewType.PLANNED_EVENT_SENSOR
		| EventViewType.OMQ_SENSOR
		| EventViewType.SMQ_SENSOR
		| EventViewType.THERAPY_SESSION_SENSOR,
): EventViewData {
	const source = dpItem.payload.sensors[0].sensorData;
	const isCompleted = source.value.status === PlannedEventStatus.COMPLETE;
	const isCanceled = source.value.status === PlannedEventStatus.CANCELED;
	return {
		id: createEventViewId(type, source.id, dpItem.occurrenceTime),
		ownerId: source.userId,
		title: dpItem.title,
		startTime: dpItem.startTime,
		endTime: dpItem.endTime,
		sensors: [source.sensorName, ...getSensorNamesFromObject(source)],
		type,
		source,
		isCompleted,
		isCanceled,
		createdAt: dpItem.createdAt,
		updatedAt: dpItem.updatedAt,
		createdBy: dpItem.createdBy,
		updatedBy: dpItem.updatedBy,
	};
}

function transformRecurringPlannedEventDP(dpItem: RecurringPlannedEventDP): RecurringPlannedEventView {
	const {plannedEvent} = dpItem.payload;
	const {reflection} = plannedEvent;
	/**
	 * Event item component should render when the event actually held or happened (happenedAt),
	 * if it's not done yet then show the event planend at date (occurenceTime).
	 *
	 * happenedAt is the date/time when the event was actually held
	 * occurenceTime is the date/time when the event is planned
	 * https://niceday.productboard.com/feature-board/1723440-new-features-for-therapists/features/3187871/detail
	 * - Client could see the completed events on the daily planner sorted by the checked of for time
	 * */
	const checkedOffFor = reflection?.sensorData?.value?.happenedAt
		? reflection.sensorData.value.happenedAt
		: dpItem.occurrenceTime;
	const checkedOffAt = reflection?.sensorData ? reflection.sensorData.createdAt : null;

	const hasThoughtRecord =
		!!reflection?.sensorData.value.gscheme && isThoughtRecordSensor(reflection.sensorData.value.gscheme.sensorData);

	if (hasThoughtRecord) {
		// Transform thought record sensor IF `reflection` has a `gscheme` sensor with version > 1
		reflection.sensorData.value.gscheme = {
			sensorData: remoteThoughtRecordSensorToThoughtRecordSensor(
				reflection?.sensorData.value.gscheme.sensorData as any,
			),
		} as ResolvedSensorData<ThoughtRecord>;
	}

	const happenedAt = reflection?.sensorData?.value?.happenedAt;
	/**
	 * Based on this issue,
	 * we need to change occurrenceTime to happenedAt for completed dailyPlanner
	 * https://github.com/senseobservationsystems/goalie-backend/issues/4084
	 **/
	return {
		id: createEventViewId(
			EventViewType.RECURRING_PLANNED_EVENT,
			plannedEvent.id,
			happenedAt ?? dpItem.occurrenceTime,
		),
		ownerId: plannedEvent.owner,
		title: dpItem.title,
		startTime: checkedOffFor,
		endTime: dpItem.endTime,
		sensors: [Sensors.PLANNED_EVENT, ...getSensorNamesFromObject(plannedEvent)],
		type: EventViewType.RECURRING_PLANNED_EVENT,
		source: hasThoughtRecord
			? {
					...dpItem,
					payload: {
						...dpItem.payload,
						plannedEvent: {
							...dpItem.payload.plannedEvent,
							reflection: reflection,
						},
					},
			  }
			: dpItem,
		isCompleted: dpItem.isCompleted,
		isCanceled: dpItem.isCanceled,
		createdAt: dpItem.createdAt,
		updatedAt: dpItem.updatedAt,
		createdBy: dpItem.createdBy,
		updatedBy: dpItem.updatedBy,
		checkedOffFor,
		checkedOffAt,
	};
}
