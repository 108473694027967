import {NotFoundError, OrganizationMemberStatus, InvalidPageError} from "@sense-os/goalie-js";
import {Therapist} from "@sense-os/goalie-js/dist/organization/Therapist";
import {AuthUser} from "../../auth/authTypes";
import featureFlags from "../../featureFlags/FeatureFlags";
import organizationSdk from "./organizationSdk";

/**
 * Get all therapists from all organizations
 *
 * @param token
 * @returns
 */
export async function fetchAllTherapists(token: string, authUser: AuthUser, all?: boolean): Promise<Therapist[]> {
	const pageSize = 200;
	let page: number = 1;
	let therapists: Therapist[] = [];
	let groupId: number = undefined;
	let organizationsId: number[] = undefined;

	if (featureFlags.therapistOrgContacts) {
		groupId = authUser.organization.groupId || undefined;
		// If `groupId` already exist, don't include `organizationId`
		// since BE performing an AND operation on their side
		organizationsId = groupId ? undefined : [authUser.organization.id];
	}

	if (!!all) {
		organizationsId = undefined;
	}

	/**
	 * This will fetch all therapists of organization.
	 * The logic in `catch` part, meant to retry the request in case we got an error
	 * outside the specified errors.
	 */
	while (true) {
		try {
			const someTherapists = await organizationSdk.getAllTherapists(
				token,
				[OrganizationMemberStatus.ACCEPTED],
				groupId,
				organizationsId,
				page,
				pageSize,
			);

			therapists.push(...someTherapists);
			page += 1;

			// Kill the loop when one of the response has less than pageSize
			if (someTherapists.length < pageSize) {
				break;
			}
		} catch (err) {
			// Not found error indicates that the requested `page` doesn't contain any result.
			// Therefore we should exit from this operation gracefully without throwing an error.
			// Otherwise we should throw the error to the caller.
			if (err instanceof NotFoundError || err instanceof InvalidPageError) {
				break;
			}
			throw err;
		}
	}

	return therapists;
}
