import produce from "immer";
import {getType} from "typesafe-actions";
import {TimeTrackingState} from "../timeTrackingTypes";
import {timeTrackingActions, timeTrackingActionType} from "./timeTrackingActions";
import {LoadingState} from "../../ts/constants/redux";
import {dateToString, getTodaysDate, getSortedTimeEntries, isItToday} from "../timeTrackingHelpers";
import {LoadingStateReducer} from "../../helpers/loadingStateReducer";

export const initialState: TimeTrackingState = {
	isManualTrackingInProgress: false,
	trackingEntryCache: {},
	daysLoadingState: {},
	selectedDate: getTodaysDate(),
	entryConfirmationLoadingState: LoadingState.EMPTY,
	isCreatingEntry: LoadingState.EMPTY,
	isDeletingEntryMap: {},
	isUpdatingEntryMap: {},
	isActiveEntryTimerRunning: false,
	unconfirmedEntries: [],
	loadingExportingTimeTracking: LoadingState.EMPTY,
	loadingCheckExportTimeTracking: {},
	downloadLinkMapForExport: {},
};

const downloadLinkLoadingStateLogic = new LoadingStateReducer<TimeTrackingState>()
	.addLogic(
		"loadingCheckExportTimeTracking",
		timeTrackingActions.getDownloadLinkofTimeTrackingExport,
		(action) => action.payload.downloadId,
	)
	.addLogic("daysLoadingState", timeTrackingActions.fetchEntries, (action) => action.payload.date)
	.addLogic("isCreatingEntry", timeTrackingActions.createTimeEntry);

const timeTrackingReducer = produce(
	(state: TimeTrackingState = initialState, action: timeTrackingActionType): TimeTrackingState => {
		downloadLinkLoadingStateLogic.apply(state, action);

		switch (action.type) {
			case getType(timeTrackingActions.setDate):
				state.selectedDate = action.payload.date;
				return;

			case getType(timeTrackingActions.fetchEntries.success): {
				state.trackingEntryCache[action.payload.date] = getSortedTimeEntries(action.payload.entries);
				return;
			}

			case getType(timeTrackingActions.storeActiveEntryInputToRedux): {
				state.activeEntryInput = action.payload.entry;
				state.isActiveEntryTimerRunning = action.payload.isTimerRunning;

				return;
			}

			case getType(timeTrackingActions.updateActiveEntry): {
				state.activeEntryInput = {...state.activeEntryInput, ...action.payload.entry};

				return;
			}

			case getType(timeTrackingActions.resetActiveEntry): {
				state.activeEntryInput = null;
				return;
			}

			case getType(timeTrackingActions.resetActiveEntryAndStopTimer): {
				state.isActiveEntryTimerRunning = false;
				state.activeEntryInput = null;
				return;
			}

			case getType(timeTrackingActions.setIsTimerRunningForActiveEntry): {
				state.isActiveEntryTimerRunning = action.payload.isTimerRunning;
				return;
			}

			case getType(timeTrackingActions.createTimeEntry.success): {
				const {entry} = action.payload;
				const date = dateToString(entry.startTime);
				state.trackingEntryCache[date] = state.trackingEntryCache[date] || [];
				state.trackingEntryCache[date] = getSortedTimeEntries(state.trackingEntryCache[date].concat([entry]));

				// Add new entry data to unconfirmed entries array only if it is for the previous day.
				if (!isItToday(date)) {
					state.unconfirmedEntries.push(entry);
				}

				return;
			}

			case getType(timeTrackingActions.updateEntry.request): {
				state.isUpdatingEntryMap[action.payload.entryId] = LoadingState.LOADING;
				return;
			}

			case getType(timeTrackingActions.updateEntry.failure): {
				state.isUpdatingEntryMap[action.payload.entryId] = LoadingState.ERROR;
				return;
			}

			case getType(timeTrackingActions.updateEntry.success): {
				state.isUpdatingEntryMap[action.payload.entryId] = LoadingState.LOADED;
				const {entry, entryId, date} = action.payload;

				// Don't do anything if the data is inconsistent.
				if (!state.trackingEntryCache[date] || state.trackingEntryCache[date].length === 0) return;

				const entryIdx = state.trackingEntryCache[date].findIndex((e) => e.id === entryId);

				// Don't do anything if the data is inconsistent.
				if (entryIdx === -1) return;

				state.trackingEntryCache[date][entryIdx] = {
					...state.trackingEntryCache[date][entryIdx],
					...entry,
				};
				state.trackingEntryCache[date] = getSortedTimeEntries(state.trackingEntryCache[date]);

				return;
			}

			case getType(timeTrackingActions.deleteEntry.request): {
				state.isDeletingEntryMap[action.payload.entryId] = LoadingState.LOADING;
				return;
			}

			case getType(timeTrackingActions.deleteEntry.success): {
				const {date, entryId} = action.payload;
				state.isDeletingEntryMap[action.payload.entryId] = LoadingState.LOADED;
				state.trackingEntryCache[date] = state.trackingEntryCache[date].filter((e) => e.id !== entryId);
				state.unconfirmedEntries = state.unconfirmedEntries.filter((entry) => entry.id !== entryId);

				return;
			}

			case getType(timeTrackingActions.deleteEntry.failure): {
				state.isDeletingEntryMap[action.payload.entryId] = LoadingState.ERROR;
				return;
			}

			case getType(timeTrackingActions.setAutoTrackedActivity): {
				state.currentAutoTrackedActivity = action.payload.activity;
				return;
			}

			case getType(timeTrackingActions.confirmEntries.request): {
				state.entryConfirmationLoadingState = LoadingState.LOADING;
				return;
			}

			case getType(timeTrackingActions.confirmEntries.success): {
				state.entryConfirmationLoadingState = LoadingState.LOADED;
				state.trackingEntryCache[action.payload.date] = action.payload.entries;
				return;
			}

			case getType(timeTrackingActions.confirmEntries.failure): {
				state.entryConfirmationLoadingState = LoadingState.ERROR;
				return;
			}

			case getType(timeTrackingActions.undoEntryDeletion): {
				const {entryId} = action.payload;
				state.isDeletingEntryMap[entryId] = LoadingState.EMPTY;
				return;
			}

			case getType(timeTrackingActions.fetchUnconfirmedEntries.success): {
				state.unconfirmedEntries = action.payload.entries;
				return;
			}

			case getType(timeTrackingActions.addUnconfirmedEntry): {
				state.unconfirmedEntries.push(action.payload.entry);
				return;
			}

			case getType(timeTrackingActions.setDoesUserAllowAutoTracking): {
				state.doesUserAllowAutoTracking = action.payload.doesUserAllowAutoTracking;
				return;
			}

			case getType(timeTrackingActions.exportTimeTracking.request): {
				state.loadingExportingTimeTracking = LoadingState.LOADING;
				return;
			}

			case getType(timeTrackingActions.exportTimeTracking.success): {
				state.loadingExportingTimeTracking = LoadingState.LOADED;
				return;
			}

			case getType(timeTrackingActions.exportTimeTracking.failure): {
				state.loadingExportingTimeTracking = LoadingState.ERROR;
				return;
			}

			case getType(timeTrackingActions.getDownloadLinkofTimeTrackingExport.success): {
				const {downloadId, downloadLink} = action.payload;
				state.downloadLinkMapForExport[downloadId] = downloadLink;
				return;
			}
		}
	},
	initialState,
);

export default timeTrackingReducer;
