import moment from "moment";
import {TIME_UNITS} from "constants/time";
import {LoggerConfig} from "./LoggerConfigTypes";
import {RepeatedOption} from "../../../customRepetition/helpers/CustomRepetitionTypes";
import {
	RecurringScheduleResponse,
	RecurringSchedule,
	RecurringExpression,
	Margin,
} from "@sense-os/goalie-js/dist/swaggerGenerated/dailyPlanner";
import {convertRRuleToUIState, convertUIStateToRRule} from "../../../customRepetition/helpers/RRuleHelpers";
import {ScheduleType} from "../tracking/TrackingTypes";
import RRule from "rrule";
import {createLocalDateFromISODateString} from "utils/time";

/**
 * Transform `RecurringScheduleResponse` data model to `LoggerConfig`
 *
 * @param {RecurringScheduleResponse} recurringSchedule
 */
export function transformRecurringScheduleToLoggerConfig(recurringSchedule: RecurringScheduleResponse): LoggerConfig {
	const loggerConfig: LoggerConfig = {
		id: recurringSchedule.id.toString(),
		title: recurringSchedule.title,
		// Recurring schedule start and end time are timezone unaware.
		// But somehow we get the time converted in UTC.
		// Example: 2020-09-09T17:00:00 gets converted to 2020-09-09T17:00:00Z
		startTime: moment(createLocalDateFromISODateString(recurringSchedule.startTime)),
		endTime: moment(createLocalDateFromISODateString(recurringSchedule.startTime)).add(
			recurringSchedule.recurringExpression.margin.after,
			TIME_UNITS.MINUTES,
		),
		pushNotificationRequired: recurringSchedule.recurringExpression.reminderEnabled,
		scheduleType: recurringSchedule.scheduleType as ScheduleType,
		repeatedOption: RepeatedOption.NOT_REPEATED,
	};
	return {
		...loggerConfig,
		...convertRRuleToUIState(recurringSchedule.recurringExpression.rrule),
		rawRecurringExpression: recurringSchedule.recurringExpression,
	};
}

/**
 * Transform `LoggerConfig` data model to `RecurringSchedule`
 *
 * @param {LoggerConfig} loggerConfig
 */
export function transformLoggerConfigToRecurringSchedule(loggerConfig: LoggerConfig): RecurringSchedule {
	return {
		title: loggerConfig.title,
		scheduleType: loggerConfig.scheduleType,
		recurringExpression: transformLoggerConfigToRecurringExpression(loggerConfig),
	};
}

/**
 * Transform `LoggerConfig` to `RecurringExpression`
 *
 * @param {LoggerConfig} loggerConfig
 */
function transformLoggerConfigToRecurringExpression(loggerConfig: LoggerConfig): RecurringExpression {
	const margin: Margin = {
		before: 0,
		after: Math.abs(loggerConfig.startTime.diff(loggerConfig.endTime, TIME_UNITS.MINUTES)),
	};
	const rrule: RRule = convertUIStateToRRule(
		loggerConfig.startTime,
		loggerConfig.repeatedOption,
		loggerConfig.customRepetition,
	);
	const rruleString: string = rrule.toString().split("Z").join("");

	return {
		margin,
		reminderEnabled: loggerConfig.pushNotificationRequired,
		reminderMargin: [margin],
		rrule: rruleString,
	};
}
