import {KeyboardEvent} from "react";
import strTranslation from "../../assets/lang/strings";
import localization from "../../localization/Localization";

export function getInvalidAttemptErrorMessage(attemptLeft: number) {
	return localization.formatMessage(strTranslation.TWO_FA.error.incorrect_code_attempt_count.message, {
		count: attemptLeft,
	});
}

export function getIncorrectCodeErrorMessage() {
	return localization.formatMessage(strTranslation.TWO_FA.error.incorrect_code.message);
}

const acceptedKeys = ["ArrowLeft", "ArrowRight", "Backspace", "Enter"];

export function onVerificationCodeValidation(event: KeyboardEvent<HTMLDivElement>) {
	// set verification input field to fill number only
	// Arrow left, arrow right, backspace, and enter are still allowed to be typed
	if (!/[0-9]/.test(event.key) && !acceptedKeys.includes(event.key)) {
		event.preventDefault();
	}
}
