import {put, select} from "redux-saga/effects";
import {TaskPutBodyRequest} from "@sense-os/goalie-js";
import {apiCallSaga} from "../../../helpers/apiCall/apiCall";
import psychoEducationSDK from "../helpers/psychoEducationSDK";
import {PsychoEducationFormValues} from "../psychoEducationTypes";
import {psychoEducationActions} from "../redux/psychoEducationActions";
import {getRemovedArticleIds} from "../redux/psychoEducationSelector";
import taskSDK from "../../../taskRegistration/helpers/taskSDK";
import createLogger from "../../../logger/createLogger";
import {SentryTags} from "../../../errorHandler/createSentryReport";

const log = createLogger("updatePsychoEducationTaskSaga", SentryTags.PsychoEducation);

export function* updatePsychoEducationTask(params: {
	token: string;
	formValues: PsychoEducationFormValues;
	userId: number;
	reminderDateTime: Date;
	psychoEducationId: number;
	taskId: number;
}) {
	const {formValues, psychoEducationId, reminderDateTime, taskId, token, userId} = params;

	try {
		/** 1. Update the psycho education */
		yield apiCallSaga(psychoEducationSDK.updatePsychoEducation, token, {
			id: psychoEducationId,
			patient: userId,
			title: formValues.title,
			description: formValues.description,
			expiresAt: formValues.dueDate,
			remindAt: reminderDateTime,
		});

		const removedArticleIds = yield select(getRemovedArticleIds);

		if (removedArticleIds.length > 0) {
			// Remove psycho education articles
			yield put(
				psychoEducationActions.removeArticles.request({
					psychoEducationId: psychoEducationId,
					articleIds: removedArticleIds,
				}),
			);
		} else {
			// Update psycho education articles
			yield put(
				psychoEducationActions.updateArticles.request({
					psychoEducationId: psychoEducationId,
					articles: formValues.articles,
				}),
			);
		}

		/** 2. Update Psycho Education Task  */
		const doesHaveTask: boolean = !!taskId;

		/** only update task if the psycho education has a task */
		if (doesHaveTask) {
			const updateTaskPayload: TaskPutBodyRequest<any> = {
				title: formValues.title,
				expiresAt: formValues.dueDate,
				remindAt: reminderDateTime,
			};

			yield apiCallSaga(taskSDK.updateTask, token, taskId, updateTaskPayload);
		}
	} catch (error) {
		log.captureException(error);
	}
}
