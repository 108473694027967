import moment from "moment";
import {TaskFormValues} from "../taskTypes";
import {ReminderTimeUnit} from "../../helpers/time";

/** Convert reminder duration into date time */
export function getTaskReminderDateTime(formValues: TaskFormValues = null): Date {
	if (!formValues) {
		return null;
	}

	const isHour: boolean = formValues.reminderTimeUnit === ReminderTimeUnit.HOURS;

	return moment(formValues.expiresAt)
		.subtract(formValues.reminderDuration, isHour ? "hours" : "minutes")
		.toDate();
}
