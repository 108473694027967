import {takeEvery, put, call} from "redux-saga/effects";
import {ActionType, getType} from "typesafe-actions";

import createLogger from "../../../logger/createLogger";
import {SentryTags} from "../../../errorHandler/createSentryReport";
import {apiCallSaga} from "../../../helpers/apiCall/apiCall";

import {calendarActions} from "../../redux/calendarActions";
import {getSensorDataById} from "../../helpers/calendarHelpers";
import {getDailyPlannerItems} from "../../../clientActivity/helpers/clientActivitySDKHelpers";
import {getSessionId} from "../../../auth/helpers/authStorage";
import {transformDailyPlannerIntoEventViewData} from "../../../clientActivity/helpers/dailyPlannerActivityHelpers";

const log = createLogger("getSessionDetailsSaga", SentryTags.Calendar);

function* handleFetchSessionDetails(action: ActionType<typeof calendarActions.fetchSessionDetails.request>) {
	const {sessionId} = action.payload;
	const token = yield call(getSessionId);
	try {
		// Fetch the sensor data of session for getting the `userId`, `startTime` and `endTime`
		const sensorData = yield apiCallSaga(getSensorDataById, sessionId);
		// Fetch daily planner items of client
		const dailyPlannerItems = yield apiCallSaga(
			getDailyPlannerItems,
			token,
			sensorData.userId,
			sensorData.startTime,
			sensorData.endTime,
		);
		// Find session plan
		const sessionItem = dailyPlannerItems.find(
			(item) => item.id === sessionId || item.payload.sensorData.id === sessionId,
		);
		// Transform session plan into event view data
		const sessionEventViewData = yield call(transformDailyPlannerIntoEventViewData, sessionItem);

		yield put(calendarActions.fetchSessionDetails.success({sessionData: sessionEventViewData}));
	} catch (err) {
		log.captureException(err);
		yield put(calendarActions.fetchSessionDetails.failure(err));
	}
}

export default function* () {
	yield takeEvery(getType(calendarActions.fetchSessionDetails.request), handleFetchSessionDetails);
}
