import {NotificationItem} from "./NotificationItem";
import {ActionType, createAction} from "typesafe-actions";

export namespace NotificationCenterAction {
	export const addNotifications = createAction(
		"NotificationCenter/addNotifications",
		(notificationItems: NotificationItem[]) => ({notificationItems}),
	)();

	export const combinedActions = {addNotifications};

	export type NotificationCenterActionType = ActionType<typeof combinedActions>;
}
