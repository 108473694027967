import {getTranslatedCustomTracker} from "../trackerLocalizations";
import localization from "../../../../localization/Localization";
import {graphV2TrackerConfigs, supportedSensorGraphV2} from "./graphV2Config";
import strTranslation from "../../../../assets/lang/strings";
import {TrackerSensorName} from "../../customTrackerSensorTypes";
import {overviewTrackerConfigs} from "./overviewTrackerConfig";
import {useMemo} from "react";
import {TrackerRegistrationGroupByDate, TrackerNameWithData} from "../../customTrackerGraphV2Types";

/**
 * Returns true if Sensor is supported by the web portal.
 * Unsupported preset trackers are trackers which the portal don't have the configuration yet.
 */
export function isSupportedSensorGraphV2(sensorName: string): boolean {
	return !!supportedSensorGraphV2[sensorName];
}

export const getTranslatedGraphName = (sensorName: string): string => {
	if (isSupportedSensorGraphV2(sensorName)) {
		return localization.formatMessage(strTranslation.ZZ_CORE_TRACKER.tracker[sensorName].name);
	}
	if (isGraphV2Supported(sensorName)) return getTranslatedCustomTracker(sensorName as unknown as TrackerSensorName);
	return "";
};

/**
 * Returns true if Tracker is supported by all time view graph
 */
export const isAllTimeViewGraphSupported = (sensorName: string): boolean => {
	return !!graphV2TrackerConfigs[sensorName];
};

/**
 * Returns true if Tracker is supported by overview graph
 */
export const isOverviewGraphSupported = (sensorName: string): boolean => {
	return !!overviewTrackerConfigs[sensorName];
};

/**
 * Returns true if Tracker is supported by all time view graph or overview graph
 */
export const isGraphV2Supported = (sensorName: string): boolean => {
	return isAllTimeViewGraphSupported(sensorName) || isOverviewGraphSupported(sensorName);
};

/**
 * Returns tracker data group by date
 */
export const useGroupTrackerDataByDate = (selectedTracker: TrackerNameWithData): TrackerRegistrationGroupByDate => {
	return useMemo(() => {
		return selectedTracker.data.reduce((result, trackerData) => {
			const date = trackerData.startTime.getDate();
			const month = trackerData.startTime.getMonth();
			const year = trackerData.startTime.getFullYear();
			const monthDateYear = `${date}/${month}/${year}`;
			return {
				...result,
				[monthDateYear]: result[monthDateYear] ? result[monthDateYear].concat(trackerData) : [trackerData],
			};
		}, {});
	}, [selectedTracker.data]);
};
