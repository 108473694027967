import {psychoEducationActions, PsychoEducationActionType} from "./psychoEducationActions";
import {getType} from "typesafe-actions";
import analyticsService from "../../../analytics/AnalyticsService";

const trackedActions = [
	psychoEducationActions.openForm,
	psychoEducationActions.closeForm,

	psychoEducationActions.savePsychoEducation.request,
	psychoEducationActions.savePsychoEducation.success,
	psychoEducationActions.savePsychoEducation.failure,
	psychoEducationActions.fetchPsychoEducation.request,
	psychoEducationActions.fetchPsychoEducation.success,
	psychoEducationActions.fetchPsychoEducation.failure,
	psychoEducationActions.deletePsychoEducation.request,
	psychoEducationActions.deletePsychoEducation.success,
	psychoEducationActions.deletePsychoEducation.failure,
].map(getType);

const properties = (action: PsychoEducationActionType) => {
	switch (action.type) {
		case getType(psychoEducationActions.savePsychoEducation.request):
			return action.payload;
		default:
			return null;
	}
};

export const psychoEducationEvents = analyticsService.trackReduxActions(trackedActions, properties);
