import {RecurringSchedulesApi, RecurringSchedulesService, RecurringExpression} from "@sense-os/goalie-js";
import autobind from "autobind-decorator";
import {Injectable} from "../../IoC/Injectable";
import {getAnchorTime} from "../../../customRepetition/helpers/RRuleHelpers";
import {getSessionId} from "../../../auth/helpers/authStorage";
import {createTimezoneUnawareTimeString} from "utils/time";

export interface IRecurringScheduleService {
	/**
	 * Initializes the tracking service.
	 */
	init(): void;

	/** Direct access to the RecurringSchedulesApi SDK */
	readonly sdk: RecurringSchedulesApi;

	removeRecurringSchedule(scheduleId: string, rawRecurringExpression: RecurringExpression);
}

/**
 *
 */
@autobind
export class RecurringScheduleService implements IRecurringScheduleService, Injectable {
	public readonly c: string = "[RecurringScheduleService]";

	private _sdk: RecurringSchedulesApi;

	/** @inheritDoc */
	public get sdk(): RecurringSchedulesApi {
		if (!this._sdk) {
			return;
		}
		return this._sdk;
	}

	/** @inheritDoc */
	public init(): void {
		this._sdk = RecurringSchedulesService;
	}

	public removeRecurringSchedule(scheduleId: string, rawRecurringExpression: RecurringExpression) {
		const terminatedTime: Date = getAnchorTime(rawRecurringExpression, new Date());
		const token = getSessionId();
		return this.sdk.recurringScheduleIdPut(token, scheduleId, {
			terminatedTime: createTimezoneUnawareTimeString(terminatedTime),
		});
	}
}
