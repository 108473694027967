import {fork} from "redux-saga/effects";
import saveTaskRegistrationSaga from "./saveTaskRegistration";
import taskDetailDialogSaga from "./taskDetailDialogSaga";
import fetchTaskByTaskRegistrationId from "./fetchTaskByTaskRegistrationId";

export default function* () {
	yield fork(saveTaskRegistrationSaga);
	yield fork(taskDetailDialogSaga);
	yield fork(fetchTaskByTaskRegistrationId);
}
