import {ReducersMapObject} from "redux";
import {reducer as formReducer} from "redux-form";

import organizationsReducer from "../../organizations/redux/organizationReducers";
import {NotificationsReducer} from "./notificationCenter/NotificationCenterReducer";

import {trackingReducer} from "./tracking/TrackingReducer";
import {ChatReducer} from "../../chat/redux/ChatReducer";
import {UIReducer} from "./UI/UIReducer";
import {videoCallReducer} from "./videoCall/VideoCallReducer";
import {AppStateNames} from "./AppState";
import {meetingNotesReducer} from "../../tracker/meetingNotes/redux/meetingNotesReducer";
import {clientNetworkReducer} from "../../clientNetwork/redux";
import {ChatPresenceReducer} from "../../chat/redux/ChatPresenceReducer";
import {loggerConfigReducer} from "./loggerConfig/LoggerConfigReducer";
import userProfileReducer from "../../userProfile/redux/UserProfileReducer";
import {SensorTargetReducer} from "./sensorTarget/SensorTargetReducer";
import {clientsFeedReducer} from "../../clientsFeed/redux/ClientsFeedReducer";
import {contactReducer} from "../../contacts/redux/contactReducer";
import {RomReducer} from "./rom/RomReducer";
import {ChatRoomReducer} from "../../chat/redux/ChatRoomReducer";
import {authReducer} from "../../auth/redux";
import {bootstrapReducer} from "../../bootstrap/redux";
import customTrackerReducer from "../../tracker/customTracker/redux/customTrackerReducer";
import {toastReducer} from "../../toaster/redux/toastReducer";
import {inactiveClientReducer} from "../../inactiveClient/redux";
import {rateCallReducer} from "../../rateCall/redux/rateCallReducer";
import {userSettingsReducer} from "../../userSettings/redux/userSettingsReducer";
import {treatmentStatusReducer} from "../../treatmentStatus/redux/treatmentStatusReducer";
import {connectionStatusReducer} from "../../connectionStatus/redux";
import sidebarNavReducer from "../../sidebarNav/redux/sidebarNavReducers";
import {connectRouter} from "connected-react-router";
import {history} from "../../helpers/routerHistory";
import {clientActivityMapReducer} from "../../clientActivity/redux/clientActivityReducer";
import {psychoEducationReducer} from "../../tracker/psychoEducation/redux/psychoEducationReducer";
import {dashboardReducer} from "../../dashboard/redux/DashboardReducer";
import {newFeaturePopupReducer} from "../../newFeaturePopup/redux/newFeaturePopupReducer";
import {ndBlogSearchReducer} from "../../nicedayBlogSearch/redux/NDBlogSearchReducer";
import {privateNotesReducer} from "../../privateNotes/redux/privateNotesReducer";
import {versionCheckReducer} from "../../versionCheck/redux/versionCheckReducer";
import timeTrackingReducer from "../../timeTracking/redux/timeTrackingReducer";
import {warningBarReducer} from "../../warningBar/redux";
import {planActivityReducer} from "../../tracker/events/redux/planActivityReducer";
import {fileSharingReducer} from "../../fileSharing/redux/fileSharingReducer";
import {fileSharingUploadReducer} from "../../fileSharing/redux/fileSharingUploadReducer";
import {twoFAReducer} from "../../twoFactorAuthentication/redux/twoFAReducer";
import calendarReducer from "../../calendar/redux/calendarReducer";
import {behaviorExprReducer} from "../../tracker/behaviorExpr/redux/behaviorExprReducer";
import {clientNotePageReducer} from "../../clientNotesPage/redux/clientNoteReducer";
import {interventionNoteReducer} from "../../intervention/note/redux/interventionNoteReducer";
import {therapySessionReducer} from "../../tracker/therapySession/redux/therapySessionReducer";
import {interventionTreatmentGoalReducer} from "../../intervention/treatmentGoal/redux/interventionTreatmentGoalReducer";
import {diaryEntryReducer} from "../../tracker/diaryEntry/redux/diaryEntryReducer";
import {interventionCustomTrackerReducer} from "../../intervention/customTracker/redux/interventionCustomTrackerReducer";
import {interventionPsychoEducationReducer} from "../../intervention/psychoEducation/redux/interventionPsychoEducationReducer";
import {interventionDiaryReducer} from "../../intervention/diary/redux/interventionDiaryReducer";
import {interventionBehaviorExperimentReducer} from "../../intervention/behaviorExperiment/redux/interventionBehaviorExperimentReducer";
import {interventionSessionReducer} from "../../intervention/session/redux/interventionSessionReducer";
import {checkInFeelingReducer} from "../../tracker/checkInFeeling/redux/checkInFeelingReducer";
import {thoughtRecordReducer} from "../../tracker/thoughtRecord/redux/thoughtRecordReducer";
import {clientManagementReducer} from "../../caseload/redux/clientManagementReducer";
import {sortingReducer} from "../../components/sorting/redux/sortingRedux";
import {userManagementReducer} from "../../userManagement/redux/userManagementReducer";
import {dialogReducer} from "../../components/dialog/redux/dialogReducer";
import {assessmentReducer} from "../../tracker/assessment/redux/assessmentReducer";
import {interventionAssessmentReducer} from "../../intervention/assessment/redux/interventionAssessmentReducer";
import {onlineUsersReducer} from "../../onlineUsers/redux/onlineUsersReducer";
import {xaiReducer} from "../../xai/redux/xaiReducer";
import taskRegistrationReducer from "../../taskRegistration/redux/taskRegistrationReducer";
import {interventionTaskRegistrationReducer} from "../../intervention/taskRegistration/redux/interventionTaskRegistrationReducer";

/**
 * The entire applications state (store) is just whatever gets returned from all the reducers
 */

const reducers: ReducersMapObject = {
	// A
	[AppStateNames.auth]: authReducer,
	[AppStateNames.assessment]: assessmentReducer,
	// B
	[AppStateNames.bootstrap]: bootstrapReducer,
	[AppStateNames.behaviorExperiment]: behaviorExprReducer,
	// C
	[AppStateNames.calendar]: calendarReducer,
	[AppStateNames.caseload]: clientManagementReducer,
	[AppStateNames.chat]: ChatReducer,
	[AppStateNames.chatPresence]: ChatPresenceReducer,
	[AppStateNames.chatRoom]: ChatRoomReducer,
	[AppStateNames.clientActivityMap]: clientActivityMapReducer,
	[AppStateNames.clientNetwork]: clientNetworkReducer,
	[AppStateNames.clientsFeed]: clientsFeedReducer,
	[AppStateNames.contacts]: contactReducer,
	[AppStateNames.customTracker]: customTrackerReducer,
	[AppStateNames.connectionStatus]: connectionStatusReducer,
	[AppStateNames.clientNotePage]: clientNotePageReducer,
	[AppStateNames.checkInFeeling]: checkInFeelingReducer,
	// D
	[AppStateNames.dashboard]: dashboardReducer,
	[AppStateNames.dialog]: dialogReducer,
	[AppStateNames.diaryEntry]: diaryEntryReducer,
	// E
	// F
	[AppStateNames.fileSharing]: fileSharingReducer,
	[AppStateNames.fileSharingUpload]: fileSharingUploadReducer,
	[AppStateNames.form]: formReducer,
	// G
	// H
	// I
	[AppStateNames.inactiveClient]: inactiveClientReducer,
	[AppStateNames.interventionAssessment]: interventionAssessmentReducer,
	[AppStateNames.interventionBehaviorExperiment]: interventionBehaviorExperimentReducer,
	[AppStateNames.interventionCustomTracker]: interventionCustomTrackerReducer,
	[AppStateNames.InterventionDiary]: interventionDiaryReducer,
	[AppStateNames.interventionNote]: interventionNoteReducer,
	[AppStateNames.interventionPsychoEducation]: interventionPsychoEducationReducer,
	[AppStateNames.interventionSession]: interventionSessionReducer,
	[AppStateNames.interventionTaskRegistration]: interventionTaskRegistrationReducer,
	[AppStateNames.interventionTreatmentGoal]: interventionTreatmentGoalReducer,
	// L
	[AppStateNames.loggerConfig]: loggerConfigReducer,
	// M
	[AppStateNames.meetingNotes]: meetingNotesReducer,
	// N
	[AppStateNames.newFeaturePopup]: newFeaturePopupReducer,
	[AppStateNames.nicedayBlogSearch]: ndBlogSearchReducer,
	[AppStateNames.notifications]: NotificationsReducer,
	// O
	[AppStateNames.organizations]: organizationsReducer,
	[AppStateNames.onlineUsers]: onlineUsersReducer,

	// P
	[AppStateNames.psychoEducation]: psychoEducationReducer,
	[AppStateNames.planActivity]: planActivityReducer,
	[AppStateNames.privateNotes]: privateNotesReducer,
	// R
	[AppStateNames.rateCall]: rateCallReducer,
	[AppStateNames.rom]: RomReducer,
	[AppStateNames.router]: connectRouter(history),

	// S
	// selectedClientId: selectedClientIdReducer,
	[AppStateNames.sensorTarget]: SensorTargetReducer,
	[AppStateNames.sidebarNav]: sidebarNavReducer,
	[AppStateNames.sorting]: sortingReducer,

	// T
	[AppStateNames.taskRegistration]: taskRegistrationReducer,
	[AppStateNames.therapySession]: therapySessionReducer,
	[AppStateNames.thoughtRecord]: thoughtRecordReducer,
	[AppStateNames.timeTracking]: timeTrackingReducer,
	[AppStateNames.toastData]: toastReducer,
	[AppStateNames.trackingData]: trackingReducer,
	[AppStateNames.treatmentStatus]: treatmentStatusReducer,
	[AppStateNames.twoFA]: twoFAReducer,

	// U
	[AppStateNames.UI]: UIReducer,
	[AppStateNames.userManagement]: userManagementReducer,
	[AppStateNames.userProfile]: userProfileReducer,
	[AppStateNames.userSettings]: userSettingsReducer,
	// V
	[AppStateNames.videoCall]: videoCallReducer,
	[AppStateNames.versionCheck]: versionCheckReducer,
	// W
	[AppStateNames.warningBarData]: warningBarReducer,
	// X
	[AppStateNames.xai]: xaiReducer,
};

export const rootReducer = reducers;
