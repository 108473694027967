import {Interval, SensorDatum} from "redux/tracking/TrackingTypes";
import {ActionType, createAsyncAction, createAction} from "typesafe-actions";
import {TrackerCategory, Tracker, TrackerDataMap, CustomTrackerSensor} from "../customTrackerTypes";
import {TrackerItem} from "@sense-os/goalie-js";
import {TrackerGraphV2DataMap} from "../customTrackerGraphV2Types";

export const customTrackerActions = {
	openSetupTrackerDialog: createAction("customTrackerActions/openSetupTrackerDialog")(),
	closeSetupTrackerDialog: createAction("customTrackerActions/closeSetupTrackerDialog")(),
	loadTrackerCategories: createAsyncAction(
		"customTrackerActions/loadTrackerCategories",
		"customTrackerActions/loadTrackerCategoriesSucceed",
		"customTrackerActions/loadTrackerCategoriesFailed",
	)<void, TrackerCategory[], Error>(),
	addTracker: createAsyncAction(
		"customTrackerActions/addTracker",
		"customTrackerActions/addTrackerSucceed",
		"customTrackerActions/addTrackerFailed",
	)<{tracker: Tracker}, {userId: number; tracker: Tracker}, Error>(),
	showTrackerAddedDialog: createAction("customTrackerActions/showAddedDialog", (newTracker: Tracker) => ({
		newTracker,
	}))(),
	hideTrackerAddedDialog: createAction("customTrackerActions/hideAddedDialog")(),

	loadUserTrackers: createAsyncAction(
		"customTrackerActions/loadUserTrackers",
		"customTrackerActions/loadUserTrackersSucceed",
		"customTrackerActions/loadUserTrackersFailed",
	)<{hashId: string}, {hashId: string; trackers: TrackerItem[]}, {hashId: string; error: Error}>(),

	loadTrackerData: createAsyncAction(
		"customTrackerActions/loadTrackerData",
		"customTrackerActions/loadTrackerDataSucceed",
		"customTrackerActions/loadTrackerDataFailed",
	)<
		{userId: number; sensorNames: string[]; hashId: string; interval: Interval},
		{hashId: string; trackerDataMap: TrackerDataMap},
		{hashId: string; error: Error}
	>(),
	openManageTrackersDialog: createAction("customTrackerActions/openManageDialog")(),
	closeManageTrackersDialog: createAction("customTrackerActions/closeManageDialog")(),

	saveManageTrackers: createAsyncAction(
		"customTrackerActions/saveManageTrackers",
		"customTrackerActions/saveManageTrackersSucceed",
		"customTrackerActions/saveManageTrackersFailed",
	)<{trackers: TrackerItem[]; trackerId: number; isEnabled: boolean; userId: number}, void, Error>(),

	//
	// Fetch custom tracker dialog actions
	//
	fetchCustomTrackerDialog: createAsyncAction(
		"customTrackerActions/fetchCustomTrackerDialog",
		"customTrackerActions/fetchCustomTrackerDialogSucceed",
		"customTrackerActions/fetchCustomTrackerDialogFailed",
	)<{userId: number; trackerId: string}, {customTrackerData: SensorDatum<CustomTrackerSensor>}, Error>(),

	// Fetch tracker graph v2
	fetchTrackerGraphV2: createAsyncAction(
		"customTrackerActions/fetchTrackerGraphV2",
		"customTrackerActions/fetchTrackerGraphV2Success",
		"customTrackerActions/fetchTrackerGraphV2Fail",
	)<
		{userId: number; hashId: string; sensorName: string[]},
		{trackerGraphV2Data: TrackerGraphV2DataMap; hashId: string},
		{hashId: string; error: Error}
	>(),
};

export type CustomTrackerActionType = ActionType<typeof customTrackerActions>;
