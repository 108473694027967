import {LoadingState} from "constants/redux";
import {getType} from "typesafe-actions";
import {ExpireStatus, InterventionBehaviorExperimentState} from "../interventionBehaviorExperimentType";
import {
	interventionBehaviorExperimentActions,
	interventionBehaviorExperimentActionType,
} from "./interventionBehaviorExperimentActions";

const initialState: InterventionBehaviorExperimentState = {
	/** Initial State for Fetching State */
	fetchingState: LoadingState.EMPTY,
	/** Initial State for total of behavior experiment */
	total: 0,
	/** Initial State for behavior experiment list */
	data: [],
	/** Initial State for `selectedExpiresStatus` */
	selectedExpiresStatus: ExpireStatus.PLANNED,
	/** Initial State for `currentPage` */
	currentPage: 1,
};

export function interventionBehaviorExperimentReducer(
	state: InterventionBehaviorExperimentState = initialState,
	action: interventionBehaviorExperimentActionType,
): InterventionBehaviorExperimentState {
	switch (action.type) {
		case getType(interventionBehaviorExperimentActions.fetchBehaviorExperiment.request): {
			return {
				...state,
				fetchingState: LoadingState.LOADING,
				data: [],
			};
		}
		case getType(interventionBehaviorExperimentActions.fetchBehaviorExperiment.success): {
			const {total, data} = action.payload;

			return {
				...state,
				fetchingState: LoadingState.LOADED,
				data,
				total,
			};
		}
		case getType(interventionBehaviorExperimentActions.fetchBehaviorExperiment.failure): {
			return {
				...state,
				fetchingState: LoadingState.ERROR,
				data: [],
			};
		}
		case getType(interventionBehaviorExperimentActions.setBehaviorExpiresStatus): {
			const {expireStatus} = action.payload;

			return {
				...state,
				selectedExpiresStatus: expireStatus,
				// set to page 1 if any changes of the `expireStatus`
				currentPage: 1,
			};
		}
		case getType(interventionBehaviorExperimentActions.setBehaviorCurrentPage): {
			const {page} = action.payload;

			return {
				...state,
				currentPage: page,
			};
		}
		default:
			return state;
	}
}
