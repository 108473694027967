import {PsychoEducationItem} from "@sense-os/goalie-js";
import {createEventViewId} from "redux/tracking/TrackingHelper";
import {EventViewType, PsychoEducationEventView, Sensors} from "redux/tracking/TrackingTypes";

// Transform PsychoEducationResponse to be able shown in client's activity
export function transformPsychoEducationIntoEventViewData(item: PsychoEducationItem): PsychoEducationEventView {
	const occurenceTime = new Date(item.completedAt || item.expiresAt);
	const expiredAt = new Date(item.expiresAt);
	return {
		id: createEventViewId(EventViewType.PSYCHO_EDUCATION, item.id.toString(), occurenceTime),
		ownerId: item.patient,
		createdBy: item.createdBy,
		title: item.title,
		startTime: occurenceTime,
		endTime: occurenceTime,
		type: EventViewType.PSYCHO_EDUCATION,
		isCompleted: !!item.completedAt,
		createdAt: item.plannedAt,
		updatedAt: item.updatedAt,
		sensors: [Sensors.PSYCHO_EDUCATION],
		source: {
			...item,
			expiresAt: expiredAt,
		},
	};
}
