import {StringMap} from "services/utils/Maps";
import {LoadingState} from "constants/redux";
import {TIME} from "constants/time";
import {GetUserFeedResponse} from "@sense-os/goalie-js";
import {MenuItemProps} from "@material-ui/core/MenuItem/MenuItem";
import {Contact} from "../contacts/contactTypes";

export interface UserFeed extends GetUserFeedResponse {
	/**
	 * Unique id for feed
	 *
	 * This unique id is created by portal
	 */
	id?: string;
	/**
	 * Datetime of when an feed is completed
	 */
	completedAt: Date;
	/**
	 * Datetime of when an feed is started
	 */
	occurrenceStartTime: Date;
	/**
	 * Datetime of when an feed is ended
	 */
	occurrenceEndTime: Date;
	/**
	 * Datetime of when an feed is created
	 */
	createdAt: Date;
	/**
	 * Datetime of when an feed is updated
	 */
	updatedAt: Date;
	/**
	 * Link to user profile of the owner who owns the feed
	 */
	ownerLink?: string;
	/**
	 * Link to user profile of the creator who creates the feed
	 */
	creatorLink?: string;
	/**
	 * Link to where the user will be redirected to when user click the feed
	 */
	sourceLink?: string;
}

/**
 * Available feed types enum
 * TODO Move this `FeedType` to sdk - trackers for changes awareness in the future.
 */
export enum FeedType {
	DIARY_ENTRY = "diary_entry",
	PLANNED_EVENT = "planned_event_entry",
	RECURRING_PLANNED_EVENT = "planned_event_log",
	MEETING_NOTE = "meeting_note",
	THOUGHT_RECORD = "gscheme",
	THERAPY_SESSION = "therapy_session",
	SMQ = "smq",
	OMQ = "omq",
	PSYCHO_EDUCATION = "homework",
	BEHAVIOR_EXPERIMENT = "behavior_experiment",
	CHECK_IN_FEELING = "check_in",
}

/**
 * Available Client feed enum
 */
export enum ClientsFeedLabel {
	DATA_CHECK = "data_check",
	PAST_ACTIVITY = "past_activity",
	UPCOMING_ACTIVITY = "upcoming_activity",
}

/** First client feed page number value  */
export const FIRST_PAGE_NUMBER: number = 1;
/** Maximum client feed items per page value  */
export const MAX_ITEM_PER_PAGE: number = 10;
/** Minimum total page for showing pagination */
export const MIN_PAGE_FOR_PAGINATION: number = 2;

/**
 * Clients Feed State interface
 */
export interface ClientsFeedState {
	/** Current active ClientFeedLabel */
	activeLabel: ClientsFeedLabel;
	/** ClientFeed mapped by its id */
	feedsMap: StringMap<UserFeed>;
	/** loadingFeeds state */
	loadingFeeds: LoadingState;
	/** Current page value */
	currentPage: number;
	/** max item per page value */
	maxItemPerPage: number;
	/** time range in hour */
	hourRange: number;
	/** Filter feeds by feed type */
	selectedFeedFilter: FeedFilter;
	/** Filter feeds by client */
	selectedClientFilter: ClientFilter;
}

export const HourRange = {
	ONE_DAY: TIME.HOURS_IN_DAY,
	TWO_DAYS: TIME.HOURS_IN_DAY * 2,
	THREE_DAYS: TIME.HOURS_IN_DAY * 3,
	ONE_WEEK: TIME.HOURS_IN_DAY * TIME.DAYS_IN_WEEK,
	ONE_MONTH: TIME.HOURS_IN_DAY * TIME.DAYS_IN_MONTH_30,
};

export type ClientFeedDropDownItem = Pick<
	MenuItemProps,
	"id" | "disabled" | "value" | "children" | "selected" | "style"
>;

/** Extra dropdown filter option */
export enum DropdownFilter {
	ALL = "all",
}

export type FeedFilter = FeedType | DropdownFilter;

export type ClientFilter = Contact | DropdownFilter;
