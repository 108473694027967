import {useState, useEffect, useCallback} from "react";
import * as React from "react";
import {useSelector, useDispatch} from "react-redux";
import Highlighter from "react-highlight-words";
import {FormattedMessage} from "react-intl";
import keyboardJS from "keyboardjs";
import {Grid, Typography, Button, Box, Tooltip, makeStyles} from "@material-ui/core";
import {OpenInNew, FileCopy, Assignment} from "@material-ui/icons";
import {PostItem} from "@sense-os/goalie-js";
import loc from "../../localization/Localization";
import {toastActions} from "../../toaster/redux/toastAction";
import EnterArrow from "../../assets/icons/enter-arrow.svg";
import analyticsService from "../../analytics/AnalyticsService";
import {AnalyticsEvent} from "../../analytics/AnalyticsEventTypes";
import {getItemsPerPage, getSearchKeyword} from "../redux/NDBlogSearchSelector";
import {getPostTypeName, copyToClipboard, decodeHtmlCharCodes, getItemIndex} from "../helpers/NDBlogSearchHelpers";
import {SearchItemOption, KeyBindings, KeyContexts, MIN_TOOLTIP_CHAR} from "../NDBlogSearchTypes";
import {ndBlogSearchActions} from "../redux/NDBlogSearchActions";
import strTranslation from "../../assets/lang/strings";

interface Props {
	searchItemOption: SearchItemOption;
	onClose: () => void;
}

export const NDBlogSearchList: React.FC<Props> = (props) => {
	const {searchItemOption, onClose} = props;
	const classes = useSearchItemStyles();
	const dispatch = useDispatch();

	const searchResultItems: PostItem[] = useSelector(getItemsPerPage);
	const searchKeyword: string = useSelector(getSearchKeyword);
	const [selectedItem, setSelectedItem] = useState<PostItem>(null);
	const isCopyOption: boolean = searchItemOption === SearchItemOption.COPY_ITEM;
	const ActionIcon = isCopyOption ? <FileCopy /> : <Assignment />;
	const searchActionButton = isCopyOption
		? strTranslation.BLOG_SEARCH.search_item.copy_link.button
		: strTranslation.BLOG_SEARCH.search_item.paste_link.button;

	const handlePasteLink = useCallback(() => {
		if (!selectedItem) {
			return;
		}
		dispatch(ndBlogSearchActions.setPasteLink(selectedItem.url));
		dispatch(
			toastActions.addToast({
				message: loc.formatMessage(strTranslation.BLOG_SEARCH.toast.paste_success.text),
				type: "info",
			}),
		);
		onClose();
	}, [dispatch, onClose, selectedItem]);

	const handleCopyLink = useCallback(() => {
		if (!selectedItem) {
			return;
		}
		copyToClipboard(selectedItem.url);
		onClose();
		dispatch(
			toastActions.addToast({
				message: loc.formatMessage(strTranslation.BLOG_SEARCH.toast.copy_success.text),
				type: "info",
			}),
		);
	}, [dispatch, onClose, selectedItem]);

	const handleVisitLink = (url: string) => {
		window.open(url, "_blank");
		analyticsService.trackEvent(AnalyticsEvent.VISIT_SEARCH_RESULT_LINK, {
			url: url,
		});
	};

	useEffect(() => {
		// Set first result as default selected item
		if (searchResultItems.length > 0) {
			setSelectedItem(searchResultItems[0]);
		}
	}, [searchResultItems]);

	useEffect(() => {
		// An array of search result items id
		const itemIds: number[] = searchResultItems?.map((item) => item.id);

		// Set keyboardJS context to "search_item"
		keyboardJS.setContext(KeyContexts.SEARCH_ITEM);

		// Bind the keys
		keyboardJS.bind(KeyBindings.ENTER, (e) => {
			isCopyOption ? handleCopyLink() : handlePasteLink();
			e.preventDefault();
		});
		keyboardJS.bind(KeyBindings.TAB, () => setSelectedItem(searchResultItems[getItemIndex(selectedItem, itemIds)]));
		keyboardJS.bind(KeyBindings.UP, () =>
			setSelectedItem(
				searchResultItems[selectedItem ? itemIds.indexOf(selectedItem.id) - 1 : searchResultItems.length - 1],
			),
		);
		keyboardJS.bind(KeyBindings.DOWN, () =>
			setSelectedItem(searchResultItems[getItemIndex(selectedItem, itemIds)]),
		);

		return () => {
			keyboardJS.setContext(KeyContexts.SEARCH_ITEM);
			// Unbind any previously bound handlers
			keyboardJS.unbind(KeyBindings.ENTER);
			keyboardJS.unbind(KeyBindings.TAB);
			keyboardJS.unbind(KeyBindings.UP);
			keyboardJS.unbind(KeyBindings.DOWN);
		};
	}, [handleCopyLink, handlePasteLink, isCopyOption, searchResultItems, selectedItem]);

	return (
		<>
			<Grid container direction="column">
				{searchResultItems && searchResultItems.length > 0 ? (
					searchResultItems.map((item) => {
						// Show tooltip if title length > MIN_TOOLTIP_CHAR
						const shouldShowTooltip: boolean = item.title.length > MIN_TOOLTIP_CHAR;
						// Show `Enter` button for selected item
						const shouldShowEnterButton: boolean = selectedItem?.id === item.id;
						return (
							<Box
								key={item.id}
								className={classes.item}
								onMouseMove={() => setSelectedItem(item)}
								style={{borderColor: selectedItem?.id === item.id && "#AED3FA"}}
							>
								<Grid
									item
									container
									direction="row"
									justify="flex-start"
									alignItems="center"
									zeroMinWidth
									xs
									style={{overflow: "hidden"}}
								>
									<Grid item className={classes.postType}>
										<Typography variant="inherit" color="textSecondary">
											{getPostTypeName(item.subtype)}
										</Typography>
									</Grid>
									<Grid item className={classes.postTitle}>
										<Tooltip title={shouldShowTooltip ? decodeHtmlCharCodes(item.title) : ""}>
											<div id="psychoeducation_search_title">
												<Highlighter
													textToHighlight={decodeHtmlCharCodes(item.title)}
													searchWords={[searchKeyword]}
													highlightClassName={classes.highlightedTitle}
													className={classes.postTitle}
													autoEscape
												/>
											</div>
										</Tooltip>
									</Grid>
								</Grid>
								<Grid item container justify="flex-end" alignItems="center" xs={6} sm={6}>
									<Grid item>
										<Button
											className={classes.visitButton}
											id="psychoeducation_search_visit_btn"
											onClick={() => handleVisitLink(item.url)}
											disableFocusRipple
											endIcon={<OpenInNew />}
										>
											<FormattedMessage
												id={strTranslation.BLOG_SEARCH.search_item.visit_link.button}
											/>
										</Button>
									</Grid>
									<Grid item style={{minWidth: 100}}>
										<Button
											id={`psychoeducation_search_${isCopyOption ? "copy" : "paste"}_btn`}
											className={classes.copyButton}
											onClick={() => (isCopyOption ? handleCopyLink() : handlePasteLink())}
											disableFocusRipple
											endIcon={shouldShowEnterButton ? <EnterButton /> : ActionIcon}
										>
											<Typography className={classes.copyText}>
												<FormattedMessage id={searchActionButton} />
											</Typography>
										</Button>
									</Grid>
								</Grid>
							</Box>
						);
					})
				) : (
					<Grid item>
						<Typography variant="body1" color="textSecondary">
							<FormattedMessage id={strTranslation.BLOG_SEARCH.hint.text} />
						</Typography>
					</Grid>
				)}
				{/* TODO: Add NDBlogSearchPagination component here when wp-api able to return > 10 search result items */}
			</Grid>
		</>
	);
};

const EnterButton = () => {
	const classes = useSearchItemStyles();
	return (
		<>
			<div id="psychoeducation_search_enter_btn" className={classes.enterButton}>
				<Typography variant="overline" className={classes.enterText}>
					<FormattedMessage id={strTranslation.BLOG_SEARCH.search_item.enter.button} />
				</Typography>
				<EnterArrow />
			</div>
		</>
	);
};

const useSearchItemStyles = makeStyles(() => ({
	item: {
		margin: "6px 0px",
		height: "60px",
		display: "flex",
		borderRadius: "10px",
		backgroundColor: "#fff",
		border: "solid 3px #fff",
	},
	postType: {
		width: "70px",
		marginLeft: "20px",
		fontSize: "12px",
	},
	postTitle: {
		maxWidth: "290px",
		marginLeft: "10px",
		display: "block",
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
	},
	highlightedTitle: {
		backgroundColor: "#fff",
		color: "#0f6bff",
	},
	enterButton: {
		height: "38px",
		width: "88px",
		backgroundColor: "#EBEBEB !important",
		border: "solid 3px #B2B2B2",
		borderRadius: "8px",
		display: "flex",
		alignItems: "center",
		justifyContent: "space-evenly",
	},
	enterText: {
		fontSize: "12px",
		fontWeight: "bold",
	},
	visitButton: {
		minHeight: "45px",
		fontSize: "14px",
		fontWeight: "normal",
		padding: "5px 10px",
		marginRight: "5px",
	},
	copyButton: {
		minHeight: "45px",
		marginRight: "5px",
		padding: "5px 10px",
	},
	copyText: {
		fontSize: "14px",
	},
	// TODO: Uncomment this when NDBlogSearchPagination id added
	// pagination: {
	// 	fontSize: "12px",
	// },
}));
