import * as React from "react";
import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogContentText,
	DialogActions,
	ButtonProps,
	DialogProps,
} from "@material-ui/core";
import {Button} from "../material-ui/buttons";
import {NDColors} from "../material-ui/NDColors";

interface Props {
	open?: boolean;
	titleText?: string;
	contentText?: string;
	closeButtonText?: string | JSX.Element;
	closeButtonProps?: ButtonProps;
	confirmButtonText?: string | JSX.Element;
	confirmButtonProps?: ButtonProps;
	onClose: () => void;
	onConfirm: () => void;
	/**
	 * If set, the confirmation button on the dialog has red color in order to warn the user
	 * that the action to be performed is critical, such as deletion which cannot be undone.
	 */
	destructive?: boolean;
}

const ConfirmDialog: React.FC<Props & DialogProps> = ({
	open,
	titleText,
	contentText,
	closeButtonText,
	closeButtonProps,
	confirmButtonText,
	confirmButtonProps,
	onClose,
	onConfirm,
	destructive: destructive,
	...props
}) => {
	return (
		<Dialog open={open} onClose={onClose} maxWidth={props.maxWidth || "md"} {...props}>
			{titleText && <DialogTitle>{titleText}</DialogTitle>}
			<DialogContent style={{minWidth: 500, minHeight: 60}}>
				<DialogContentText style={{color: NDColors.DEFAULT_TEXT}}>{contentText}</DialogContentText>
			</DialogContent>
			<DialogActions style={{padding: 14, minHeight: 60}}>
				<Button
					id="btn_close"
					color="default"
					onClick={onClose}
					variant={closeButtonProps?.variant || "contained"}
					{...closeButtonProps}
				>
					{closeButtonText}
				</Button>
				<Button
					id="btn_confirm"
					color={destructive ? "secondary" : "primary"}
					onClick={onConfirm}
					variant={confirmButtonProps?.variant || "contained"}
					{...confirmButtonProps}
				>
					{confirmButtonText}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ConfirmDialog;
