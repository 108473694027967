import {call, put} from "redux-saga/effects";
import {ActionType} from "typesafe-actions";
import strTranslation from "../../../assets/lang/strings";
import {getSessionId} from "../../../auth/helpers/authStorage";
import {SentryTags} from "../../../errorHandler/createSentryReport";
import {apiCallSaga} from "../../../helpers/apiCall/apiCall";
import localization from "../../../localization/Localization";
import createLogger from "../../../logger/createLogger";
import {toastActions} from "../../../toaster/redux";
import behaviorExprSDK from "../helpers/behaviorExprSDK";
import {behaviorExprActions} from "../redux/behaviorExprActions";

const log = createLogger("deleteBehaviorExperimentById", SentryTags.BehaviorExperiment);

/**
 * Delete Behavior Experiment by Id
 */
export function* deleteBehaviorExperimentById(
	action: ActionType<typeof behaviorExprActions.deleteBehaviorExperiment.request>,
) {
	const {behaviorExprId} = action.payload;

	yield put(
		toastActions.addToast({
			message: localization.formatMessage(strTranslation.GRAPHS.delete_event.in_progress.toast),
			type: "info",
		}),
	);

	try {
		const token: string = yield call(getSessionId);
		yield apiCallSaga(behaviorExprSDK.deleteBehaviorExperiment, token, behaviorExprId);
		yield put(behaviorExprActions.deleteBehaviorExperiment.success());
		yield put(
			toastActions.addToast({
				message: localization.formatMessage(strTranslation.GRAPHS.delete_event.success.toast),
				type: "success",
			}),
		);
	} catch (error) {
		log.error("Error while deleting behavior experiment data", error);
		yield put(behaviorExprActions.deleteBehaviorExperiment.failure(error));
	}
}
