import {ActionType, createAction, createAsyncAction} from "typesafe-actions";
import {TaskFormActionType, TaskFormValues} from "../taskTypes";
import {TaskItem, TaskItemResponse, TrackerItem} from "@sense-os/goalie-js";

export const taskRegistrationActions = {
	/**
	 * Save Task through API
	 */
	saveTask: createAsyncAction("taskActions/saveTask", "taskActions/saveTaskSucceed", "taskActions/saveTaskFailed")<
		{
			formValues: TaskFormValues;
			patient: number;
			taskId: number;
			formAction: TaskFormActionType;
			tracker: TrackerItem;
		},
		void,
		Error
	>(),
	/**
	 * set the task modal form
	 */
	setModalFormTask: createAction(
		"taskActions/setModalFormTask",
		(params: {open: boolean; tracker: TrackerItem; formAction: TaskFormActionType}) => {
			return params;
		},
	)(),
	/**
	 * Save behavior experiment draft form values
	 */
	saveFormValues: createAction(
		"taskActions/saveFormValues",
		(params: {userId: number; sensorName: string; formValues?: TaskFormValues}) => {
			const {userId, sensorName, formValues} = params;

			return {userId, formValues, sensorName};
		},
	)(),
	/**
	 * Reset Form value
	 * set the form values to `null` by selected `userId`
	 */
	resetFormValues: createAction("taskActions/resetFormValues", (params: {userId: number; sensorName: string}) => {
		const {userId, sensorName} = params;

		return {userId, sensorName};
	})(),

	/**
	 * fetch task registration detail dialog
	 */
	fetchTaskRegistrationDetailDialog: createAsyncAction(
		"taskActions/fetchTaskRegistrationDetailDialog",
		"taskActions/fetchTaskRegistrationDetailDialogSucceed",
		"taskActions/fetchTaskRegistrationDetailDialogFailed",
	)<{taskRegistrationId: number}, {taskRegistrationData: TaskItem<any>}, Error>(),

	/**
	 * Reset Task Registration Detail Dialog State
	 */
	resetTaskRegistrationDetailDialog: createAction("taskActions/resetTaskRegistrationDetailDialog")(),

	/**
	 * Reset Saving State of task
	 */
	resetSavingState: createAction("taskActions/resetSavingState")(),

	/**
	 * Fetch Task by Registration Id
	 */
	fetchTaskByTaskRegistrationId: createAsyncAction(
		"taskActions/fetchTaskByTaskRegistrationId",
		"taskActions/fetchTaskByTaskRegistrationIdSuccess",
		"taskActions/fetchTaskByTaskRegistrationIdFailed",
	)<
		{
			registrationId: number | string;
		},
		{
			taskRegistration: TaskItemResponse<any>;
		},
		Error
	>(),

	/**
	 * Reset related task fetched data
	 */
	resetRelatedTask: createAction("taskActions/resetRelatedTask")(),
};

export type taskRegistrationActionType = ActionType<typeof taskRegistrationActions>;
