import {call, put} from "redux-saga/effects";
import {ActionType} from "typesafe-actions";
import strTranslation from "../../../assets/lang/strings";
import {getSessionId} from "../../../auth/helpers/authStorage";
import {SentryTags} from "../../../errorHandler/createSentryReport";
import {apiCall} from "../../../helpers/apiCall/apiCall";
import createLogger from "../../../logger/createLogger";
import {toastActions} from "../../../toaster/redux";
import psychoEducationSDK from "../helpers/psychoEducationSDK";
import {psychoEducationActions} from "../redux/psychoEducationActions";
import loc from "../../../localization/Localization";

const log = createLogger("psychoEducationSaga", SentryTags.PsychoEducation);

export function* deletePsychoEducation(
	action: ActionType<typeof psychoEducationActions.deletePsychoEducation.request>,
) {
	const {id} = action.payload;
	const token = yield call(getSessionId);

	yield put(
		toastActions.addToast({
			message: loc.formatMessage(strTranslation.GRAPHS.delete_event.in_progress.toast),
			type: "info",
		}),
	);
	try {
		yield apiCall(psychoEducationSDK.removePsychoEducation, token, Number(id));
		yield put(psychoEducationActions.deletePsychoEducation.success());
		yield put(
			toastActions.addToast({
				message: loc.formatMessage(strTranslation.GRAPHS.delete_event.success.toast),
				type: "success",
			}),
		);
	} catch (err) {
		log.captureException(err);
		yield put(psychoEducationActions.deletePsychoEducation.failure(err));
		yield put(
			toastActions.addToast({
				message: loc.formatMessage(strTranslation.GRAPHS.delete_event.fail.toast),
				type: "error",
			}),
		);
	}
}
