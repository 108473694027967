import {Injectable} from "./Injectable";

import {ITrackingService, TrackingService} from "../services/tracking/TrackingService";
import {VideoCallService} from "../services/chat/video/VideoCallService";
import {ITypingIndicationService, TypingIndicationService} from "../services/chat/TypingIndicationService";
import {IRecurringScheduleService, RecurringScheduleService} from "services/recurringSchedule/RecurringScheduleService";
import {ISensorTargetService, SensorTargetService} from "services/sensorTarget/SensorTargetService";
import {IScheduleService, ScheduleService} from "../services/scheduleService/ScheduleService";
import {IPlannedEventService, PlannedEventService} from "services/plannedEvent/PlannedEventService";

/**
 *
 *  DISC - dependency injection static container.
 *
 * `inversify` turned out to be too heavy and misbehaving, so instead of polluting the resulting JS with
 * A large library and overcomplicated code it was decided to go for a very simple and straight-forward
 * dependency injection approach.
 *
 * Unfortunately, static initialisation failed to work, so `private static logger: ILog = new SenseWebLogger();`
 * was replaced with the initialisation inside the method `getLogger()`.
 * The same goes for the other dependencies in this class.
 *
 * FIXME: @Leo
 * FIXME: The condition above may have already changed. The static initialisation may be supported in TS 3.3.x.
 * FIXME: Research this subject. We may be able to improve performance by switching to the static initialisation!
 *
 */

export class DISC {
	/** Map of the class types to class instances */
	private static instances: any = {};

	/**
	 * The solution is a bit ugly and might not be the most performant. For now it is left as it is,
	 * might be improved later.
	 * @param {{new() => T}} type
	 * @returns {T}
	 */
	private static activator<T extends Injectable>(type: {new (): T}): T {
		const inst: T = new type();
		const c: string = (inst as Injectable).c; // get the class identifier

		if (!this.instances[c]) {
			this.instances[c] = inst;
			// console.info("[DISC] SET  " + c);
		}
		// console.info("[DISC] RETURN " + c);
		return this.instances[c];
	}

	/** @returns  The Tracking Service is built around the Tracking SDK */
	public static getTrackingService(): ITrackingService {
		return this.activator(TrackingService);
	}

	/** @returns  The Organization service is built around Organization SDK */
	public static getVideoCallService(): VideoCallService {
		return this.activator(VideoCallService);
	}

	/** @returns  The call signalling */
	public static getTypingIndication(): ITypingIndicationService {
		return this.activator(TypingIndicationService);
	}

	/** @returns  The recurring schedule  */
	public static getRecurringSchedule(): IRecurringScheduleService {
		return this.activator(RecurringScheduleService);
	}

	/** @returns Sensor Target service which relies on actual TargetSDK */
	public static getSensorTargetService(): ISensorTargetService {
		return this.activator(SensorTargetService);
	}

	/** @returns Scheduling service */
	public static getScheduleService(): IScheduleService {
		return this.activator(ScheduleService);
	}

	/** @returns planned event service */
	public static getPlannedEventService(): IPlannedEventService {
		return this.activator(PlannedEventService);
	}
}
