import {LoadingState} from "constants/redux";
import {getContactsMap, getSelectedContactId} from "../../contacts/redux/contactSelectors";
import {AppState, AppStateNames} from "../../ts/redux/AppState";
import {EventViewData} from "../../ts/redux/tracking/TrackingTypes";
import {ClientActivityState} from "../clientActivityTypes";

/**
 * Returns `clientActivityMap` state
 */
const getClientActivityMap = (state: AppState) => state[AppStateNames.clientActivityMap].clientActivities;

/**
 * Returns user's `ClientActivityState`
 */
const getClientActivityMapByUserId = (state: AppState, userId: number): ClientActivityState | undefined =>
	getClientActivityMap(state)[userId];

/**
 * Returns user's activities sort `startTime` by ascending
 */
const getActivitiesByUserId = (state: AppState, userId: number) => {
	const activities = getClientActivityMapByUserId(state, userId)?.activities || [];

	// Sort by ascending
	return activities.slice().sort((a, b) => a.startTime.getTime() - b.startTime.getTime());
};

/**
 * Returns loading state when fetching user's activities data
 */
const getActivityLoadingStateByUserId = (state: AppState, userId: number) =>
	getClientActivityMapByUserId(state, userId)?.loadingState || LoadingState.EMPTY;

/**
 * Returns user's activities data with contact data.
 * This is because backend doesn't send creator and updater information, and we need these data in the component.
 * Therefore we need to combine this manually, in the front end :sad_faces:
 */
export const getClientActivities = (state: AppState, userId: number): EventViewData[] => {
	const activities = getActivitiesByUserId(state, userId);
	const contactsMap = getContactsMap(state);

	const eventViewDataWithUserProfiles: EventViewData[] = activities.map((eventData) => {
		return {
			...eventData,
			createdByProfile: contactsMap[eventData.createdBy],
			updatedByProfile: contactsMap[eventData.updatedBy],
		};
	});

	return eventViewDataWithUserProfiles;
};

/**
 * Returns selected client single activity
 */
export const getClientActivityByUserId = (state: AppState, userId: number, eventViewId: string) => {
	const activities = getClientActivities(state, userId);
	return activities.find(({id}) => id === eventViewId);
};

/**
 * Returns selected client activities
 */
export const getSelectedClientActivities = (state: AppState) => {
	const selectedClientId = getSelectedContactId(state);
	return getClientActivities(state, selectedClientId);
};

/**
 * Returns selected client activities loading state
 */
export const getSelectedClientActivityLoadingState = (state: AppState) => {
	const selectedClientId = getSelectedContactId(state);
	return getActivityLoadingStateByUserId(state, selectedClientId);
};

/**
 * Return the id of recently opened activity.
 */
export const getIdOfRecentlyOpenedActivity = (state: AppState) =>
	state.clientActivityMap.idOfRecentlyOpenedRegistration;
