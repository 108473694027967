import {LoadingState} from "constants/redux";
import {UserTrackerMap, UserTrackerState, TrackerDatum} from "../customTrackerTypes";
import {CustomTrackerActionType, customTrackerActions} from "./customTrackerActions";
import {getType} from "typesafe-actions";

export default function userTrackerMapReducer(
	state: UserTrackerMap = {},
	action: CustomTrackerActionType,
): UserTrackerMap {
	switch (action.type) {
		case getType(customTrackerActions.loadUserTrackers.request):
		case getType(customTrackerActions.loadUserTrackers.success):
		case getType(customTrackerActions.loadUserTrackers.failure):
		case getType(customTrackerActions.loadTrackerData.request):
		case getType(customTrackerActions.loadTrackerData.success):
		case getType(customTrackerActions.loadTrackerData.failure):
			const prevState = state[action.payload.hashId];
			return {
				...state,
				[action.payload.hashId]: userTrackerReducer(prevState, action),
			};

		default:
			return state;
	}
}

const userTrackerInitialState: UserTrackerState = {
	trackers: [],
	trackerDataMap: {},
	userTrackerFetchingState: LoadingState.EMPTY,
	trackerDataFetchingState: LoadingState.EMPTY,
};
function userTrackerReducer(
	state: UserTrackerState = userTrackerInitialState,
	action: CustomTrackerActionType,
): UserTrackerState {
	switch (action.type) {
		case getType(customTrackerActions.loadUserTrackers.request):
			return {
				...state,
				userTrackerFetchingState: LoadingState.LOADING,
			};
		case getType(customTrackerActions.loadUserTrackers.success):
			return {
				...state,
				userTrackerFetchingState: LoadingState.LOADED,
				trackers: action.payload.trackers,
			};
		case getType(customTrackerActions.loadUserTrackers.failure):
			return {
				...state,
				userTrackerFetchingState: LoadingState.ERROR,
				loadTrackerError: action.payload.error,
			};

		case getType(customTrackerActions.loadTrackerData.request): {
			return {
				...state,
				trackerDataFetchingState: LoadingState.LOADING,
			};
		}
		case getType(customTrackerActions.loadTrackerData.success): {
			// Merge new sensor data with old data for each sensor
			const newTrackerData = Object.keys(action.payload.trackerDataMap).reduce((map, sensorName) => {
				map[sensorName] = mergeTrackerData(
					state.trackerDataMap[sensorName] || [],
					action.payload.trackerDataMap[sensorName],
				);
				return map;
			}, {});
			return {
				...state,
				trackerDataMap: newTrackerData,
				trackerDataFetchingState: LoadingState.LOADED,
			};
		}
		case getType(customTrackerActions.loadTrackerData.failure): {
			return {
				...state,
				trackerDataFetchingState: LoadingState.ERROR,
			};
		}
		default:
			return state;
	}
}

function mergeTrackerData(oldTrackerData: TrackerDatum[], newTrackerData: TrackerDatum[]) {
	return [...newTrackerData, ...oldTrackerData].reduce((combinedData, data) => {
		// If `result` array already have identical `item` object, we don't need to include it.
		if (combinedData.every((res) => res.sensorId !== data.sensorId)) {
			combinedData.push(data);
		}
		return combinedData;
	}, []);
}
