import {Sensors} from "redux/tracking/TrackingTypes";
import {TrackerSensorName} from "../../customTrackerSensorTypes";
import {IGraphConfig} from "../../customTrackerGraphV2Types";
import {averageDurationConfig} from "./averageDurationConfig";
import {quantityConfig} from "./quantityConfig";
import {booleanConfig} from "./booleanConfig";
import {averageIntensityConfig} from "./averageIntensityConfig";
import {averageQuantityConfig} from "./averageQuantityConfig";
import strTranslation from "../../../../assets/lang/strings";
import {checkInConfig} from "./checkInConfig";

export enum TrackerRegistrationGoal {
	INCREASE = "increase",
	DECREASE = "decrease",
	UNDETERMINED = "undetermined",
}
export interface IOverviewTrackerConfigs {
	graphConfig: IGraphConfig;
	trackerGoal: TrackerRegistrationGoal;
}

export const overviewTrackerConfigs: Partial<Record<TrackerSensorName | Sensors, IOverviewTrackerConfigs>> = {
	// tracker with average duration
	[TrackerSensorName.bodycheck]: {
		graphConfig: averageDurationConfig(["measureBodycheck"]),
		trackerGoal: TrackerRegistrationGoal.DECREASE,
	},
	[TrackerSensorName.depersonalization]: {
		graphConfig: averageDurationConfig(["measureDepersonalization"]),
		trackerGoal: TrackerRegistrationGoal.DECREASE,
	},
	[TrackerSensorName.distracted]: {
		graphConfig: averageDurationConfig(["measureDistracted"]),
		trackerGoal: TrackerRegistrationGoal.DECREASE,
	},
	[TrackerSensorName.exercise]: {
		graphConfig: averageDurationConfig(["measureExercise"]),
		trackerGoal: TrackerRegistrationGoal.UNDETERMINED,
	},
	[TrackerSensorName.hyperAroused]: {
		graphConfig: averageDurationConfig(["measureHyperAroused"]),
		trackerGoal: TrackerRegistrationGoal.DECREASE,
	},
	[TrackerSensorName.loneliness]: {
		graphConfig: averageDurationConfig(["measureLoneliness"]),
		trackerGoal: TrackerRegistrationGoal.DECREASE,
	},
	[TrackerSensorName.mindfulness]: {
		graphConfig: averageDurationConfig(["measureMindfulness"]),
		trackerGoal: TrackerRegistrationGoal.INCREASE,
	},
	[TrackerSensorName.restAndRelax]: {
		graphConfig: averageDurationConfig(["measureRelaxation"]),
		trackerGoal: TrackerRegistrationGoal.UNDETERMINED,
	},
	[TrackerSensorName.worry]: {
		graphConfig: averageDurationConfig(["measureWorry"]),
		trackerGoal: TrackerRegistrationGoal.DECREASE,
	},

	// tracker with total quantity
	[TrackerSensorName.alcohol]: {
		graphConfig: quantityConfig("measureUnitOfAlcohol"),
		trackerGoal: TrackerRegistrationGoal.DECREASE,
	},
	[TrackerSensorName.binge]: {
		graphConfig: quantityConfig("measureBinge"),
		trackerGoal: TrackerRegistrationGoal.DECREASE,
	},
	[TrackerSensorName.positiveThings]: {
		graphConfig: quantityConfig("measurePositiveThings"),
		trackerGoal: TrackerRegistrationGoal.INCREASE,
	},
	[TrackerSensorName.smoking]: {
		graphConfig: quantityConfig("measureCigarettes"),
		trackerGoal: TrackerRegistrationGoal.DECREASE,
	},
	[TrackerSensorName.cocaine]: {
		graphConfig: quantityConfig("measureCocaine"),
		trackerGoal: TrackerRegistrationGoal.DECREASE,
	},

	// average quantity
	[TrackerSensorName.weight]: {
		graphConfig: averageQuantityConfig("measureWeight", strTranslation.CUSTOM_TRACKER.chart.weight.kilograms),
		trackerGoal: TrackerRegistrationGoal.UNDETERMINED,
	},

	// tracker with boolean
	[TrackerSensorName.avoidance]: {
		graphConfig: booleanConfig("measureAvoidance"),
		trackerGoal: TrackerRegistrationGoal.DECREASE,
	},
	[TrackerSensorName.flashbacks]: {
		graphConfig: booleanConfig("measureFlashbacks"),
		trackerGoal: TrackerRegistrationGoal.DECREASE,
	},
	[TrackerSensorName.memoryLoss]: {
		graphConfig: booleanConfig("measureMemoryLoss"),
		trackerGoal: TrackerRegistrationGoal.DECREASE,
	},
	[TrackerSensorName.nightmares]: {
		graphConfig: booleanConfig("measureNightmares"),
		trackerGoal: TrackerRegistrationGoal.DECREASE,
	},
	[TrackerSensorName.safetyBehaviour]: {
		graphConfig: booleanConfig("measureSafetyBehaviour"),
		trackerGoal: TrackerRegistrationGoal.DECREASE,
	},
	[TrackerSensorName.assertiveness]: {
		graphConfig: booleanConfig("measureAssertiveness"),
		trackerGoal: TrackerRegistrationGoal.UNDETERMINED,
	},

	// intensity
	[TrackerSensorName.afraid]: {
		graphConfig: averageIntensityConfig(["measureAfraid"]),
		trackerGoal: TrackerRegistrationGoal.DECREASE,
	},
	[TrackerSensorName.angry]: {
		graphConfig: averageIntensityConfig(["measureAngry"]),
		trackerGoal: TrackerRegistrationGoal.DECREASE,
	},
	[TrackerSensorName.ashamed]: {
		graphConfig: averageIntensityConfig(["measureAshamed"]),
		trackerGoal: TrackerRegistrationGoal.DECREASE,
	},
	[TrackerSensorName.compulsion]: {
		graphConfig: averageIntensityConfig(["measureCompulsion"]),
		trackerGoal: TrackerRegistrationGoal.DECREASE,
	},
	[TrackerSensorName.craving]: {
		graphConfig: averageIntensityConfig(["measureCraving"]),
		trackerGoal: TrackerRegistrationGoal.DECREASE,
	},
	[TrackerSensorName.depressed]: {
		graphConfig: averageIntensityConfig(["measureDepressed"]),
		trackerGoal: TrackerRegistrationGoal.DECREASE,
	},
	[TrackerSensorName.energy]: {
		graphConfig: averageIntensityConfig(["measureEnergy"]),
		trackerGoal: TrackerRegistrationGoal.INCREASE,
	},
	[TrackerSensorName.excited]: {
		graphConfig: averageIntensityConfig(["measureExcitement"]),
		trackerGoal: TrackerRegistrationGoal.INCREASE,
	},
	[TrackerSensorName.fatigue]: {
		graphConfig: averageIntensityConfig(["measureFatigue"]),
		trackerGoal: TrackerRegistrationGoal.DECREASE,
	},
	[TrackerSensorName.gratitude]: {
		graphConfig: averageIntensityConfig(["measureGratitude"]),
		trackerGoal: TrackerRegistrationGoal.INCREASE,
	},
	[TrackerSensorName.guilty]: {
		graphConfig: averageIntensityConfig(["measureGuilty"]),
		trackerGoal: TrackerRegistrationGoal.DECREASE,
	},
	[TrackerSensorName.happy]: {
		graphConfig: averageIntensityConfig(["measureHappy"]),
		trackerGoal: TrackerRegistrationGoal.INCREASE,
	},
	[TrackerSensorName.hyperAlert]: {
		graphConfig: averageIntensityConfig(["measureHyperAlert"]),
		trackerGoal: TrackerRegistrationGoal.DECREASE,
	},
	[TrackerSensorName.insecure]: {
		graphConfig: averageIntensityConfig(["measureInsecure"]),
		trackerGoal: TrackerRegistrationGoal.DECREASE,
	},
	[TrackerSensorName.panicAttack]: {
		graphConfig: averageIntensityConfig(["measurePanicAttacks"]),
		trackerGoal: TrackerRegistrationGoal.DECREASE,
	},
	[TrackerSensorName.sad]: {
		graphConfig: averageIntensityConfig(["measureSad"]),
		trackerGoal: TrackerRegistrationGoal.DECREASE,
	},
	[TrackerSensorName.sideEffectsMedication]: {
		graphConfig: averageIntensityConfig(["measureSideEffectsMedication"]),
		trackerGoal: TrackerRegistrationGoal.DECREASE,
	},
	[TrackerSensorName.socialAvoidance]: {
		graphConfig: averageIntensityConfig(["measureSocialAvoidance"]),
		trackerGoal: TrackerRegistrationGoal.DECREASE,
	},
	[TrackerSensorName.tension]: {
		graphConfig: averageIntensityConfig(["measureTension"]),
		trackerGoal: TrackerRegistrationGoal.DECREASE,
	},
	[TrackerSensorName.pain]: {
		graphConfig: averageIntensityConfig(["measurePain"]),
		trackerGoal: TrackerRegistrationGoal.DECREASE,
	},
	[TrackerSensorName.physicalWeakness]: {
		graphConfig: averageIntensityConfig(["measurePhysicalWeakness"]),
		trackerGoal: TrackerRegistrationGoal.DECREASE,
	},
	[TrackerSensorName.withdrawal]: {
		graphConfig: averageIntensityConfig(["measureWithdrawalSymptoms"]),
		trackerGoal: TrackerRegistrationGoal.DECREASE,
	},

	// check-in Feeling
	[Sensors.CHECK_IN_FEELING]: {
		graphConfig: checkInConfig(),
		trackerGoal: TrackerRegistrationGoal.UNDETERMINED,
	},

	// tracker with total, physical, verbal aggression
	// TODO : TBD with product
	//
	// [TrackerSensorName.aggression]: {
	// 	graphConfig: -,
	// 	trackerGoal: TrackerRegistrationGoal.UNDETERMINED,
	// },
	//
	// // multi intensity 3d research emotions
	// [TrackerSensorName.researchEmotions]: [IGraphConfig];
};
