import {UserFeedApi, UserFeedService as BaseUserFeedService, GetUserFeedResponse} from "@sense-os/goalie-js";
import {UserFeed, FeedType} from "../clientsFeedTypes";
import featureFlags from "../../featureFlags/FeatureFlags";
import {getSessionId} from "../../auth/helpers/authStorage";
import createLogger from "../../logger/createLogger";
import {SentryTags} from "../../errorHandler/createSentryReport";
import {ActivityTypes} from "redux/tracking/TrackingTypes";

class NeedDrivenDashboardService {
	private _sdk: UserFeedApi;

	private log = createLogger("NeedDrivenDashboard", SentryTags.NDD);

	/** @inheritDoc */
	public constructor() {
		this._sdk = BaseUserFeedService;
	}

	/** @inheritDoc */
	public async getUserFeeds(startTime: Date, endTime: Date, pastActivity: boolean): Promise<UserFeed[]> {
		const timezone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;
		let feedResponse: GetUserFeedResponse[] = [];
		if (pastActivity) {
			feedResponse = await this._sdk.userFeedPastGet(
				getSessionId(),
				startTime.toISOString(),
				endTime.toISOString(),
				timezone,
			);
		} else {
			feedResponse = await this._sdk.userFeedUpcomingGet(
				getSessionId(),
				startTime.toISOString(),
				endTime.toISOString(),
				timezone,
			);
		}

		const feedTypes = Object.values(FeedType) as string[];
		feedResponse = feedResponse.filter((feed) => {
			const isRecurringPlannedEvent = feed.feedType === FeedType.RECURRING_PLANNED_EVENT;
			const isFeedUnidentified = !feedTypes.includes(feed.feedType);

			// Hide unidentified feeds
			// Shouldn't this be handled in SDK? :thinking_face:
			if (isFeedUnidentified) {
				return false;
			}
			// Hide planned event if featureflag is off
			if (isRecurringPlannedEvent && !featureFlags.recurringPlannedEvents) {
				return false;
			}
			return true;
		});

		return this.setFeedId(feedResponse);
	}

	/**
	 * Set `feedId` since the `id` of `UserFeed` is not unique enough (Recurring planned event has same id within its occurrences)
	 *
	 * @param {UserFeed[]} feeds
	 */
	private setFeedId(feeds: UserFeed[]): UserFeed[] {
		return feeds.map((feed: UserFeed) => {
			switch (feed.feedType) {
				case FeedType.DIARY_ENTRY:
				case FeedType.PLANNED_EVENT: {
					// Try to override `feed_type` from backend.
					// This is because `feed_type` planned event contains multiple type of registrations:
					// SMQ, OMQ, Therapy session, and non repetitive Planned Event.

					if (feed.activityType === ActivityTypes.FILL_OMQ) {
						feed.id = `${FeedType.OMQ}_${feed.sourceId}`;
						feed.feedType = FeedType.OMQ;
					} else if (feed.activityType === ActivityTypes.FILL_SMQ) {
						feed.id = `${FeedType.SMQ}_${feed.sourceId}`;
						feed.feedType = FeedType.SMQ;
					} else if (feed.activityType === ActivityTypes.THERAPY_SESSION) {
						// Prevent crash when session status is `null`
						// this could be happened when client trying to complete a deleted therapy session plan
						// see https://github.com/senseobservationsystems/web-getgoalie/issues/3770
						if (feed.status) {
							feed.id = `${FeedType.THERAPY_SESSION}_${feed.sourceId}`;
							feed.feedType = FeedType.THERAPY_SESSION;
						}
					} else {
						feed.id = `${feed.feedType}__${feed.sourceId}`;
					}

					break;
				}
				case FeedType.MEETING_NOTE:
				case FeedType.THOUGHT_RECORD:
				case FeedType.PSYCHO_EDUCATION:
					feed.id = `${feed.feedType}__${feed.sourceId}`;
					break;
				case FeedType.BEHAVIOR_EXPERIMENT:
					feed.id = `${feed.feedType}__${feed.sourceId}`;
					break;
				case FeedType.RECURRING_PLANNED_EVENT:
					/**
					 * Based on this issue,
					 * we need to change occurrenceTime to happenedAt for completed dailyPlanner
					 * https://github.com/senseobservationsystems/goalie-backend/issues/4084
					 **/
					feed.id = `${feed.feedType}__${feed.sourceId}__${
						feed.completedAt?.getTime() ?? feed.occurrenceStartTime.getTime()
					}`;
					break;
				case FeedType.CHECK_IN_FEELING:
					feed.id = `${feed.feedType}__${feed.sourceId}`;
					break;
				default:
					this.log.warn("Unexpected feedType was provided!", feed);
					break;
			}
			return feed;
		});
	}
}

const needDrivenDashboardService = new NeedDrivenDashboardService();

export default needDrivenDashboardService;
