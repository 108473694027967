import {put, takeEvery} from "redux-saga/effects";
import {ActionType, getType} from "typesafe-actions";
import {timeTrackingActions} from "../redux/timeTrackingActions";
import createLogger from "../../logger/createLogger";
import {SentryTags} from "../../errorHandler/createSentryReport";
import {toastActions} from "../../toaster/redux";
import {dialogActions} from "../../components/dialog/redux/dialogAction";
import {getSessionId} from "../../auth/helpers/authStorage";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";
import timeTrackingSdk from "../timeTrackingSdk";
import localization from "../../localization/Localization";
import strTranslation from "../../assets/lang/strings";
import {TIME_TRACKING_EXPORT_DIALOG_IDS} from "../timeTrackingConst";

const log = createLogger("time-tracking-export", SentryTags.TherapistTimeTracking);

function* exportTimeTracking(action: ActionType<typeof timeTrackingActions.exportTimeTracking.request>) {
	try {
		const token = getSessionId();

		yield apiCallSaga(timeTrackingSdk.exportTimeEntries, token, {
			...action.payload,
		});
		yield put(timeTrackingActions.exportTimeTracking.success());
		yield put(dialogActions.closeDialog(TIME_TRACKING_EXPORT_DIALOG_IDS.MAIN_DIALOG));
		yield put(dialogActions.openDialog(TIME_TRACKING_EXPORT_DIALOG_IDS.SUCCEED_DIALOG));
	} catch (error) {
		yield put(timeTrackingActions.exportTimeTracking.failure({error}));
		yield put(
			toastActions.addToast({
				message: localization.formatMessage(strTranslation.TIME_TRACKING.export.notification.failure),
				type: "error",
			}),
		);
		log.captureException(error);
	}
}

function* getDownloadLinkofTimeTrackingExport(
	action: ActionType<typeof timeTrackingActions.getDownloadLinkofTimeTrackingExport.request>,
) {
	const {downloadId} = action.payload;
	try {
		const token = getSessionId();
		const response: {url: string} = yield apiCallSaga(timeTrackingSdk.getExportedTimeEntriesUrl, token, downloadId);

		yield put(
			timeTrackingActions.getDownloadLinkofTimeTrackingExport.success({downloadId, downloadLink: response.url}),
		);
	} catch (error) {
		yield put(timeTrackingActions.getDownloadLinkofTimeTrackingExport.failure({downloadId, error}));
		log.captureException(error);
	}
}

export function* timeTrackingExportSaga() {
	yield takeEvery(getType(timeTrackingActions.exportTimeTracking.request), exportTimeTracking);
	yield takeEvery(
		getType(timeTrackingActions.getDownloadLinkofTimeTrackingExport.request),
		getDownloadLinkofTimeTrackingExport,
	);
}
