import {call, put, takeEvery} from "redux-saga/effects";
import {getType, ActionType} from "typesafe-actions";

import createLogger from "../../../logger/createLogger";
import {interventionPsychoEducationActions} from "../redux/interventionPsychoEducationActions";
import {apiCallSaga} from "../../../helpers/apiCall/apiCall";
import {getSessionId} from "../../../auth/helpers/authStorage";
import {PsychoEducationItemList} from "@sense-os/goalie-js";
import {getPsychoEducationList} from "../helpers/fetchPsychoEducationList";
import {SentryTags} from "../../../errorHandler/createSentryReport";

const log = createLogger("fetchInterventionPsychoEducationSaga", SentryTags.Intervention);

export function* fetchInterventionPsychoEducation(
	action: ActionType<typeof interventionPsychoEducationActions.fetchPsychoEducations.request>,
) {
	const {hashId, page, pageSize, psychoEducationStatus} = action.payload;
	const token = yield call(getSessionId);

	try {
		const psychoEducationData: PsychoEducationItemList = yield apiCallSaga(
			getPsychoEducationList,
			token,
			hashId,
			page,
			pageSize,
			psychoEducationStatus,
		);
		yield put(
			interventionPsychoEducationActions.fetchPsychoEducations.success({
				data: psychoEducationData,
				hashId,
			}),
		);
	} catch (err) {
		log.captureException(err);
		yield put(interventionPsychoEducationActions.fetchPsychoEducations.failure({err}));
	}
}

export default function* () {
	yield takeEvery(
		getType(interventionPsychoEducationActions.fetchPsychoEducations.request),
		fetchInterventionPsychoEducation,
	);
}
