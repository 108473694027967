import {ActionType, createAction, createAsyncAction} from "typesafe-actions";
import {Contact, IncomingInvitation} from "../contactTypes";

export const contactActions = {
	/** Injects a single contact into the contact list */
	addContact: createAction("Contact/addContact", (contact: Contact) => ({contact}))(),
	/** Injects array of contact into the contact list */
	addContacts: createAction("Contact/addContacts", (contacts: Contact[]) => ({contacts}))(),
	/**
	 * TODO: Remove when not needed anymore.
	 * This is part of hack to support therapists of different orgs
	 * in the treatment form.
	 */
	addTherapistsContacts: createAction("Contact/addTherapistsContacts", (contacts: Contact[]) => ({contacts}))(),

	loadContacts: createAsyncAction(
		"Contact/loadingContacts",
		"Contact/loadedContacts",
		"Contact/errorLoadingContacts",
	)<void, void, {error: Error}>(),

	loadContactById: createAction("Contact/loadContactById", (userId: number) => ({userId}))(),

	removeContact: createAsyncAction(
		"Contact/removingContact",
		"Contact/contactRemoved",
		"Contact/errorRemovingContact",
	)<{userId: number}, {userId: number}, {userId: number}>(),

	fetchPendingIncomingInvitations: createAction("Contact/fetchPendingIncomingInvitations")(),

	/** Set pending incoming invitations */
	setPendingIncomingInvitations: createAction(
		"Contact/setPendingIncomingInvitations",
		(invitations: IncomingInvitation[]) => ({invitations}),
	)(),

	/**
	 * Accept client invitation
	 */
	acceptClientInvitation: createAsyncAction(
		"Contact/acceptClientInvitation",
		"Contact/acceptClientInvitationSuccess",
		"Contact/acceptClientInvitationFail",
	)<{contact: IncomingInvitation}, {contact: IncomingInvitation}, {contact: IncomingInvitation; error: Error}>(),

	/**
	 * Reject client invitation
	 */
	rejectClientInvitation: createAsyncAction(
		"Contact/rejectClientInvitation",
		"Contact/rejectClientInvitationSuccess",
		"Contact/rejectClientInvitationFail",
	)<
		{contact: IncomingInvitation; reason: string},
		{contact: IncomingInvitation},
		{error: Error; contact: IncomingInvitation}
	>(),
};

export type ContactActionType = ActionType<typeof contactActions>;
