import createLogger from "../../logger/createLogger";
import {SentryTags} from "../../errorHandler/createSentryReport";
import {takeEvery, put, call} from "redux-saga/effects";
import {ActionType, getType} from "typesafe-actions";
import {clientNetworkActions} from "../redux/clientNetworkActions";
import contactSdk from "../../contacts/contactSdk";
import {getAuthUserId, getSessionId} from "../../auth/helpers/authStorage";
import {apiCallSaga} from "../../helpers/apiCall/apiCall";
import Localization, {ILocalization} from "../../localization/Localization";
import {toastActions} from "../../toaster/redux/toastAction";
import {contactActions} from "../../contacts/redux/contactAction";
import {getTherapistName} from "../helpers/clientNetworkUtils";
import strTranslation from "../../assets/lang/strings";
import {automaticTreatmentCleanupSaga} from "../../treatmentStatus/sagas/automaticTreatmentCleanupSaga";

const log = createLogger("clientNetworkSaga", SentryTags.ClientNetwork);
const loc: ILocalization = Localization;

function* inviteTherapist(action: ActionType<typeof clientNetworkActions.inviteExtraTherapist.request>) {
	try {
		const token: string = yield call(getSessionId);
		const {clientId, invitedTherapist} = action.payload;

		const toastText = loc.formatMessage(strTranslation.CLIENT_NETWORK.toast.invitation.sent_to, {
			therapist: getTherapistName(invitedTherapist),
		});
		yield apiCallSaga(contactSdk.inviteTherapistToPatient, token, invitedTherapist.id, clientId);
		yield put(clientNetworkActions.inviteExtraTherapist.success({clientId: clientId}));
		yield put(clientNetworkActions.loadClientsConnectionById.request({clientId: clientId}));
		yield put(toastActions.addToast({message: toastText, type: "success"}));
	} catch (err) {
		log.captureException(err);
		yield put(clientNetworkActions.inviteExtraTherapist.failure({clientId: action.payload.clientId, error: err}));
		yield put(clientNetworkActions.loadClientsConnectionById.request({clientId: action.payload.clientId}));
		yield put(
			toastActions.addToast({type: "error", message: strTranslation.CLIENT_NETWORK.connections.fetching.failed}),
		);
	}
}

function* cancelInvitation(action: ActionType<typeof clientNetworkActions.cancelInvitation.request>) {
	try {
		const token: string = yield call(getSessionId);
		const {clientId, pendingTherapist} = action.payload;
		const toastText = loc.formatMessage(strTranslation.CLIENT_NETWORK.toast.invitation.cancel.success, {
			name: getTherapistName(pendingTherapist),
		});
		yield apiCallSaga(contactSdk.cancelInvitation, token, pendingTherapist.invitationId);
		yield put(clientNetworkActions.cancelInvitation.success({clientId: clientId}));
		yield put(clientNetworkActions.loadClientsConnectionById.request({clientId: clientId}));
		yield put(toastActions.addToast({message: toastText, type: "info"}));
	} catch (err) {
		log.captureException(err);
		yield put(clientNetworkActions.cancelInvitation.failure({clientId: action.payload.clientId, error: err}));
		yield put(clientNetworkActions.loadClientsConnectionById.request({clientId: action.payload.clientId}));
	}
}

function* disconnectTherapist(action: ActionType<typeof clientNetworkActions.disconnectTherapist.request>) {
	try {
		const token: string = yield call(getSessionId);
		const userId: number = yield call(getAuthUserId);
		const {clientId, therapistToDisconnect} = action.payload;
		const toastText = loc.formatMessage(strTranslation.CLIENT_NETWORK.toast.therapist.disconnected, {
			therapist: getTherapistName(therapistToDisconnect),
		});

		yield call(automaticTreatmentCleanupSaga, clientId, therapistToDisconnect.id);
		yield apiCallSaga(contactSdk.removeContactFromUserContact, token, therapistToDisconnect.id, clientId);
		yield put(clientNetworkActions.disconnectTherapist.success({clientId: clientId}));
		yield put(clientNetworkActions.loadClientsConnectionById.request({clientId: clientId}));
		yield put(toastActions.addToast({message: toastText, type: "info"}));

		if (userId === therapistToDisconnect.id) {
			yield put(contactActions.removeContact.success({userId: clientId}));
		}
	} catch (err) {
		log.captureException(err);
		const {clientId, therapistToDisconnect} = action.payload;
		const toastText = loc.formatMessage(strTranslation.CLIENT_NETWORK.toast.therapist.disconnect_failed, {
			therapist: getTherapistName(therapistToDisconnect),
		});

		yield put(toastActions.addToast({message: toastText, type: "error"}));
		yield put(clientNetworkActions.disconnectTherapist.failure({clientId: clientId, error: err}));
		yield put(clientNetworkActions.loadClientsConnectionById.request({clientId: clientId}));
	}
}

export default function* () {
	yield takeEvery(getType(clientNetworkActions.inviteExtraTherapist.request), inviteTherapist);
	yield takeEvery(getType(clientNetworkActions.cancelInvitation.request), cancelInvitation);
	yield takeEvery(getType(clientNetworkActions.disconnectTherapist.request), disconnectTherapist);
}
