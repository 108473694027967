import {VideoCallState} from "./VideoCallState";
import {VideoCallAction, VideoCallActions, VideoCallReAction} from "./VideoCallAction";
import {CallActionType, callActions} from "../../../call/redux/callActions";
import {getType, ActionType} from "typesafe-actions";
import {ParticipantData} from "@sense-os/goalie-js";

const initialState: VideoCallState = {
	incomingCallerIds: [],
	isVideo: false,
	accepted: false,
	userReacted: false,
	userReacting: false,
	activeCall: null,
	callWindowCrashed: false,
	callInfo: null,
	selectedOutgoingCallType: null,
};

type VideoCallActionCombo = VideoCallAction | VideoCallReAction | CallActionType;

export function videoCallReducer(state: VideoCallState = initialState, action: VideoCallActionCombo): VideoCallState {
	switch (action.type) {
		case VideoCallActions.INCOMING_CALL:
			return {
				...state,
				incomingCallerIds: (<VideoCallAction>action).callerUserIds,
				isVideo: state.isVideo.valueOf(),
				accepted: state.accepted.valueOf(),
				userReacted: false,
				userReacting: true,
			};

		case getType(callActions.acceptCall): {
			const {payload} = action as ActionType<typeof callActions.acceptCall>;
			return {
				...state,
				isVideo: payload.isVideo,
				accepted: true,
				userReacted: true,
				userReacting: false,
			};
		}
		case getType(callActions.rejectCall):
			return {
				...state,
				isVideo: false,
				accepted: false,
				userReacted: true,
				userReacting: false,
			};

		case getType(callActions.resetActiveCall):
			return initialState;

		case getType(callActions.createActiveCall): {
			const {payload} = action as ActionType<typeof callActions.createActiveCall>;
			return {
				...state,
				activeCall: payload.activeCall,
			};
		}

		case getType(callActions.userInitiateCall): {
			const {userId, time, deviceInformation} = (action as ActionType<typeof callActions.userInitiateCall>)
				.payload;

			return setParticipantMap(state, userId, {
				initiatedTime: time,
				joinedTime: null,
				leaveTime: null,
				deviceInformation,
			});
		}

		case getType(callActions.userJoinCall): {
			const {userId, time, deviceInformation} = (action as ActionType<typeof callActions.userJoinCall>).payload;

			return setParticipantMap(state, userId, {
				leaveTime: null,
				joinedTime: time,
				deviceInformation,
			});
		}

		case getType(callActions.userLeaveCall): {
			const {userId, time: leaveTime} = (action as ActionType<typeof callActions.userLeaveCall>).payload;
			return setParticipantMap(state, userId, {
				leaveTime,
			});
		}

		case getType(callActions.setStreamingStartedDate): {
			const {payload} = action as ActionType<typeof callActions.setStreamingStartedDate>;
			return {
				...state,
				activeCall: {
					...state.activeCall,
					streamingStartedDate: payload.date,
				},
			};
		}

		case getType(callActions.callWindowCrashed):
			return {
				...state,
				callWindowCrashed: true,
			};

		case getType(callActions.setCallInfo): {
			const {payload} = action as ActionType<typeof callActions.setCallInfo>;
			return {
				...state,
				callInfo: payload,
			};
		}

		case getType(callActions.resetCallInfo): {
			return {
				...state,
				callInfo: null,
			};
		}

		case getType(callActions.startOutgoingCallByType): {
			const {payload} = action as ActionType<typeof callActions.startOutgoingCallByType>;
			return {
				...state,
				selectedOutgoingCallType: payload.callType,
			};
		}

		default:
			return state;
	}
}

/**
 * Set `participantMap` by merge `data` with existing `participantMap`
 *
 * @param state
 * @param userId
 * @param data
 */
function setParticipantMap(state: VideoCallState, userId: number, data: Partial<ParticipantData>) {
	if (!state.activeCall) {
		return state;
	}
	return {
		...state,
		activeCall: {
			...state.activeCall,
			participantMap: {
				...state.activeCall.participantMap,
				[userId]: {
					...state.activeCall.participantMap[userId],
					...data,
				},
			},
		},
	};
}
