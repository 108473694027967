import {NotificationItem, NotificationTypes} from "./NotificationItem";
import {NotificationCenterAction} from "./NotificationCenterAction";
import {contactActions, ContactActionType} from "../../../contacts/redux/contactAction";
import {Utils} from "../../utils/Utils";
import {UserUtils} from "../../utils/UserUtils";
import {TIME} from "../../constants/time";
import {getType} from "typesafe-actions";
import {NumberMap} from "../../services/utils/Maps";
import {IncomingInvitation} from "../../../contacts/contactTypes";

/**
 * map new invitation notifications
 * TODO: this is temporary since the state is not fixed
 *
 * @param {IncomingInvitation[]} invitations
 * @param {NotificationItem[]} state
 * @param {boolean}
 */
const mapNewInvitationNotifications = (
	invitations: IncomingInvitation[],
	state: NotificationItem[],
	isRead: boolean,
) => {
	return invitations
		.filter((invitation) => !state.some((s) => s.referenceId === invitation.invitationId))
		.map((invitation): NotificationItem => {
			const name = UserUtils.getName(invitation.firstName, invitation.lastName, invitation.email, true);
			const {firstName, lastName} = invitation;
			return {
				id: Utils.guid(),
				referenceId: invitation.invitationId,
				name,
				fullName: {firstName, lastName},
				notificationType: NotificationTypes.Invitations,
				userPicUrl: invitation.image,
				// invitation notification text will be generated in the component GenericNotificationDropdown
				text: null,
				timestamp:
					invitation.invitationCreatedAt &&
					Math.round(invitation.invitationCreatedAt.valueOf() / TIME.MILLISECONDS_IN_SECOND),
				isRead,
			};
		});
};

/**
 * TODO: @leo
 * map as object instead of array
 * @see https://redux.js.org/recipes/structuring-reducers/normalizing-state-shape
 */

export function NotificationsReducer(
	state: NotificationItem[] = [],
	action: NotificationCenterAction.NotificationCenterActionType | ContactActionType,
): NotificationItem[] {
	switch (action.type) {
		// Update the existing notifications and add new ones
		case getType(NotificationCenterAction.addNotifications):
			//FIXME: consider replacing the array of notifications with a map in the redux store,
			//FIXME: so that conversions on an update won't be needed

			// convert existing notifications into a map
			const notifMap: NumberMap<NotificationItem> = {};
			state.forEach((notif) => {
				notifMap[notif.id] = notif;
			});

			// iterate over the new notifications, update the existing notifications and add new ones
			const newNotifications: NotificationItem[] = action.payload.notificationItems;
			newNotifications.forEach((newNotif) => {
				notifMap[newNotif.id] = newNotif;
			});

			// convert the resulting notifications back into an array.
			const result: NotificationItem[] = [];
			for (let id in notifMap) {
				if (notifMap.hasOwnProperty(id)) {
					result.push(notifMap[id]);
				}
			}

			// sort data message notifications by timestamp deliveryAt descending.
			return result.sort((a, b) => {
				return b.timestamp.valueOf() - a.timestamp.valueOf();
			});

		// TODO: this is temporary since the state is not fixed yet
		case getType(contactActions.setPendingIncomingInvitations): {
			const {invitations} = action.payload;

			// Mark pending invitations as read
			const notificationsMarkedAsRead = markPendingInvitationsAsRead(invitations, state);
			// Filter out existing pending invitations. Only get the new ones.
			const newInvitationNotifications = mapNewInvitationNotifications(invitations, state, false);
			// Merge old notifications with the new ones.
			return [...notificationsMarkedAsRead, ...newInvitationNotifications];
		}

		case getType(contactActions.acceptClientInvitation.success):
		case getType(contactActions.rejectClientInvitation.success): {
			// since the sdk doesn't provide rejected invitation data, we could just update the notification 'isRead' to true
			return markNotificationAsReadById(action.payload.contact.invitationId, state);
		}

		default:
			return state;
	}
}

/**
 * Given new invitations item from backend, this function will try to find old invitation notifications
 * and mark them as read.
 *
 * @param {NetworkInvitation[]} newInvitations
 * @param {NotificationItem[]} notifications
 */
function markPendingInvitationsAsRead(
	newInvitations: IncomingInvitation[],
	notifications: NotificationItem[],
): NotificationItem[] {
	return notifications.map((notification) => {
		const isInvitation = notification.notificationType === NotificationTypes.Invitations;
		if (!isInvitation) {
			return notification;
		}
		const isRead = !newInvitations.some((invitation) => invitation.invitationId === notification.referenceId);
		return {
			...notification,
			isRead,
		};
	});
}

/**
 * Mark notification item as read by given id.
 * @param {number} id
 * @param {NotificationItem[]} notifications
 * @returns {NotificationItem[]}
 */
function markNotificationAsReadById(id: number, notifications: NotificationItem[]): NotificationItem[] {
	return notifications.map((item) => {
		if (item.referenceId === id) {
			return {...item, isRead: true};
		}
		return item;
	});
}
