import moment from "moment";
import {SentryTags} from "../../../errorHandler/createSentryReport";
import {ReminderTimeUnit, PsychoEducationFormValues} from "../psychoEducationTypes";
import createLogger from "../../../logger/createLogger";

const log = createLogger("PsychoEducation", SentryTags.PsychoEducation);

/**
 * Reminder time range helper
 * @param {ReminderTimeUnit} unit
 * @returns {number}
 */
export function getTimeRange(unit: ReminderTimeUnit = ReminderTimeUnit.HOURS): number[] {
	// For 'Minutes' time unit it should showing every 5 minutes (5 to 60 minutes)
	if (unit === ReminderTimeUnit.MINUTES) {
		return Array.from({length: 12}, (x, i) => (i + 1) * 5);
	}
	// For 'Hours' should showing every 1 hour (1 to 24 hours)
	return Array.from({length: 24}, (x, i) => i + 1);
}

/** Check local browser whether support `navigator.clipboard.readText` or not */
export function isSupportClipboardReadText() {
	return !!navigator.clipboard && !!navigator.clipboard.readText;
}

/**  Paste text to clipboard */
export async function pasteFromClipboard(): Promise<string | void> {
	try {
		if (isSupportClipboardReadText()) {
			const text = await navigator.clipboard.readText();
			log.debug("text pasted!");
			return text;
		} else {
			return;
		}
	} catch (err) {
		log.error(err);
	}
}

/** Convert reminder duration into date time */
export function getReminderDateTime(formValues: PsychoEducationFormValues = null): Date {
	if (!formValues) {
		return null;
	}

	const isHour: boolean = formValues.reminderTimeUnit === ReminderTimeUnit.HOURS;

	return moment(formValues.dueDate)
		.subtract(formValues.reminderDuration, isHour ? "hours" : "minutes")
		.toDate();
}

/** Convert reminder date time into duration (in minutes) */
export function getReminderDurationInMinutes(expiredAt: Date, remindedAt: Date): number {
	if (!remindedAt) {
		return null;
	}
	return moment(expiredAt).diff(remindedAt, "minutes");
}

/** Convert reminder date time into duration (in hour) */
export function getReminderDurationInHour(expiredAt: Date, remindedAt: Date): number {
	if (!remindedAt) {
		return null;
	}
	return moment(expiredAt).diff(remindedAt, "hours");
}
