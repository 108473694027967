import {fork, takeEvery, takeLatest} from "redux-saga/effects";
import {getType} from "typesafe-actions";
import {psychoEducationActions} from "../redux/psychoEducationActions";
import {deletePsychoEducation} from "./deletePsychoEducationSaga";
import {fetchPsychoEducation, handleOpenForm, savePsychoEducation} from "./psychoEducationSaga";
import articlesSaga from "./articlesSaga";
import {savePsychoEducationTask} from "./savePsychoEducationTaskSaga";

function* PsychoEducationSaga() {
	yield takeLatest(getType(psychoEducationActions.savePsychoEducation.request), savePsychoEducation);
	yield takeEvery(getType(psychoEducationActions.openForm), handleOpenForm);
	yield takeLatest(getType(psychoEducationActions.fetchPsychoEducation.request), fetchPsychoEducation);
	yield takeLatest(getType(psychoEducationActions.deletePsychoEducation.request), deletePsychoEducation);
	yield takeLatest(getType(psychoEducationActions.savePsychoEducationTask.request), savePsychoEducationTask);
}

export default function* () {
	yield fork(PsychoEducationSaga);
	yield fork(articlesSaga);
}
