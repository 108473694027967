import produce from "immer";
import {SortDirection, SortingReduxState, emptySortingMapItem} from "../sortingType";
import {SortingAction, sortingActions} from "./sortingActions";
import {getType} from "typesafe-actions";

const initialState: SortingReduxState = {
	sortingMap: {},
};

export const sortingReducer = produce((state: SortingReduxState, action: SortingAction) => {
	switch (action.type) {
		case getType(sortingActions.clearSorting): {
			const {tableKey} = action.payload;
			state.sortingMap[tableKey] = {...emptySortingMapItem};
			return;
		}

		case getType(sortingActions.setSorting): {
			const {tableKey, sortKey} = action.payload;
			state.sortingMap[tableKey] = state.sortingMap[tableKey] || {...emptySortingMapItem};

			const prevIdx = state.sortingMap[tableKey].sortingKeys.findIndex((key) => key === sortKey);
			if (prevIdx === 0) {
				state.sortingMap[tableKey].sortingDirections[sortKey] =
					state.sortingMap[tableKey].sortingDirections[sortKey] === SortDirection.Descending
						? SortDirection.Ascending
						: SortDirection.Descending;
			} else {
				state.sortingMap[tableKey].sortingDirections[sortKey] = SortDirection.Ascending;
				const keys = state.sortingMap[tableKey].sortingKeys.filter((key) => key !== sortKey);
				keys.splice(0, 0, sortKey);
				state.sortingMap[tableKey].sortingKeys = keys;
			}

			return;
		}

		case getType(sortingActions.setDefaultSortingKeys): {
			const {tableKey, sortingKeys} = action.payload;
			state.sortingMap[tableKey] = state.sortingMap[tableKey] || {...emptySortingMapItem};
			state.sortingMap[tableKey].sortingKeys = sortingKeys;
			return;
		}
	}
}, initialState);
