import {call, put, takeEvery} from "redux-saga/effects";
import {ActionType, getType} from "typesafe-actions";
import {SentryTags} from "../../../errorHandler/createSentryReport";
import {apiCallSaga} from "../../../helpers/apiCall/apiCall";
import createLogger from "../../../logger/createLogger";
import {interventionTreatmentGoalActions} from "../redux/interventionTreatmentGoalActions";
import treatmentStatusSdk from "../../../treatmentStatus/treatmentStatusSdk";
import {getSessionId} from "../../../auth/helpers/authStorage";
import {toastActions} from "../../../toaster/redux";
import strTranslation from "../../../assets/lang/strings";
import {Treatment} from "@sense-os/goalie-js";

const log = createLogger("loadInterventionTreatmentByPatient", SentryTags.Intervention);

function* loadInterventionTreatmentByPatient(
	action: ActionType<typeof interventionTreatmentGoalActions.getDetailTreatment.request>,
) {
	const {hashId} = action.payload;

	try {
		const token: string = yield call(getSessionId);
		const [result]: Treatment[] = yield apiCallSaga(treatmentStatusSdk.getTreatmentsOfAClient, token, hashId);
		yield put(interventionTreatmentGoalActions.getDetailTreatment.success({treatment: result}));
	} catch (error) {
		log.error(error);
		yield put(interventionTreatmentGoalActions.getDetailTreatment.failure({err: error}));
		yield put(toastActions.addToast({message: strTranslation.GRAPHS.new_event.plan.fail.toast, type: "error"}));
	}
}

export default function* () {
	yield takeEvery(
		getType(interventionTreatmentGoalActions.getDetailTreatment.request),
		loadInterventionTreatmentByPatient,
	);
}
