import {ActionType, createAction} from "typesafe-actions";
import moment from "moment";
import {ThunkAction} from "redux-thunk";
import RRule from "rrule";
import {AppState} from "../AppState";
import {initialize} from "redux-form";
import {
	LoggerConfig,
	LoggerConfigurationFormValues,
	OverlappingDuplicate,
	OverlappingRecurringSchedule,
} from "./LoggerConfigTypes";
import {transformLoggerConfigToRecurringSchedule, transformRecurringScheduleToLoggerConfig} from "./LoggerConfigHelper";
import {getDefaultEndTime, getDefaultStartTime} from "../../../customRepetition/helpers/CustomRepetitionHelpers";
import {getLoggerConfigs, getScheduleType} from "./LoggerConfigSelector";
import {StringMap} from "services/utils/Maps";
import {DISC} from "IoC/DISC";
import Localization, {ILocalization} from "../../../localization/Localization";
import {ScheduleType} from "../tracking/TrackingTypes";
import {RecurringSchedule, ResourceConflictError, TrackerItem} from "@sense-os/goalie-js";
import {createTimezoneUnawareTimeString} from "utils/time";
import {getAnchorTime, hasOccurrence} from "../../../customRepetition/helpers/RRuleHelpers";
import {UIAction} from "../UI/UIAction";
import {getSessionId} from "../../../auth/helpers/authStorage";
import createLogger from "../../../logger/createLogger";
import {SentryTags} from "../../../errorHandler/createSentryReport";
import {toastActions, ToastActionTypes} from "../../../toaster/redux/toastAction";
import {isUnauthorizedError} from "../../../errorHandler/errorHandlerUtils";
import {apiCall} from "../../../helpers/apiCall/apiCall";
import {getSelectedContactId} from "../../../contacts/redux/contactSelectors";
import {RepeatedOption} from "../../../customRepetition/helpers/CustomRepetitionTypes";
import strTranslation from "../../../assets/lang/strings";
import {getCoreTrackerList} from "../../../tracker/customTracker/redux/customTrackerSelectors";
import {CoreTrackerId} from "../../../tracker/utils/coreTracker";
import trackersSDK from "../../../tracker/customTracker/helpers/trackersSDK";

export namespace LoggerConfigAction {
	const c: string = "[LoggerConfigAction]";
	const log = createLogger("LoggerConfigAction", SentryTags.Tracking);

	//
	// OPEN AND CLOSE LOGGER CONFIG MODAL
	//
	export const openModal = createAction("LoggerConfig/OPEN_MODAL", (scheduleType: ScheduleType) => ({
		scheduleType,
	}))();
	/** Close logger config modal */
	export const closeModal = createAction("LoggerConfig/CLOSE_MODAL")();

	//
	// OPEN AND CLOSE LOGGER CONFIG EVENT MODAL
	//
	export const openEventModal = createAction("LoggerConfig/OPEN_EVENT_MODAL")();
	/** Close logger config modal */
	export const closeEventModal = createAction("LoggerConfig/CLOSE_EVENT_MODAL")();

	//
	// OPEN AND CLOSE LOGGER CONFIG FORM
	//

	/** Open logger config form */
	export const openForm = createAction("LoggerConfig/OPEN_FORM")();
	/** Close logger config form */
	export const closeForm = createAction("LoggerConfig/CLOSE_FORM")();

	//
	// LOAD LOGGER CONFIG FROM SERVER
	//

	/** logger config is loading */
	export const loadingLoggerConfig = createAction("LoggerConfig/LOADING_LOGGER_CONFIG", (userId: number) => ({
		userId,
	}))();
	/** error loading logger config */
	export const errorLoadingLoggerConfig = createAction(
		"LoggerConfig/ERROR_LOADING_LOGGER_CONFIG",
		(userId: number, err: any) => ({userId, err}),
	)();
	/** logger config is loading */
	export const loadedLoggerConfig = createAction(
		"LoggerConfig/LOADED_LOGGER_CONFIG",
		(userId: number, loggerConfigs: LoggerConfig[]) => ({userId, loggerConfigs}),
	)();

	//
	// SAVE LOGGER CONFIG. EITHER CREATE OR UPDATE LOGGER CONFIG
	//

	/** logger config is saving */
	export const savingLoggerConfig = createAction(
		"LoggerConfig/SAVING_LOGGER_CONFIG",
		(userId: number, scheduleType: ScheduleType, values: any) => ({userId, scheduleType, values}),
	)();
	/** error saving logger config */
	export const errorSavingLoggerConfig = createAction(
		"LoggerConfig/ERROR_SAVING_LOGGER_CONFIG",
		(userId: number, scheduleType: ScheduleType, err: any) => ({userId, scheduleType, err}),
	)();
	/** logger config saved */
	export const loggerConfigSaved = createAction(
		"LoggerConfig/LOGGER_CONFIG_SAVED",
		(userId: number, scheduleType: ScheduleType, values: LoggerConfig) => ({userId, scheduleType, values}),
	)();

	//
	// REMOVE LOGGER CONFIG
	//

	/** logger config is removing */
	export const removingLoggerConfig = createAction(
		"LoggerConfig/REMOVE_LOGGER_CONFIG",
		(userId: number, scheduleType: ScheduleType, loggerId: string) => ({userId, scheduleType, loggerId}),
	)();

	/** logger config removed */
	export const loggerConfigRemoved = createAction(
		"LoggerConfig/LOGGER_CONFIG_REMOVED",
		(userId: number, scheduleType: ScheduleType, loggerId: string) => ({userId, scheduleType, loggerId}),
	)();

	/** error removing logger config */
	export const errorRemovingloggerConfig = createAction(
		"LoggerConfig/ERROR_REMOVING_LOGGER_CONFIG",
		(userId: number, scheduleType: ScheduleType, loggerId: string, err: any) => ({
			userId,
			scheduleType,
			loggerId,
			err,
		}),
	)();

	// To set schedule type flag in redux store
	export const setScheduleType = createAction("LoggerConfig/SET_SCHEDULE_TYPE", (scheduleType: ScheduleType) => ({
		scheduleType,
	}))();

	/** combine all typesafe actions above to be union */
	const loggerConfigActions = {
		// load logger config
		loadingLoggerConfig,
		errorLoadingLoggerConfig,
		loadedLoggerConfig,

		// open logger config modal
		openModal,
		closeModal,
		openEventModal,
		closeEventModal,

		// Open logger config form
		openForm,
		closeForm,

		// Save logger config
		savingLoggerConfig,
		errorSavingLoggerConfig,
		loggerConfigSaved,

		// Remove logger config
		removingLoggerConfig,
		loggerConfigRemoved,
		errorRemovingloggerConfig,

		// To set schedule type flag in redux store
		setScheduleType,
	};

	/** Union of all action inside `loggerConfigActions` */
	export type LoggerConfigActionType = ActionType<typeof loggerConfigActions>;
	type LoggerConfigThunkActionType = ThunkAction<void, AppState, any, LoggerConfigActionType | ToastActionTypes>;

	//
	// THUNK ACTIONS
	//

	/**
	 * Load recurring schedules and convert it to LoggerConfig
	 *
	 * @param {number} userId
	 */
	export const loadLoggerConfig = (userId: number): LoggerConfigThunkActionType => {
		return async (dispatch) => {
			const token: string = getSessionId();

			dispatch(loadingLoggerConfig(userId));
			try {
				const recurringScheduleSDK = DISC.getRecurringSchedule().sdk;
				const recurringSchedules = await apiCall(
					[recurringScheduleSDK, recurringScheduleSDK.recurringScheduleGet],
					token,
					userId,
				);
				const loggerConfigs: LoggerConfig[] = recurringSchedules
					// We don't want to show recurring schedule that already terminated or doesn't occur anymore.
					.filter(
						(r) =>
							!r.terminatedTime &&
							r.scheduleType !== ScheduleType.RECURRING_PLANNED_EVENT &&
							hasOccurrence(RRule.fromString(r.recurringExpression.rrule)),
					)
					.map(transformRecurringScheduleToLoggerConfig);
				dispatch(loadedLoggerConfig(userId, loggerConfigs));
			} catch (err) {
				reportErrorToSentry(err, "Failed to load logger config");
				dispatch(errorLoadingLoggerConfig(userId, err));
			}
		};
	};

	/**
	 * Open logger config form modal
	 *
	 * Set initial value if `loggerId` passed
	 *
	 * @param {string} loggerId
	 */
	export const openLoggerConfigForm = (loggerId?: string): LoggerConfigThunkActionType => {
		return (dispatch, getState) => {
			let initialValues: LoggerConfigurationFormValues = {
				startTime: getDefaultStartTime(),
				endTime: getDefaultEndTime(),
				repeatedOption: RepeatedOption.NOT_REPEATED,
				title: Localization.formatMessage(`ZZ_REMINDER.prompt.title.${getScheduleType(getState())}`),
				pushNotificationRequired: true, // By default, all logger reminder will have push notification enabled
			};

			if (loggerId) {
				const loggerConfigs: StringMap<LoggerConfig> = getLoggerConfigs(getState());
				// Get logger by loggerId
				initialValues = loggerConfigs[loggerId];
			}

			dispatch(UIAction.collapseChatBox());

			// Set initial value for `LoggerConfigForm`
			dispatch(initialize("LoggerConfigForm", initialValues));
			// Open the form
			dispatch(openForm());
		};
	};

	/**
	 * Perform save logger config
	 *
	 * @param {ScheduleType} scheduleType
	 * @param {LoggerConfig} loggerConfig
	 */
	export const saveLoggerConfig = (
		scheduleType: ScheduleType,
		loggerConfig: LoggerConfig,
	): LoggerConfigThunkActionType => {
		return async (dispatch, getState) => {
			const token: string = getSessionId(),
				userId: number = getSelectedContactId(getState());

			dispatch(savingLoggerConfig(userId, scheduleType, loggerConfig));

			// Tranform `LoggerConfig` to `RecurringSchedule`
			let recurringSchedule: RecurringSchedule = transformLoggerConfigToRecurringSchedule({
				...loggerConfig,
				scheduleType,
			});

			// If `loggerConfig` has an `id`, this means that we're trying to update a logger config
			// Anchor time is used to terminate the old logger config
			if (loggerConfig.id) {
				const {startTime, rawRecurringExpression} = loggerConfig;
				const anchorTime: Date = getAnchorTime(rawRecurringExpression, startTime.toDate());

				recurringSchedule = {
					...recurringSchedule,
					anchorTime: createTimezoneUnawareTimeString(anchorTime),
					parent: loggerConfig.id,
				};
			}

			log.debug(c, "saving logger config", recurringSchedule);
			try {
				const recurringScheduleSDK = DISC.getRecurringSchedule().sdk;
				const response = await apiCall(
					[recurringScheduleSDK, recurringScheduleSDK.recurringSchedulePost],
					token,
					userId,
					recurringSchedule,
				);

				// auto enable core tracker while creating (not editing) the reminder
				// if the tracker is inactive
				if (
					!recurringSchedule?.parent &&
					[ScheduleType.DIARY_ENTRY, ScheduleType.THOUGHT_RECORDS].includes(scheduleType)
				) {
					dispatch(
						onAutoEnableCoreTracker({
							scheduleType,
							token,
							userId,
						}),
					);
				}

				// Tranform `RecurringScheduleRespone` to `LoggerConfig`
				const loggerConfig: LoggerConfig = transformRecurringScheduleToLoggerConfig(response);

				// Stop loading state
				dispatch(loggerConfigSaved(userId, scheduleType, loggerConfig));

				// Close form and show success message
				dispatch(closeForm());

				// Reload logger
				dispatch(loadLoggerConfig(userId));
				let successText: string = Localization.formatMessage(strTranslation.LOGGER_CONFIG.toast.created);
				// Updated logger should have previous logger id stored in `parent`
				if (!!response && !!response.parent) {
					successText = Localization.formatMessage(strTranslation.LOGGER_CONFIG.toast.updated);
				}
				dispatch(toastActions.addToast({message: successText, type: "success"}));
			} catch (err) {
				if (err instanceof ResourceConflictError) {
					dispatch(toastActions.addToast({message: getOverlappingErrorText(err), type: "error"}));
				}
				dispatch(errorSavingLoggerConfig(userId, scheduleType, err));
				reportErrorToSentry(err, "Failed to save logger config");
			}
		};
	};

	/**
	 * Return error text for conflicting error when creating a recurring schedule
	 *
	 * @param {ResourceConflictError} err
	 */
	function getOverlappingErrorText(err: ResourceConflictError): string {
		const loc: ILocalization = Localization;

		try {
			const errorDetails: OverlappingRecurringSchedule[] = err.details.body.detail;

			if (errorDetails.length === 0) {
				return loc.formatMessage(strTranslation.LOGGER_CONFIG.form.error.conflict.other);
			}

			const duplicate: OverlappingDuplicate = errorDetails[0].duplicates[0];
			const timeFormat: string = "dddd, DD MMM YYYY, HH:mm";
			const startTime: string = moment(duplicate.existingOccurrence.startTime).format(timeFormat),
				endTime: string = moment(duplicate.existingOccurrence.endTime).format(timeFormat);

			return loc.formatMessage(strTranslation.LOGGER_CONFIG.form.error.conflict.main, {startTime, endTime});
		} catch (e) {
			reportErrorToSentry(err, "Failed to execute `getOverlappingErrorText`");
			return loc.formatMessage(strTranslation.LOGGER_CONFIG.form.error.conflict.other);
		}
	}

	/**
	 * Perform remove logger config
	 *
	 * @param {string} loggerId
	 */
	export const removeLoggerConfig = (loggerId: string): LoggerConfigThunkActionType => {
		return async (dispatch, getState) => {
			const loc: ILocalization = Localization,
				userId: number = getSelectedContactId(getState()),
				scheduleType: ScheduleType = getScheduleType(getState()),
				loggerConfig: LoggerConfig = getLoggerConfigs(getState())[loggerId];

			const {rawRecurringExpression} = loggerConfig;

			dispatch(removingLoggerConfig(userId, scheduleType, loggerId));

			try {
				await apiCall(
					[DISC.getRecurringSchedule(), DISC.getRecurringSchedule().removeRecurringSchedule],
					loggerId,
					rawRecurringExpression,
				);
				dispatch(loggerConfigRemoved(userId, scheduleType, loggerId));

				const successText: string = loc.formatMessage(strTranslation.LOGGER_CONFIG.toast.removed);
				dispatch(toastActions.addToast({message: successText, type: "success"}));
			} catch (err) {
				if (!isUnauthorizedError(err)) {
					const failText: string = loc.formatMessage(strTranslation.LOGGER_CONFIG.toast.remove.fail);
					dispatch(toastActions.addToast({message: failText, type: "error"}));
				}
				reportErrorToSentry(err, "Failed to remove logger config");
				dispatch(errorRemovingloggerConfig(userId, scheduleType, loggerId, err));
			}
		};
	};

	/**
	 * Opens logger configuration modal and collapses chatbox
	 *
	 * @param {ScheduleType} scheduleType
	 */

	export const openLoggerConfigModal = (scheduleType: ScheduleType): LoggerConfigThunkActionType => {
		return (dispatch) => {
			dispatch(UIAction.collapseChatBox());
			dispatch(LoggerConfigAction.openModal(scheduleType));
		};
	};

	/**
	 * Opens logger configuration event modal and collapses chatbox
	 *
	 * @param {ScheduleType} scheduleType
	 */

	export const openLoggerConfigEventModal = (): LoggerConfigThunkActionType => {
		return (dispatch) => {
			dispatch(UIAction.collapseChatBox());
			dispatch(LoggerConfigAction.openEventModal());
		};
	};

	function reportErrorToSentry(err: any, message?: string) {
		log.captureException(err, {message});
	}

	const onAutoEnableCoreTracker = (params: {
		scheduleType: ScheduleType;
		token: string;
		userId: number;
	}): LoggerConfigThunkActionType => {
		return async (_, getState) => {
			const {scheduleType, token, userId} = params;

			/** Map Between Schedule Type to Core Tracker Id */
			const LoggerTrackerIdMap = {
				[ScheduleType.DIARY_ENTRY]: CoreTrackerId.DIARY_NOTE,
				[ScheduleType.THOUGHT_RECORDS]: CoreTrackerId.THOUGHT_RECORD,
			};

			const trackerId: number = LoggerTrackerIdMap[scheduleType];
			const coreTrackerList: TrackerItem[] = getCoreTrackerList(getState());
			const selectedTracker: TrackerItem = coreTrackerList?.find((tracker) => {
				return tracker.id === trackerId;
			});

			// ensure the tracker is inactive
			if (!selectedTracker?.isEnabled) {
				await trackersSDK.updateTrackerStatus(token, {
					isEnabled: true,
					tracker: trackerId,
					patient: userId,
				});
			}
		};
	};
}
