import {
	BehaviorExperimentPostRequestBody,
	BehaviorExperimentResultItem,
	TaskPostBodyRequest,
} from "@sense-os/goalie-js";
import {BehaviorExprFormValues} from "../redux/behaviorExprTypes";
import moment from "moment";
import {apiCallSaga} from "../../../helpers/apiCall/apiCall";
import behaviorExprSDK from "../helpers/behaviorExprSDK";
import {CoreTrackerId} from "../../utils/coreTracker";
import taskSDK from "../../../taskRegistration/helpers/taskSDK";
import createLogger from "../../../logger/createLogger";
import {SentryTags} from "../../../errorHandler/createSentryReport";

const log = createLogger("createBehaviorExperimentTaskSaga", SentryTags.BehaviorExperiment);
/**
 * Handle Save Behavior Experiment Task
 * Flow:
 * 1. Create Behavior Experiment, then get behavior experiment id
 * 2. Create Task, then set `registration_id` by created behavior experiment id
 */
export function* createBehaviorExperimentTask(params: {
	formValues: BehaviorExprFormValues;
	userId: number;
	reminderDateTime: Date;
	token: string;
}) {
	const {formValues, userId, reminderDateTime, token} = params;

	try {
		// Create new behavior experiment
		const behaviorExperimentBody: BehaviorExperimentPostRequestBody = {
			title: formValues.title,
			description: formValues.description,
			expectation: {
				negativeThought: formValues.negativeExpectation,
				negativeBelievability: formValues.negativeExpectationBelievability,
				negativeConsequence: formValues.negativeExpectationConsequence,
				rationalThought: formValues.rationalExpectation,
				rationalBelievability: formValues.rationalExpectationBelievability,
				rationalConsequence: formValues.rationalExpectationConsequence,
			},
			expiresAt: moment(formValues.expiresAt).toDate(),
			patient: userId,
			remindAt: reminderDateTime,
		};

		const behaviorExperimentResponse: BehaviorExperimentResultItem = yield apiCallSaga(
			behaviorExprSDK.createBehaviorExperiment,
			token,
			behaviorExperimentBody,
		);

		/** 2nd Create Task */
		const creationTaskBody: TaskPostBodyRequest<unknown> = {
			title: formValues.title,
			patient: userId,
			tracker: CoreTrackerId.BEHAVIOR_EXPERIMENT,
			expiresAt: moment(formValues.expiresAt).toDate(),
			remindAt: reminderDateTime,
			registrationId: behaviorExperimentResponse.id,
		};

		yield apiCallSaga(taskSDK.createTask, token, creationTaskBody);
	} catch (error) {
		log.captureException(error);
	}
}
